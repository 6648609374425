import React from "react";
import { Outlet } from "react-router-dom";
import logo from "../../assets/imgs/Logo.svg";

export const Register = () => {
  return (
    <div className="bg-neutral-50 h-screen ">
      <div className=" h-full">
        <div className="h-[10%] 2xl:h-[100px]  w-full bg-neutral-50 flex justify-start pl-7 items-center">
          <div>
            <img src={logo} alt="" />
          </div>
        </div>
        <div className="flex bg-neutral-50 h-[90%] 2xl:h-[calc(100%-100px)]">
          <div className=" h-full w-[10%]"></div>
          <div className=" px-14 pt-10 bg-white w-full rounded-tl-[50px] overflow-auto">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
