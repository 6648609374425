import React, { useEffect, useState } from "react";
import { getTokens } from "../../service/useService";
import createAxiosInstance from "../../service";
import { HashLoader } from "react-spinners";
import { getFromStore } from "../../utils/helpers";
import { toast } from "react-toastify";
import { useAppContext } from "../../context";

const BvnINputField = ({ setCheck }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [bvn, setBvn] = React.useState("");
  const [btnDisabled, setBtnDisabled] = React.useState(true);
  const { setBvncheck } = useAppContext();

  const baseUrl = process.env.REACT_APP_WALLET_BASE_URL;
  const service = createAxiosInstance(baseUrl);
  const details = getFromStore("vendyz_user");
  const merchant_id = getFromStore("merchant_id");

  const wallet = getFromStore("walletDetails");
  const wallet_reference = wallet.data.data[1].wallet_ref;
  const userType = details.user_type;
  const userID = details.id;
  // console.log(wallet_reference);

  const handleBvnChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 11) {
      setBvn(value);
    }
  };
  const handleKeyDown = (e) => {
    if (["e", "E", "+", "-", "."].includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleSubmitBvn = async () => {
    try {
      setIsUploading(true);
      setBtnDisabled(true);

      const body = {
        user_id: userID,
        wallet_ref: wallet_reference,
        bvn: String(bvn),
      };
      const merchantBody = {
        merchant_business_id: merchant_id,
        wallet_ref: wallet_reference,
        bvn: String(bvn),
      };
      const response =
        userType === "buyer"
          ? await service.patch("/virtual/accounts/bvn", body)
          : await service.patch("/virtual/accounts/merchant/bvn", merchantBody);
      if (response.status === 200) {
        setIsUploading(false);
        setBtnDisabled(true);
        toast.success("BVN uploaded successfully");
        setCheck(true);
        setBvncheck(true);
      }
    } catch (error) {
      setIsUploading(false);
      setBtnDisabled(true);
      console.log(error);
      const errorMessage = error.response.data.message;
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    bvn.length > 10 ? setBtnDisabled(false) : setBtnDisabled(true);
  }, [bvn]);

  return (
    <div className="flex items-center gap-3">
      <input
        type="number"
        className="w-[520px] border-[1px] border-[#F0F0F0] rounded-[20px] items-center gap-2 outline-none hover:shadow-[#F0F0F0] h-[50px] px-2 flex shadow-sm"
        placeholder="0000 0000 000"
        onChange={handleBvnChange}
        value={bvn}
        maxLength={11}
        onKeyDown={handleKeyDown}
      />

      <button
        onClick={handleSubmitBvn}
        className={`text-[#5271FF] font-[400] ${
          btnDisabled
            ? "bg-gray-100 cursor-not-allowed"
            : "bg-white cursor-pointer hover:scale-95"
        } px-4 py-2 rounded-[100px] shadow-md  border-[1px] border-[#fafafa] `}
        disabled={btnDisabled}
      >
        {isUploading ? <HashLoader size={20} color="#5271FF" /> : "Upload"}
      </button>
    </div>
  );
};

export default BvnINputField;
