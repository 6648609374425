import React, { useState } from "react";
import arrowRight from "../../assets/imgs/arrow-right (2).svg";
import MajorTitle from "../../components/MajorTitle";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import bankLogo from "../../assets/imgs/bankLogo.svg";
import dummiProfileImg from "../../assets/imgs/dummiProfileicon.svg";
import mooiIcon from "../../assets/imgs/mooiIcon.svg";
import { useEffect } from "react";
import { GetDataTable } from "../../components/GetDataTable";
import { PaymentInfoTable } from "../../components/paymentInfoTable";
import BuyerDisputeModal from "../../components/Modals/BuyerDispute";
import { RemarkOutput } from "./Dispute-confirmation/Buyer-remark";
import { SellerRemarkOutput } from "./Dispute-confirmation/seller-remarks";
import { ConfirmOrderNotificatioN } from "../../components/orders-notification";
import { UnConfirmedOrderNotificatioN } from "../../components/orders-notification/unconfirmed";
import { useAppContext } from "../../context";
import { getFromStore } from "../../utils/helpers";
import useService from "../../service/useService";
import { toast } from "react-toastify";
import { ClockLoader, PuffLoader } from "react-spinners";
import { SellerDisputesMOdal } from "../../components/Modals/SellerDisputes";
import createAxiosInstance from "../../service";

const OrderConfirmation = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { setAlOrderData, allOrderData } = useAppContext();
  const details = getFromStore("vendyz_user");
  const userID = details.id;
  const userType = details.user_type;

  const basUrl = process.env.REACT_APP_ODER_BASE_URL;
  const Service = useService(basUrl);
  const postSerice = createAxiosInstance(basUrl);

  const [ifHaveBuyerRemark, setIfHaveBuyerRemark] = React.useState(false);
  const [ifHaveSellerRemark, setIfHaveSellerRemark] = React.useState(false);
  const [buyerBtn, setBuyerBtn] = React.useState(false);
  const [sellerBtn, setSellerBtn] = React.useState(false);

  const [confirmedOrderNotification, setConfirmedOrderNotification] =
    React.useState(false);
  const [unConfirmedOrderNotification, setUnConfirmedOrderNotification] =
    React.useState(false);

  // show seller remark on screen
  const [showDisputeResultBuyer, setShowDisputeResultBuyer] = useState(
    userType === "buyer" ? false : true
  );
  // dispute Button value
  const [openDisputeButtonValue, setOpenDisputeButtonValue] =
    useState(" Open disputes");
  // buyer dispute
  const [openDispute, setOpenDisputes] = useState(false);
  // buyers info
  const [buyerRecieved, setBuyerRecieved] = useState("");
  const [buyerResolutionDetail, setBuyerResolutionDetail] = useState("");
  const [buyerRemark, setBuyerRemark] = useState("");
  const [buyerSelectedFiles, setbuyerSelectedFile] = useState([]);
  // seller info
  const [sellerReciept, setSellerReciept] = useState("");
  const [sellerProposal, setSellerProposal] = useState("");
  const [sellerRemark, setSellerRemark] = useState("");
  const [sellerEvidence, setSellerEvidence] = useState([]);
  // seller dispute
  const [sellersModal, setsellersModal] = useState(false);
  // buttons
  const [confirmOrderButton, setCofirmORderButton] = useState(true);
  const [openDisputeButton, setOpenDisputeButton] = useState(
    userType === "buyer" ? true : false
  );
  const [disAbleRejectProposalButton, setDisAbleRejectProposalButton] =
    useState(false);
  const [disableAcceptedProposalButton, setDisableAcceptedProposalButton] =
    useState(false);
  const [sellerAcceptProposal, setSellerAcceptProposal] = useState(true);
  const [sellerRejectProposal, setSellerRejectProposal] = useState(true);
  const [rejectedProposalButtonValue, setRejectedProposalButtonValue] =
    useState("Reject proposal");
  const [acceptProporsalButtonValue, setAcceptProporsalButtonValue] =
    useState("Accept proposal");
  const [sellerAcceptedNotification, setSellerAcceptedNotification] =
    useState(false);
  const [showBtnWhileProposalDeclined, setShowBtnWhileProposalDeclined] =
    useState(true);
  const [showBtnWhileProposalAccepted, setShowBtnWhileProposalAccepted] =
    useState(false);
  const [pageLoad, setPageLoad] = React.useState(true);
  const merchant_id = getFromStore("merchant_id");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getDetails = async () => {
      try {
        const response =
          userType === "buyer"
            ? await Service.get(`/buyer/orders/user/${userID}`)
            : await Service.get(`/vendor/orders/vendor/${merchant_id}`);
        if (response.status === 200) {
          const data = response.data.data.data;
          setAlOrderData(data);
          setPageLoad(false);
          console.log(data);
        }
      } catch (error) {
        console.log(error);
        if (error.message === "timeout exceeded") {
          toast.error("Timeout exceeded");
        } else if (error.message === "Network Error") {
          toast.error("Network error");
        }
      }
    };

    getDetails();
  }, []);

  const orderDet = allOrderData.find(
    (order) => order.id === Number(params.orderConfirmation)
  );

  console.log(orderDet);
  const acceptOrder = async () => {
    const body = {
      order_id: orderDet.id,
      order_ref: orderDet.order_ref,
      merchant_business_name: orderDet.merchant_business_name,
      merchant_business_id: orderDet.merchant_business_id,
      order_accepted_status: orderDet.order_accepted_status,
    };

    try {
      setIsLoading(true);
      setCofirmORderButton(true);
      const response = await postSerice.post(
        "/buyer/orders/accept/reject",
        body
      );
      if (response.status === 201) {
        console.log(response);
        setIsLoading(false);
        setCofirmORderButton(false);
      }
    } catch (err) {
      setIsLoading(false);
      setCofirmORderButton(false);
      console.log(err);
    }
  };

  useEffect(() => {
    const getDetails = async () => {
      try {
        const response =
          userType === "buyer"
            ? await Service.get(`/buyer/disputes/all/${userID}`)
            : await Service.get(`/vendor/disputes/all/${merchant_id}`);
        if (response.status === 200) {
          console.log(response);
          const data = response.data.data.data;
          try {
            data.forEach((item) => {
              if (item.order_id === orderDet.id) {
                if (userType === "buyer") {
                  setBuyerRecieved(item.received_order);
                  setbuyerSelectedFile(item.issue_eveidence_url);
                  setBuyerRemark(item.issue_details);
                  setBuyerResolutionDetail(item.sought_resolution);
                  setShowDisputeResultBuyer(true);
                  setCofirmORderButton(true);
                  setIfHaveBuyerRemark(true);
                  setOpenDisputeButtonValue("Dispute in progress");
                  setOpenDisputeButton(true);

                  setSellerReciept("");
                  setSellerRemark("");
                  setSellerProposal("");
                  setSellerEvidence("");
                  setIfHaveSellerRemark(false);
                } else {
                  setSellerReciept(item.delivered_order);
                  setSellerRemark(item.issue_details);
                  setSellerProposal(item.sought_resolution);
                  setSellerEvidence(item.issue_eveidence_url);
                  setOpenDisputeButtonValue("Dispute in progress");
                  setIfHaveSellerRemark(true);

                  setBuyerRecieved("");
                  setbuyerSelectedFile([]);
                  setBuyerRemark("");
                  setBuyerResolutionDetail("");
                  setShowDisputeResultBuyer(true);
                  setCofirmORderButton(true);
                  setIfHaveBuyerRemark(false);

                  setSellerAcceptProposal(false);
                  setSellerRejectProposal(true);
                  setDisAbleRejectProposalButton(true);
                  setRejectedProposalButtonValue("Rejected");
                }
              }
            });
          } catch (err) {
            console.log(err);
            // toast.error("Failed to fetch disputes");
          }
        }
      } catch (error) {
        console.log(error);
        if (error.message === "timeout exceeded") {
          toast.error("Timeout exceeded");
        } else if (error.message === "Network Error") {
          toast.error("Network error");
        }
      }
    };

    getDetails();
  }, []);

  useEffect(() => {
    function checkStatus(value) {
      if (value === "PENDING") {
        setUnConfirmedOrderNotification(true);
        setConfirmedOrderNotification(false);
        setCofirmORderButton(false);
        setOpenDisputeButton(false);
        setOpenDisputes(false);

        return value;
      } else if (value === "ACCEPTED") {
        setConfirmedOrderNotification(true);
        setUnConfirmedOrderNotification(false);
        setOpenDisputeButton(false);
        setOpenDisputes(false);
        setShowDisputeResultBuyer(false);
        setCofirmORderButton(true);

        return;
      } else if (value === "CANCELLED") {
        setConfirmedOrderNotification(false);
        setUnConfirmedOrderNotification(false);
        setOpenDisputeButton(true);
        setCofirmORderButton(true);
        setOpenDisputes(false);
        setShowDisputeResultBuyer(false);
      }
    }

    if (userType === "buyer") {
      setBuyerBtn(true);
      setSellerBtn(false);
    } else {
      setBuyerBtn(false);
      setSellerBtn(true);
    }

    checkStatus(orderDet?.order_status);
  }, [orderDet?.order_status, userType]);

  console.log(orderDet);

  const tableHeadData = [
    {
      id: 1,
      name: "Name",
      "Order Amount": "Order Amount",
      OrderID: "OrderID",
      "Order Date": "Date",
      status: "Status",
    },
  ];
  const transactionTableHead = [
    {
      id: 1,
      "Payment to": "Payment to",
      "Payment from": "Payment from",
      "Payment amount": "Payment amount",
    },
  ];
  const reciepientInfo = {
    phoneNumber: "+2347097783230",
    logo: mooiIcon,
    email: "mootigadgets@gmail.com",
  };
  const senderDetails = {
    referenceNumber: "23548868VD",
    bankName: "ECOBANK PLC",
    banklogo: bankLogo,
    phoneNumber: "+2348048740391",
    profilepicture: dummiProfileImg,
  };
  const buyerRemarkHeaders = [
    {
      id: 1,
      "Buyer’s remarks": "Buyer’s remarks",
      Receipt: "Receipt",
      Proposal: "Proposal",
      Evidence: "Evidence",
    },
  ];
  const SellerrRemarkHeaders = [
    {
      id: 1,
      "Sellers’s remarks": "Seller’s remarks",
      Receipt: "Receipt",
      Proposal: "Proposal",
      Evidence: "Evidence",
    },
  ];

  if (pageLoad) {
    return (
      <div className="h-72 w-full flex justify-center pt-44">
        <PuffLoader color="#5271FF" speedMultiplier={0.8} />
      </div>
    );
  }

  return (
    <section className="relative ">
      <div className="">
        <div
          onClick={() => navigate("/orders")}
          className=" rounded-[100px] w-[101px] h-[40px] flex  items-center shadow-sm justify-center gap-2 border-[1px] border-[#F0F0F0] cursor-pointer "
        >
          <div>
            <img src={arrowRight} alt="" />
          </div>
          <div>
            <h1 className="text-[#5C5959] font-semibold text-sm ">Back</h1>
          </div>
        </div>
        <div className="pt-10 pl-2">
          <MajorTitle title={"Details"} />
        </div>
      </div>

      <div className="">
        {userType !== "buyer" ? (
          <div>
            {unConfirmedOrderNotification && (
              <UnConfirmedOrderNotificatioN orderDet={orderDet} />
            )}
          </div>
        ) : (
          ""
        )}

        <div className="border-b-[1px] pb-12">
          <GetDataTable tableHeadData={tableHeadData} data={orderDet} />
        </div>

        {/* <div className="w-full pt-14 pb-14 ">
          <PaymentInfoTable
            data={orderDet}
            tHeaders={transactionTableHead}
            recipeientPhoneNum={reciepientInfo.phoneNumber}
            recipientEmail={reciepientInfo.email}
            recipientLogo={reciepientInfo.logo}
            senderBankLogo={senderDetails.banklogo}
            senderPhoneNumber={senderDetails.phoneNumber}
            senderBankName={senderDetails.bankName}
            senderReferenceNum={senderDetails.referenceNumber}
            senderProfilePicture={senderDetails.profilepicture}
          />
        </div> */}

        {buyerBtn && (
          <div className="flex gap-6 items-center  pt-10 pb-9">
            {showBtnWhileProposalDeclined && (
              <Button
                touches={"h-[44px] w-60 flex justify-center items-center"}
                variant="authBlue"
                size="small"
                isBtnDisabled={confirmOrderButton}
                onClick={() => {
                  // if (unConfirmedOrderNotification) {
                  //   setConfirmedOrderNotification(true);
                  //   setUnConfirmedOrderNotification(false);
                  //   orderDet.status = "completed";
                  //   setCofirmORderButton(true);
                  // }
                  acceptOrder();
                }}
              >
                {isLoading ? (
                  <ClockLoader
                    size={"30px"}
                    speedMultiplier={0.8}
                    color="#fafafa"
                  />
                ) : (
                  " Confirm Order delivery"
                )}
              </Button>
            )}
            {showBtnWhileProposalAccepted && (
              <Button
                variant="blue"
                size="small"
                isBtnDisabled={true}
                onClick={() => {}}
              >
                Confirm Order delivery
              </Button>
            )}

            <Button
              touches={"h-[44px]"}
              variant="white"
              size="small"
              isBtnDisabled={openDisputeButton}
              onClick={() => {
                setOpenDisputes(true);
              }}
            >
              {openDisputeButtonValue}
            </Button>
            <div>
              <p className="font-bold text-[#5271FF] tracking-wider cursor-pointer">
                Share reciept
              </p>
            </div>
          </div>
        )}
        {sellerBtn && (
          <div className="flex gap-6 items-center py-10 pb-9">
            <Button
              touches={"h-[44px]"}
              variant="blue"
              size="small"
              isBtnDisabled={true}
              onClick={() => {
                if (unConfirmedOrderNotification) {
                  setConfirmedOrderNotification(true);
                  setUnConfirmedOrderNotification(false);
                  orderDet.status = "completed";
                  setCofirmORderButton(true);
                }
              }}
            >
              Confirm Order delivery
            </Button>
            <Button
              touches={"h-[44px]"}
              variant="white"
              size="small"
              isBtnDisabled={true}
              onClick={() => {
                setOpenDisputes(true);
              }}
            >
              Open dispute
            </Button>
          </div>
        )}

        {confirmedOrderNotification && <ConfirmOrderNotificatioN />}

        {/* open buyers dispute Modal */}
        {openDispute && (
          <BuyerDisputeModal
            setIfHaveBuyerRemark={setIfHaveBuyerRemark}
            setDisAbleRejectProposalButton={setDisAbleRejectProposalButton}
            orderDet={orderDet}
            value={buyerRecieved}
            setValue={setBuyerRecieved}
            value2={buyerResolutionDetail}
            setvalue2={setBuyerResolutionDetail}
            setShowDisputes={setOpenDisputes}
            complaints={buyerRemark}
            selectedFiles={buyerSelectedFiles}
            setComplaints={setBuyerRemark}
            setSelectedFiles={setbuyerSelectedFile}
            setConfirmedDisputes={setShowDisputeResultBuyer}
            setCofirmORderButton={setCofirmORderButton}
            setOpenDisputeButton={setOpenDisputeButton}
            setOpenDisputeButtonValue={setOpenDisputeButtonValue}
          />
        )}
        {/* open sellers dispute Modal*/}
        {sellersModal && (
          <SellerDisputesMOdal
            orderDet={orderDet}
            value={sellerReciept}
            setValue={setSellerReciept}
            value2={sellerProposal}
            setvalue2={setSellerProposal}
            setShowDisputes={setsellersModal}
            complaints={sellerRemark}
            setComplaints={setSellerRemark}
            selectedFiles={sellerEvidence}
            setSelectedFiles={setSellerEvidence}
            setCofirmORderButton={setCofirmORderButton}
            setSellerAcceptProposal={setSellerAcceptProposal}
            setDisAbleRejectProposalButton={setDisAbleRejectProposalButton}
            setRejectedProposalButtonValue={setRejectedProposalButtonValue}
            setIfHaveSellerRemark={setIfHaveSellerRemark}
          />
        )}
      </div>

      {/* show buyers remark */}
      {showDisputeResultBuyer && (
        <div
          className={`${
            sellersModal === true || openDispute === true ? "hidden" : "block"
          }`}
        >
          <div className="w-full py-5 border-t-[1px] pt-24">
            <div className="">
              <MajorTitle title={"Disputes"} />
            </div>
            <table className="w-full">
              <thead className="text-left h-[44px] text-[#9B9697] text-[14px] font-[400]">
                <tr className="w-full">
                  {Object.keys(buyerRemarkHeaders[0]).map(
                    (key) =>
                      key !== "id" && (
                        <th
                          key={key}
                          className="capitalize pl-3 tracking-wider w-1/4"
                        >
                          {key}
                        </th>
                      )
                  )}
                </tr>
              </thead>
              {ifHaveBuyerRemark && (
                <RemarkOutput
                  Recieved={buyerRecieved}
                  Remark={buyerRemark}
                  ResolutionDetail={buyerResolutionDetail}
                  selectedFiles={buyerSelectedFiles}
                />
              )}
            </table>
          </div>

          {/* cancle buyers dispute */}

          {userType === "buyer" ? (
            <div
              className={`${
                sellerAcceptedNotification === true ? "hidden" : "block"
              } `}
            >
              <Button
                variant="white"
                size="small"
                onClick={() => {
                  setBuyerResolutionDetail("");
                  setBuyerRecieved("");
                  setBuyerRemark("");
                  setbuyerSelectedFile([]);
                  setShowDisputeResultBuyer(false);
                  setOpenDisputeButton(false);
                  setCofirmORderButton(false);
                  setOpenDisputeButtonValue("Open disputes");
                }}
              >
                Cancel Dispute
              </Button>
            </div>
          ) : null}

          {/* show seller review on buyers page */}
          {userType === "buyer" ? (
            <div className="w-full pt-10 ">
              <div className="  pt-6  w-full border-t-[1px]">
                {sellerAcceptedNotification && (
                  <div className="text-[#5C5959] text-lg font-[500]">
                    Seller accepted your proposal
                  </div>
                )}
                <table className="w-full">
                  <thead className="text-left h-[44px] text-[#9B9697] text-[14px] font-[400]  ">
                    <tr className="w-full">
                      {Object.keys(SellerrRemarkHeaders[0]).map(
                        (key) =>
                          key !== "id" && (
                            <th
                              key={key}
                              className="capitalize pl-3 tracking-wider w-1/4"
                            >
                              {key}
                            </th>
                          )
                      )}
                    </tr>
                  </thead>
                  {ifHaveSellerRemark && (
                    <SellerRemarkOutput
                      vendorRecieved={sellerReciept}
                      vendorRemark={sellerRemark}
                      vendorResolutionDetail={sellerProposal}
                      vendorFiles={sellerEvidence}
                    />
                  )}
                </table>
              </div>
            </div>
          ) : userType !== "buyer" ? (
            <div className="w-full pt-10">
              <div className="  pt-6 border-t-[1px] w-full">
                <table className="w-full ">
                  <thead className="text-left h-[44px] text-[#9B9697] text-[14px] font-[400] ">
                    <tr className="w-full">
                      {Object.keys(SellerrRemarkHeaders[0]).map(
                        (key) =>
                          key !== "id" && (
                            <th
                              key={key}
                              className="capitalize pl-3 tracking-wider w-1/4"
                            >
                              {key}
                            </th>
                          )
                      )}
                    </tr>
                  </thead>
                  {ifHaveSellerRemark && (
                    <SellerRemarkOutput
                      vendorRecieved={sellerReciept}
                      vendorRemark={sellerRemark}
                      vendorResolutionDetail={sellerProposal}
                      vendorFiles={sellerEvidence}
                    />
                  )}
                </table>
              </div>

              {userType === "buyer" ? null : (
                <div className="flex gap-6 items-center pt-10  ">
                  {sellerAcceptProposal && (
                    <Button
                      variant="blue"
                      size="small"
                      isBtnDisabled={disableAcceptedProposalButton}
                      onClick={() => {
                        setSellerRejectProposal(false);
                        setDisableAcceptedProposalButton(true);
                        setAcceptProporsalButtonValue(" Accepted proposal");
                        setSellerAcceptedNotification(true);
                        setCofirmORderButton(true);
                        setShowBtnWhileProposalDeclined(false);
                        setShowBtnWhileProposalAccepted(true);
                      }}
                    >
                      {acceptProporsalButtonValue}
                    </Button>
                  )}

                  {sellerRejectProposal && (
                    <Button
                      variant="white"
                      size="small"
                      isBtnDisabled={disAbleRejectProposalButton}
                      onClick={() => {
                        setsellersModal(true);
                      }}
                    >
                      {rejectedProposalButtonValue}
                    </Button>
                  )}
                </div>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </section>
  );
};

export default OrderConfirmation;
