import React, { useEffect } from "react";
import { TablE } from "../ReuableTable";
import TableFilter from "../Table-filter";
import { PuffLoader } from "react-spinners";
import { toast } from "react-toastify";
import useService from "../../service/useService";
import { getFromStore } from "../../utils/helpers";
import { useAppContext } from "../../context";

const DisputedOrders = () => {
  const tableHeadData = [
    {
      id: 1,
      name: "Name",
      amount: "Amount",
      OrderID: "OrderID",
      date: "Date",
      status: "Status",
    },
  ];

  const [disputeData, setDisputeData] = React.useState([]);
  const [originalData, setOriginalData] = React.useState([]);
  const [pageLoad, setPageLoad] = React.useState(true);
  const [hasOrders, setHasOrders] = React.useState(false);
  const { setAlOrderData } = useAppContext();
  const details = getFromStore("vendyz_user");
  const userID = details.id;
  const userType = details.user_type;
  const merchant_id = getFromStore("merchant_id");
  const basUrl = process.env.REACT_APP_ODER_BASE_URL;
  const Service = useService(basUrl);

  useEffect(() => {
    const getDetails = async () => {
      try {
        const response =
          userType === "buyer"
            ? await Service.get(`/buyer/orders/user/${userID}`)
            : await Service.get(`/vendor/orders/vendor/${merchant_id}`);
        if (response.status === 200) {
          const data = response.data.data.data;
          if (data.length === 0) {
            setHasOrders(true);
          } else {
            setHasOrders(false);
          }
          setAlOrderData(data);
          setDisputeData(data);
          setOriginalData(data);
          setPageLoad(false);
        }
      } catch (error) {
        if (error.message === "timeout exceeded") {
          toast.error("Timeout exceeded");
        } else if (error.message === "Network Error") {
          toast.error("Network error");
        }
      }
    };

    getDetails();
  }, []);

  const filterTable = (e) => {
    const input = e.target.value.toLowerCase();
    if (input.length > 0) {
      const filteredData = originalData.filter((item) =>
        item.item_description.toLowerCase().includes(input)
      );

      setDisputeData(filteredData);
    } else {
      setDisputeData(originalData);
    }
  };

  if (pageLoad) {
    return (
      <div className="h-72 w-full flex justify-center pt-44">
        <PuffLoader color="#5271FF" speedMultiplier={0.8} />
      </div>
    );
  }

  return (
    <div className="pt-10 ">
      <div className="w-full border-[1px] rounded-[20px]">
        <div className="px-3">
          <div className="flex flex-col gap-3 ">
            <div>
              <TableFilter title={"All orders"} onChange={filterTable} />
            </div>
            {!hasOrders ? (
              <div className="">
                <TablE
                  tHeaders={tableHeadData}
                  tRows={disputeData}
                  variant={"disputes"}
                />
              </div>
            ) : (
              <div className="rounded-[25px] py-[42px] px-[18px] border-[1px] h-[446px] ">
                <div className="flex justify-center items-center h-full">
                  <div className="flex flex-col items-center justify-center">
                    <h1 className="text-[1rem] font-[500] text-[#232323]">
                      No Dispute history
                    </h1>
                    <p className="text-[14px] font-[400] text-[#9B9697]">
                      It looks like you haven’t made any dispute yet. <br />{" "}
                      Start making dispute to see your history here.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisputedOrders;
