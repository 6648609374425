import product from "../../assets/imgs/Ellipse 2858.svg";
import product2 from "../../assets/imgs/Ellipse 2858 (1).svg";
import product4 from "../../assets/imgs/Ellipse 2858 (3).svg";

export const UnpaidlinksData = [
  {
    id: 1,
    productImg: product4,
    productName: "2000 Watt Hair Dryers..",
    link: "https://www.vendyz.com/mootigadgets",
    amount: "₦200,000.00",
    date: "Aug 12, 2023",
    status: "active",
  },
  {
    id: 2,
    productImg: product,
    productName: "2000 Watt Hair Dryers..",
    link: "https://www.vendyz.com/mootigadgets",
    amount: "₦200,000.00",
    date: "Aug 12, 2023",
    status: "expired",
  },
];
