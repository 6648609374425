import React from "react";
import { Outlet } from "react-router-dom";

const Page = ({ children }) => {
  return (
    <main className="overflow-auto h-[100%]">
      {children ? children : <Outlet />}
    </main>
  );
};
export const Payment = () => {
  return <Page />;
};
