import React, { useEffect, useState } from "react";
import MajorTitle from "../../../components/MajorTitle";
import Button from "../../../components/Button";
import OTPInpuT from "../../../components/MtuOtp";
import { useNavigate } from "react-router-dom";

const ReviewWithdraw = () => {
  const walletBalance = "₦200,000.00";
  const [otp, setOtp] = useState("");
  const [isBtnDisabled, setisBtnDisabled] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (otp.length === 4) {
      setisBtnDisabled(false);
    } else {
      setisBtnDisabled(true);
    }
  }, [otp]);

  return (
    <section>
      <div>
        <MajorTitle title={"Review your withdrawal"} />
      </div>

      <div className="flex justify-center flex-col gap-5 items-center ">
        <div className="flex px-2 w-[226px] h-[44px] border-[1px] border-[#F0F0F0] rounded-[25px] items-center justify-center gap-2 ">
          <div className="text-[12px] font-[500] text-[#5C5959]">
            {" "}
            Wallet balance
          </div>
          <div className="text-[#232323] font-[700] text-[12px] border-l-2 pl-3 py-2">
            {walletBalance}
          </div>
        </div>

        <div>
          <div className="">
            <p className="text-[16px] font-[400] text-[#9B9697] text-center">
              {" "}
              Total amount
            </p>
          </div>
          <div className="flex justify-center   h-[50px] items-center rounded-[25px]">
            <div className="  flex justify-center items-center  tracking-wide">
              <p className="text-[24px] font-[700] text-[#232323] ">₦</p>{" "}
              <span className="border-none  text-[24px] font-[700] text-[#232323] ">
                {" "}
                70,000.00
              </span>
            </div>
          </div>
        </div>

        <div className="w-[592px] pt-4">
          <div className="border-b-[1px] border-[#F0F0F0] w-full pb-3">
            <div className="flex justify-between ">
              <div>
                <p className="text-[#232323] font-[700] text-[16px] ">
                  Recipient
                </p>
              </div>
            </div>
          </div>

          <div className=" border-[#F0F0F0] w-full pb-3 pt-8 px-5">
            <div className="flex justify-between ">
              <div className="w-1/2  ">
                <p className="text-[#9B9697] font-[400] text-[14px] ">Bank</p>
              </div>

              <div className="w-1/2 ">
                <p className="text-[#232323] text-[16px] font-[500]">GT Bank</p>
              </div>
            </div>
          </div>

          <div className=" border-[#F0F0F0] w-full pb-3 pt-4 px-5">
            <div className="flex justify-between ">
              <div className="w-1/2  ">
                <p className="text-[#9B9697] font-[400] text-[14px] ">
                  Account number
                </p>
              </div>

              <div className="w-1/2 ">
                <p className="text-[#5C5959] text-[16px] font-[500]">
                  2024202420
                </p>
              </div>
            </div>
          </div>

          <div className=" border-[#F0F0F0] w-full pb-3 pt-4 px-5">
            <div className="flex justify-between ">
              <div className="w-1/2  ">
                <p className="text-[#9B9697] font-[400] text-[14px] ">
                  Account name
                </p>
              </div>

              <div className="w-1/2 ">
                <p className="text-[#5C5959] text-[16px] font-[500]">
                  Emmanuel Israel
                </p>
              </div>
            </div>
          </div>

          <div className=" border-[#F0F0F0] w-full pb-3 pt-4 px-5">
            <div className="flex justify-between ">
              <div className="w-1/2  ">
                <p className="text-[#9B9697] font-[400] text-[14px] ">
                  Remarks
                </p>
              </div>

              <div className="w-1/2 ">
                <p className="text-[#5C5959] text-[16px] font-[500]">
                  Get groceries
                </p>
              </div>
            </div>
          </div>

          <div className=" border-[#F0F0F0] w-full pb-3 pt-4 px-5">
            <div className="flex justify-between ">
              <div className="w-1/2  ">
                <p className="text-[#9B9697] font-[400] text-[14px] ">
                  Service fee
                </p>
              </div>

              <div className="w-1/2 ">
                <p className="text-[#5C5959] text-[16px] font-[500]">₦10.00</p>
              </div>
            </div>
          </div>

          <div className="pt-4 ">
            <div className="pb-3">
              <p className="text-[16px] font-[700] text-[#232323]">
                {" "}
                Transaction PIN
              </p>
            </div>
            <OTPInpuT otp={otp} setOtp={setOtp} />
          </div>

          <div className="w-full 2xl:pt-18  pt-14 flex justify-center">
            <div>
              <div className="flex gap-4 justify-center w-[592px] ">
                <div className="w-1/2">
                  <Button
                    onClick={() => {
                      navigate("/withdraw");
                    }}
                    size="fullwidth"
                    variant="white"
                    touches={"h-[44px]"}
                  >
                    Back
                  </Button>
                </div>
                <div className="w-1/2">
                  <Button
                    onClick={() => {
                      navigate("/withdraw/confirmed-withdraw");
                    }}
                    variant="authBlue"
                    size="fullwidth"
                    touches={"h-[44px]"}
                    isBtnDisabled={isBtnDisabled}
                  >
                    Withdraw money
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReviewWithdraw;
