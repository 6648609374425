import React, { useEffect } from "react";
import MajorTitle from "../../components/MajorTitle";
import OrdersCard from "../../components/orders-card";
import TableFilter from "../../components/Table-filter";
import { TablE } from "../../components/ReuableTable";
import { TransactionData } from "../../constants/transactionData";
import axios from "axios";
import { PuffLoader } from "react-spinners";
import { getFromStore } from "../../utils/helpers";
import NotFound from "../../assets/imgs/notFoundImg.jpg";

export const Transactionpage = () => {
  const [transactionData, setTransactionData] = React.useState("");
  const [pageLoading, setPageLoading] = React.useState(true);

  const [hasData, setHasData] = React.useState(false);
  const tableHeadData = [
    {
      id: 1,
      name: "Name",
      amount: "Amount",
      "Reference no": "Reference no",
      Date: "Date",
      status: "Status",
    },
  ];

  // addded somefew changes
  // useEffect(() => {
  //   getDetails();
  // }, []);

  useEffect(() => {
    const getDetails = async () => {
      try {
        const token = getFromStore("vendyz_REFTK");
        const response = await axios.get(
          "https://vendyz-transaction-service.onrender.com/transaction",
          {
            params: {
              page: 1,
              per_page: 15,
              user_type: "VENDOR",
              startDate: "2021-02-12",
              endDate: "2024-07-01",
            },
            headers: {
              bearerauth: token,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          console.log(response.data.data);
          const transactionData = response.data.data;
          setTransactionData(transactionData.data);
          console.log(transactionData.data.length);
          if (transactionData.length === 0) {
            setHasData(false);
          } else {
            setHasData(true);
          }

          setPageLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getDetails();
  }, []);
  if (pageLoading) {
    return (
      <div className="h-72 w-full flex justify-center pt-44">
        <PuffLoader color="#5271FF" speedMultiplier={0.8} />
      </div>
    );
  }

  return (
    <section>
      <div>
        <MajorTitle title={"Transactions"} />
      </div>
      <div>
        <div className="flex  w-full pt-8 gap-6">
          <div className="w-full">
            <OrdersCard title={"Transactions"} figure={0} amount={0} />
          </div>
          <div className="w-full">
            <OrdersCard title={"Credit"} figure={0} amount={0} />
          </div>
          <div className="w-full">
            <OrdersCard title={"Debit"} figure={0} amount={0} />
          </div>
        </div>
        <div className="pt-10">
          <div className="w-full border-[1px] rounded-[20px]">
            <div className="px-3">
              <div>
                <TableFilter title={"Transactions"} />
              </div>
              {!hasData ? (
                <div>
                  <TablE
                    tRows={transactionData}
                    tHeaders={tableHeadData}
                    variant={"transactions"}
                  />
                </div>
              ) : (
                <div className="flex justify-center items-center h-[400px]">
                  <div className="flex flex-col items-center justify-center">
                    <div>
                      <img
                        src={NotFound}
                        alt="/"
                        className="w-64 h-64 rounded-s-lg"
                      />
                    </div>

                    <p className="text-[14px] font-[400] text-[#9B9697] w-[60%] text-center">
                      It looks like you haven’t made any transactions yet. Start
                      making transactions to see your history here.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
