import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import arrowRight from "../../../assets/imgs/arrow-right (2).svg";
import sendicon from "../../../assets/imgs/send-2 (1).svg";
import blueTickIcon from "../../../assets/imgs/tick-circle.svg";
import Button from "../../../components/Button";

import shopIcon from "../../../assets/imgs/shop.svg";
export const JoinOption = () => {
  const navigate = useNavigate();

  const [highlightedCard, setHighlightedCard] = React.useState("");
  const [btnDisabled, setBtnDisabled] = React.useState(true);
  const [submit, setSubmit] = React.useState(null);

  useEffect(() => {
    if (submit === null) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  }, [submit]);

  const cards = [
    {
      id: "card-1",
      bgColor: "bg-white",
      borderColor: "border-[#F0F0F0]",
      iconBg: "bg-[#F4F4F4]",
      title: "Buyer",
      subtitle: "Join as a buyer, buying or paying for goods or services.",
      tickIcon: false,
      icon: sendicon,
    },
    {
      id: "card-2",
      bgColor: "bg-white",
      borderColor: "border-[#F0F0F0]",
      iconBg: "bg-[#F4F4F4]",
      title: "Vendor/Seller",
      subtitle: " Join as a vendor creating and sending payment links.",
      tickIcon: false,
      icon: shopIcon,
    },
  ];

  const highlightCard = (id) => {
    setHighlightedCard(id === highlightedCard ? null : id);
  };

  return (
    <section>
      <div className="w-full flex flex-col items-center justify-center pt-[1%] xl:pt-[3%]">
        {/* tittle */}
        <div className=" flex flex-col justify-center items-center md:flex md:items-center md:flex-col  ">
          <div className="text-[24px] font-bold text-[#232323]">
            <h1> Join vendyz waitlist </h1>
          </div>
          <div className=" text-sm px-4  text-[#9B9697] text-center md:px-0 lg:text-left">
            <h2>
              Thank you for your patience and enthusiasm. We can't wait to have
              you onboard!
            </h2>
          </div>
        </div>
        {/* cards */}
        <div>
          <div className="2xl:pt-16 pt-5 ">
            <div className="flex flex-col lg:flex lg:flex-row gap-5 ">
              {cards.map((card) => (
                <div
                  key={card.id}
                  onClick={() => {
                    highlightCard(card.id);
                    highlightedCard
                      ? setBtnDisabled(true)
                      : setBtnDisabled(false);
                    setSubmit(card.id);
                  }}
                  className={`border-[1px] rounded-[25px] cursor-pointer hover:scale-105 duration-300 ${
                    highlightedCard === card.id
                      ? "border-[#5271FF] shadow-sm shadow-[#5271FF] drop-shadow-sm bg-[#F3F5FD]"
                      : card.borderColor
                  } w-72 h-[260px] p-4 flex flex-col gap-20 px-6 relative`}
                >
                  {highlightedCard === card.id && (
                    <div className="absolute -top-5 -right-2">
                      <img src={blueTickIcon} alt="" />
                    </div>
                  )}
                  <div
                    className={`w-[70px] h-[70px] rounded-[100px] ${
                      highlightedCard === card.id ? "bg-[#E5EAFF]" : card.iconBg
                    } border-[1px] border-[#F6F6F6] flex justify-center items-center`}
                  >
                    <img src={card.icon} alt="" />
                  </div>
                  <div>
                    <h1 className="text-[#232323] text-[24px] font-bold pb-2">
                      {card.title}
                    </h1>
                    <h2 className="text-sm text-[#9B9697]">{card.subtitle}</h2>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="2xl:pt-14 pt-8 md:pb-5 mb-4">
            <Button
              size="fullwidth"
              variant="authBlue"
              isBtnDisabled={btnDisabled}
              touches={"h-[44px]"}
              onClick={() => {
                if (submit === "card-1") {
                  navigate(`/waitlist/buyer`);
                } else if (submit === "card-2") {
                  navigate("/waitlist/seller");
                } else {
                  setSubmit(null);
                  setBtnDisabled(true);
                }
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
