import React from "react";
import MajorTitle from "../../../components/MajorTitle";
import { useNavigate } from "react-router-dom";
import arrowRight from "../../../assets/imgs/arrow-right (2).svg";
import { TransactionData } from "../../../constants/transactionData";
import { useParams } from "react-router-dom";
import { GetTransactionsDataTable } from "../../../components/GetTransactionDataTable";
import bankLogo from "../../../assets/imgs/bankLogo.svg";
import mooiIcon from "../../../assets/imgs/mooiIcon.svg";
import { TransactionPaymentInfoTable } from "../../../components/transactionPaymentTable";
import Button from "../../../components/Button";

export const TransactionConfirmation = () => {
  const params = useParams();
  const transactionData = TransactionData.find(
    (order) => order.id === Number(params.transactionConfirmation)
  );

  const tableHead = [
    {
      amount: "Amount",
      "Reference number": "Reference number",
      "Payment date": "Payment date",
      Type: "Type",
    },
  ];

  const transactionTableHead = [
    {
      id: 1,
      "Payment to": "Payment to",
      "Payment from": "Payment from",
      Remarks: "Remark",
    },
  ];

  const reciepientInfo = {
    phoneNumber: "+2347097783230",
    logo: mooiIcon,
    email: "mootigadgets@gmail.com",
    name: "Emmanuel Israel",
  };

  const senderDetails = {
    bankName: "ECOBANK PLC",
    banklogo: bankLogo,
    phoneNumber: "+2348048740391",
    profilepicture: transactionData.profilePicture,
  };

  const [disabledBtnBlue, setDisabledBtnBlue] = React.useState(false);
  const [disabledBtnWhite, setDisabledBtnWhite] = React.useState(false);

  const navigate = useNavigate();
  return (
    <section>
      <div
        onClick={() => navigate("/transactions")}
        className=" rounded-[100px] w-[101px] h-[40px] flex  items-center shadow-sm justify-center gap-2 border-[1px] border-[#F0F0F0] cursor-pointer"
      >
        <div>
          <img src={arrowRight} alt="" />
        </div>
        <div>
          <h1 className="text-[#5C5959] font-semibold text-sm ">Back</h1>
        </div>
      </div>
      <div className="pt-10 pl-2">
        <MajorTitle title={"Details"} />
      </div>
      <div className="pb-24 border-[#F0F0F0] border-b-[1px]">
        <GetTransactionsDataTable
          tableHeadData={tableHead}
          data={transactionData}
        />
      </div>
      <div className="pt-5">
        <TransactionPaymentInfoTable
          data={transactionData}
          tHeaders={transactionTableHead}
          RecipientName={reciepientInfo.name}
          recipeientPhoneNum={reciepientInfo.phoneNumber}
          recipientEmail={reciepientInfo.email}
          recipientLogo={reciepientInfo.logo}
          senderBankLogo={senderDetails.banklogo}
          senderBankName={senderDetails.bankName}
          senderPhoneNumber={senderDetails.phoneNumber}
          senderProfilePicture={senderDetails.profilepicture}
        />
      </div>
      <div className="flex gap-6 items-center pt-24">
        <Button
          variant="blue"
          size="small"
          isBtnDisabled={disabledBtnBlue}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          Share receipt
        </Button>

        <Button variant="white" size="small" isBtnDisabled={disabledBtnWhite}>
          Repeat transaction
        </Button>
        <div>
          <p className="font-bold text-[#5271FF] tracking-wider cursor-pointer">
            Report transaction
          </p>
        </div>
      </div>
    </section>
  );
};
