import React from "react";
import copy from "../../assets/imgs/copy.svg";

export const GetTransactionsDataTable = ({ tableHeadData, data }) => {
  const bgFilter = (value) => {
    if (value === "completed" || value === "active" || value === "credit") {
      return "capitalize font-medium h-[28px] w-[107px] text-sm  pt-1 pb-1 rounded-[100px] bg-[#EFFFF6] text-[#449E6A] border-[1px] border-[#83F3B2] ";
    } else if (value === "pending") {
      return `capitalize font-medium h-[28px] w-[107px] text-sm  pt-1 pb-1 rounded-[100px] 
            text-[#232323] border-[1px] border-[#F0F0F0] bg-zinc-200`;
    } else if (
      value === "cancelled" ||
      value === "expired" ||
      value === "debit"
    ) {
      return "capitalize font-medium h-[28px] w-[107px] text-sm  pt-1 pb-1 rounded-[100px] bg-[#FFE8E8] text-[#EF5959] border-[1px] border-[#FFBBBB] ";
    }
  };

  const transactionAmounColorFilter = (value) => {
    if (value === "credit") {
      return "text-[#449E6A]";
    } else {
      return "text-[#232323]";
    }
  };

  return (
    <section>
      <div className="pt-20 px-4">
        <table className="w-full  ">
          <thead className="text-left h-[44px] text-[#9B9697] text-[14px] font-[400] border-[#F0F0F0]">
            <tr>
              {Object.keys(tableHeadData[0]).map(
                (key) =>
                  key !== "id" && (
                    <th
                      key={key}
                      className="capitalize pl-3 tracking-wider font-light"
                    >
                      {key}
                    </th>
                  )
              )}
            </tr>
          </thead>
          <tbody>
            <tr
              key={data.id}
              className={`h-[70px] border-[#F0F0F0]  cursor-pointer `}
            >
              {/* amount */}
              <td>
                <div>
                  <p
                    className={`${transactionAmounColorFilter(
                      data.status
                    )} font-[500] text-lg `}
                  >
                    {data.amount}
                  </p>
                </div>
              </td>
              {/* order id */}

              <td>
                <div>
                  <p className="text-[#232323] font-[500] text-lg">
                    {data.referenceNo}
                  </p>

                  <div className="flex gap-2 items-center pt-3">
                    <img src={copy} alt="" />
                    <p className="text-[#5271FF] text-[14px] font-[400]">
                      Copy
                    </p>
                  </div>
                </div>
              </td>
              {/* date */}
              <td>
                <p className="text-[#232323] text-lg font-[400]">{data.date}</p>
              </td>

              {/* status */}
              <td className=" pl-2">
                <p
                  className={`${bgFilter(
                    data.status
                  )} text-center cursor-pointer `}
                >
                  {data.status}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};
