import React, { useEffect, useState } from "react";
import { CiImageOn } from "react-icons/ci";
import { HiOutlineXMark } from "react-icons/hi2";
import { toast } from "react-toastify";

const InputFile = ({ selectedFile, setSelectedFile }) => {
  // const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [filesSelected, setFilesSelected] = useState(false);
  const [filesFailedToUpload, setFilesFailedToUpload] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    handleFile(file);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleFile(file);
  };

  const handleFile = (file) => {
    const allowedFormats = ["image/jpeg", "image/png"];
    const maxSize = 1 * 1024 * 1024; // 1 MB

    if (!allowedFormats.includes(file.type)) {
      setErrorMessage(`${file.name} is not a supported format (PNG or JPG).`);
      return;
    }

    if (file.size > maxSize) {
      setErrorMessage(`${file.name} exceeds the maximum file size of 1 MB.`);
      return;
    }

    setSelectedFile(file);
    setFilesSelected(true);
    setErrorMessage("");
  };

  const handleDelete = () => {
    setSelectedFile(null);
    setFilesSelected(false);
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      setErrorMessage("No file selected.");
      return;
    }

    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      const response = await fetch("your_api_endpoint", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload image.");
      }

      setSuccessMessage("Image uploaded successfully.");
      toast.success("Image uploaded successfully");
    } catch (error) {
      setErrorMessage("Failed to upload image. Please try again.");
      toast.error("Failed to upload image. Please try again");
      setFilesFailedToUpload(true);
      setFilesSelected(false);
    }
  };

  const handleXMarkClick = () => {
    setErrorMessage("");
    setFilesFailedToUpload(false);
    setFilesSelected(false); // Show drag-and-drop area when x-mark is clicked
  };

  return (
    <div>
      {!filesSelected && (
        <div
          className={`border-[1px] border-[#F0F0F0] p-4 justify-center items-center bg-[#FAFAFA] h-[20%] rounded-[20px] shadow-sm ${
            errorMessage ? "hidden" : "flex"
          }`}
          onDragOver={(event) => event.preventDefault()}
          onDrop={handleDrop}
        >
          <input
            type="file"
            id="fileInput"
            onChange={handleFileChange}
            accept=".jpg, .jpeg, .png"
            className="hidden"
          />
          <div className="flex justify-center items-center">
            <label
              htmlFor="fileInput"
              className="h-[90px] w-full rounded-[20px] bg-[#FAFAFA] text-white  cursor-pointer flex justify-center items-center"
            >
              <div className="flex flex-col items-center gap-1 py-5">
                <CiImageOn color="#9B9697" size={55} />
                <div className="text-[14px]  font-[500] w-full">
                  <span className="text-[#5271FF] text-sm">
                    Click to upload
                  </span>
                  <span className="text-[#9B9697]"> or drag and drop</span>
                </div>
                <div>
                  <p className="text-[#9B9697] text-[14px] ">
                    PNG and JPG (max. size 1 MB) only
                  </p>
                </div>
              </div>
            </label>
          </div>
        </div>
      )}
      {(errorMessage || filesFailedToUpload) && (
        <div className="mt-4">
          <div className="flex justify-center items-center">
            <div className="flex items-center justify-between w-full bg-white border-[1px] border-[#E51837] rounded-[20px] shadow-sm px-4 py-4">
              <div className="flex gap-2 items-center">
                <div className="h-5 w-5 rounded-[100px] bg-red-500 flex justify-center items-center">
                  <HiOutlineXMark color="white" size={15} />
                </div>
                <p className="text-[#232323] text-[16px] font-[500]">
                  {errorMessage}
                </p>
              </div>
              <div className="cursor-pointer">
                <HiOutlineXMark
                  id="x-mark"
                  onClick={handleXMarkClick}
                  color="#9B9697"
                  size={25}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {successMessage && <p className="text-green-500">{successMessage}</p>}

      {filesSelected && (
        <div className="mt-4">
          <div className="flex items-center justify-between w-full bg-white border-[1px] border-[#F0F0F0] rounded-[20px] shadow-sm px-4 py-4">
            <div className="flex gap-2 items-center">
              <div className="h-16 w-16 rounded-[100px] bg-[#FAFAFA] flex justify-center items-center">
                <img
                  src={URL.createObjectURL(selectedFile)}
                  alt={selectedFile.name}
                  className="h-8 w-8 object-cover"
                />
              </div>
              <p>{selectedFile.name}</p>
            </div>
            <div className="cursor-pointer">
              <HiOutlineXMark
                onClick={handleDelete}
                color="#9B9697"
                size={25}
              />
            </div>
          </div>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-green-500 text-white rounded mt-4 hidden"
          >
            Upload
          </button>
        </div>
      )}
    </div>
  );
};

export default InputFile;
