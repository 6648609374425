import React from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import arrowRight from "../../../assets/imgs/arrow-right (2).svg";
import decoration from "../../../assets/imgs/authDecor.svg";
import smsIcon from "../../../assets/imgs/smsnav.svg";
import locationIcon from "../../../assets/imgs/location.svg";
import blueSms from "../../../assets/imgs/smsblue.svg";
import profileIcon from "../../../assets/imgs/profile.svg";
import { useLocation } from "react-router-dom";
import blueLocationIcon from "../../../assets/imgs/location blue.svg";
import blueProfileIcon from "../../../assets/imgs/frame.svg";
export const SellerReg = () => {
  const { pathname } = useLocation();

  const params = useParams();
  const email = params.selleremail;
  const token = params.req_token;

  const navigate = useNavigate();

  return (
    <section className="w-full h-full">
      <div
        onClick={() => navigate("/")}
        className="rounded-[100px] w-44 h-[40px] flex items-center shadow-sm justify-center gap-2 border-[1px] border-[#F0F0F0] cursor-pointer"
      >
        <div>
          <img src={arrowRight} alt="" />
        </div>
        <div>
          <h1 className="text-[#5C5959] font-semibold text-sm ">
            Back to home
          </h1>
        </div>
      </div>
      <div className="flex pt-7 h-[calc(100%-40px)]  ">
        <div className="w-[35%] bg-[#FAFAFA] rounded-t-[20px] relative px-3  2xl:px-0  2xl:w-[30%]">
          <nav className="flex justify-center pt-16 z-50  ">
            <ul className="flex justify-center flex-col lg:gap-9 2xl:gap-14">
              <li
                className={`${
                  pathname === "/auth/register/seller"
                    ? "text-[#5271FF] pl-5"
                    : "text-[#9B9697]"
                }`}
              >
                <div className="flex gap-2 cursor-pointer ">
                  <div>
                    {/* <ProfileIcon
                      active={pathname === "/auth/buyer" ? true : false}
                    /> */}
                    <img
                      src={
                        pathname === "/auth/register/seller"
                          ? blueProfileIcon
                          : profileIcon
                      }
                      alt=""
                    />
                  </div>
                  <div>
                    <h1 className=" text-bold font-[500] text-lg">
                      Provide your details
                    </h1>
                    <p className=" font-[500] text-sm">
                      Provide an email and password.
                    </p>
                  </div>
                </div>
              </li>
              <li
                className={` z-10 ${
                  pathname === `/auth/register/seller/${email}/${token}`
                    ? "text-[#5271FF] pl-5"
                    : "text-[#9B9697]"
                }`}
              >
                <div className="flex gap-2 cursor-pointer">
                  <div>
                    <img
                      src={
                        pathname === `/auth/register/seller/${email}/${token}`
                          ? blueSms
                          : smsIcon
                      }
                      alt=""
                    />
                  </div>
                  <div>
                    <h1 className="text-bold font-[500] text-lg">
                      Verify your email
                    </h1>
                    <p className="font-[500] text-sm">
                      Enter the verification code sent to your email
                    </p>
                  </div>
                </div>
              </li>

              <li
                className={` z-10 ${
                  pathname === "/auth/register/seller/contact-information"
                    ? "text-[#5271FF] pl-5"
                    : "text-[#9B9697]"
                }`}
              >
                <div className="flex gap-2 cursor-pointer ">
                  <div>
                    <img
                      src={
                        pathname === "/auth/register/seller/contact-information"
                          ? blueLocationIcon
                          : locationIcon
                      }
                      alt=""
                    />
                  </div>
                  <div>
                    <h1 className=" text-bold font-[500] text-lg ">
                      Contact information
                    </h1>
                    <p className=" font-[500] text-sm">
                      Tell us how we can reach you.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </nav>
          <div className="absolute bottom-0 right-0 w-[60%]">
            <img src={decoration} alt="" className="w-full" />
          </div>
        </div>
        <div className="2xl:w-[70%] w-[60%] h-full overflow-auto">
          <Outlet />
        </div>
      </div>
    </section>
  );
};
