import React from "react";
import { Space, TimePicker } from "antd";

const GetTime = ({ onChange }) => (
  <div className="w-full flex flex-col gap-1">
    <label className="text-[#5C5959] font-[400] tracking-wider text-[14px]">
      Link expiry time
    </label>
    <Space wrap direction="vertical" style={{ width: "100%", height: "100%" }}>
      <TimePicker
        use12Hours
        format="h:mm a"
        onChange={onChange}
        className="w-full h-[50px] outline-none focus:outline-none rounded-[20px]"
        pickerStyle={{
          borderRadius: "20px",
          display: "flex",
          alignItems: "center",
          gap: "2px",
          outline: "none",
          height: "100%",
          padding: "0 2px",
          boxShadow: "0 0 5px rgba(0,0,0,0.1)",
        }}
      />
    </Space>
  </div>
);

export default GetTime;
