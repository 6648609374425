import React, { useEffect, useState } from "react";
import logo from "../../../../assets/imgs/Logo.svg";
import Textfield from "../../../../components/Textfield";

import Button from "../../../../components/Button";
import { useNavigate } from "react-router-dom";
import { InternationalPhone } from "../../../../components/international-phone-input";
import createAxiosInstance from "../../../../service";
import { ClockLoader } from "react-spinners";
import { toast } from "react-toastify";
import { getFromStore, saveInStore } from "../../../../utils/helpers";

import { Country } from "country-state-city";
import { City, State } from "country-state-city";
import Selector from "../../../../components/contry-select-input";

export const Sellercontact = () => {
  const navigate = useNavigate();
  const basUrl = process.env.REACT_APP_VENDOR_BASE_URL;
  const Service = createAxiosInstance(basUrl);
  const countryData = Country.getAllCountries();

  const sellerInfo = getFromStore("vendyzUserData");

  const [btnDisabled, setBtnDisabled] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [adress, setAddress] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [businessName, setBudinessName] = React.useState("");
  const [stateData, setStateData] = useState();
  const [cityData, setCityData] = useState();

  // console.log(state);

  const getDetails = async (e) => {
    e.preventDefault();
    const businessID = getFromStore("merchant_businessID");

    const body = {
      user_id: Number(sellerInfo.id),
      first_name: firstName,
      last_name: lastname,
      merchant_business_id: Number(businessID),
      business_name: businessName,
      phone: phoneNumber,
      address: String(adress),
      city: city.name,
      state: state.name,
      country: country.name,
    };
    console.log(body);

    try {
      setIsLoading(true);
      setBtnDisabled(true);
      const response = await Service.post("/merchant/auth/contact/info", body);
      if (response.status === 201) {
        navigate("/auth");
        const bussiness = response.data.data.user.merchant_business;
        saveInStore("merchant_bussness", bussiness);
        console.log(response);
      }
    } catch (error) {
      setIsLoading(false);
      setBtnDisabled(false);

      console.log(error);
      if (error.message === "Network Error") {
        toast.error("Network timeout");
      } else if (error.message === "timeout exceeded") {
        toast.error("Network timeout");
      } else {
        const errorMsg = error.response.data.message;
        toast.error(errorMsg);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (
      firstName !== "" &&
      lastname !== "" &&
      phoneNumber !== "" &&
      adress !== "" &&
      country !== "" &&
      state !== "" &&
      city !== "" &&
      businessName !== ""
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [
    firstName,
    lastname,
    phoneNumber,
    adress,
    country,
    state,
    city,
    businessName,
  ]);

  useEffect(() => {
    setStateData(State.getStatesOfCountry(country?.isoCode));
  }, [country]);

  useEffect(() => {
    setCityData(City.getCitiesOfState(country?.isoCode, state?.isoCode));
  }, [state, country]);

  useEffect(() => {
    stateData && setState(stateData[0]);
  }, [stateData]);

  useEffect(() => {
    cityData && setCity(cityData[0]);
  }, [cityData]);

  return (
    <section>
      <div className="flex justify-center">
        <img src={logo} alt="" className="" />
      </div>
      <div className="pt-2">
        <div className="flex justify-center">
          <h1 className="text-[27px] font-[700] text-[#232323]">
            Contact information
          </h1>
        </div>

        <div className="flex justify-center">
          <p className="text-[#9B9697] text-[16px] text-center text-[400]">
            This information verifies you and lets us reach out to you.
          </p>
        </div>
        {/* form */}
        <form className="pt-7  flex-col flex items-center justify-center ">
          <div className="w-[500px] flex-col flex gap-8 ">
            <div className="flex items-center justify-center gap-4 w-full">
              <div className="w-[250px]">
                <Textfield
                  type={"text"}
                  labal={"First name"}
                  placeholder={"John"}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  value={firstName}
                  labelStyle={"text-[14px] text-[#5C5959] font-[400] w-full"}
                />
              </div>

              <div className="w-[250px]">
                <Textfield
                  type={"text"}
                  labal={"Last name"}
                  placeholder={"Doe"}
                  value={lastname}
                  onChange={(e) => {
                    setLastname(e.target.value);
                  }}
                  labelStyle={"text-[14px] text-[#5C5959] font-[400] w-full"}
                />
              </div>
            </div>

            <div className="text-[14px] text-[#5C5959] font-[400] w-full">
              <div className="">Phone number</div>
              <InternationalPhone
                backgroundColor={"white"}
                phone={phoneNumber}
                setPhone={setPhoneNumber}
              />
            </div>

            <div>
              <Textfield
                type={"text"}
                labal={"Business name"}
                placeholder={"vendyz"}
                onChange={(e) => {
                  setBudinessName(e.target.value);
                }}
                value={businessName}
                labelStyle={"text-[14px] text-[#5C5959] font-[400] w-full"}
              />
            </div>

            <div>
              <Textfield
                type={"text"}
                labal={"Address"}
                placeholder={"7 example  Street"}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                value={adress}
                labelStyle={"text-[14px] text-[#5C5959] font-[400] w-full"}
              />
            </div>

            <div>
              <Selector
                placeholder={"Select options"}
                label={"Country"}
                selected={country}
                setSelected={setCountry}
                data={countryData}
              />
            </div>

            <div>
              <div className="flex items-center justify-center gap-4 w-full">
                <div className="w-[250px]">
                  <Selector
                    placeholder={"Select options"}
                    label={"State"}
                    selected={state}
                    setSelected={setState}
                    data={stateData}
                  />
                </div>

                <div className="w-[250px]">
                  <Selector
                    placeholder={"Select options"}
                    label={"City"}
                    selected={city}
                    setSelected={setCity}
                    data={cityData}
                  />
                </div>
              </div>
              <div>
                <div className="pt-16">
                  <Button
                    isBtnDisabled={btnDisabled}
                    touches={"h-[44px]"}
                    onClick={getDetails}
                    variant="authBlue"
                  >
                    {isLoading ? (
                      <ClockLoader
                        size={"30px"}
                        speedMultiplier={0.8}
                        color="#fafafa"
                      />
                    ) : (
                      "Finish and launch Vendyz"
                    )}
                  </Button>
                </div>
              </div>
              <div className="flex items-center justify-center gap-8 pt-6 pb-5">
                <div>
                  <h1 className="text-[16px] font-[400] text-[#5C5959]">
                    Already have an account?
                  </h1>
                </div>

                <div>
                  <h1
                    onClick={() => navigate("/auth")}
                    className="text-[16px] font-[500] text-[#5271FF] cursor-pointer"
                  >
                    Log in
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};
