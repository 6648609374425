import React from "react";

export const IndexOrdersNav = ({
  showOrders,
  setShowOrders,
  setShowAcceptedOrders,
  showAccepted,
}) => {
  return (
    <div className="bg-[#F6F6F6] rounded-[100px] w-[60%] h-[39px] flex justify-between gap-3  items-center px-3 ">
      <div
        onClick={() => {
          setShowOrders(true);
          setShowAcceptedOrders(false);
        }}
        className={` ${
          showOrders
            ? "bg-[#FFFFFF] font-semibold shadow-sm rounded-[100px] text-[#232323] flex items-center"
            : "text-[#9B9697]"
        }flex items-center hover:bg-[#FFFFFF] hover:shadow-sm hover:rounded-[100px] h-[35px] w-[50%] text-center justify-center gap-1 cursor-pointer hover:text-[#232323] text-[#9B9697] hover:font-semibold px-2 `}
      >
        <p className={`${showOrders ? " text-[#232323]" : "text-[#9B9697]"}`}>
          All orders
        </p>
      </div>

      <div
        onClick={() => {
          setShowOrders(false);
          setShowAcceptedOrders(true);
        }}
        className={` ${
          showAccepted
            ? "bg-[#FFFFFF] shadow-sm rounded-[100px] text-[#232323] font-semibold flex items-center"
            : "text-[#9B9697]"
        }flex items-center hover:bg-[#FFFFFF] hover:shadow-sm hover:rounded-[100px] h-[35px] w-[50%] text-center justify-center gap-1 cursor-pointer hover:text-[#232323] text-[#9B9697] hover:font-semibold px-2`}
      >
        <p className={`${showAccepted ? " text-[#232323]" : "text-[#9B9697]"}`}>
          Accepted orders
        </p>
      </div>
    </div>
  );
};
