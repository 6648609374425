import React from "react";
import exclamation from "../../assets/imgs/Button (3).svg";
import rectangle from "../../assets/imgs/Rectangle 6288.svg";
import naira from "../../assets/imgs/Text.svg";
import copy from "../../assets/imgs/copy.svg";
import send from "../../assets/imgs/send-2.svg";
import polygon from "../../assets/imgs/Polygon 1.svg";
import { useNavigate } from "react-router-dom";

const Card = ({
  bg,
  fade,
  icon,
  balance,
  tittle,
  card,
  iconBg,
  alertMessg,
}) => {
  const [message, setMessage] = React.useState(false);

  const [userlink, setLink] = React.useState("  https://www.pay...");
  const [showCopied, setShowCopied] = React.useState(false);
  const navigate = useNavigate();

  function copyToCipboard(value) {
    const result = navigator.clipboard.writeText(value);

    result.then(() => {
      setShowCopied(true);
      setTimeout(() => {
        setShowCopied(false);
      }, [1500]);
    });
  }

  return (
    <div className=" w-[50%]">
      <div
        className={`${bg} rounded-[25px] relative border-[1px] flex flex-col gap-3  w-full`}
      >
        <img src={fade} alt="" className="absolute top-0 right-0" />
        <div className="p-3">
          <div className="flex justify-between  ">
            <div className=" flex  items-center gap-2">
              <div
                className={` rounded-full hover:shadow-lg flex justify-center p-2 ${iconBg}`}
              >
                <img src={icon} alt="" />
              </div>
              <span className="text-[16px] text-[#5C5959] z-20 font-semibold">
                {tittle}
              </span>
            </div>
            <div className="z-30 relative">
              <div className="absolute bottom-0 -right-2 top-10 ">
                {message && (
                  <div>
                    <div className="">
                      <img
                        src={polygon}
                        alt=""
                        className="absolute right-5 -top-2"
                      />
                    </div>
                    <div className="w-[279px] bg-[#1F2734] p-5 text-sm text-white rounded-[20px] ">
                      <p className=""> {alertMessg}</p>
                    </div>{" "}
                  </div>
                )}
              </div>
              <div
                onMouseEnter={() => setMessage(true)}
                onMouseLeave={() => setMessage(false)}
              >
                <img
                  src={exclamation}
                  alt=""
                  className="z-10 hover:cursor-pointer"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-4 pt-4 h-full">
          <div className="">
            <img src={rectangle} alt="" />
          </div>
          <div className="flex-col flex gap-12 z-10">
            <div className="flex gap-2">
              <div>
                <img src={naira} alt="" />
              </div>
              <div className="text-[36px] text-[#232323] font-extrabold  tracking-wide">
                {balance}
              </div>
            </div>
            <div>
              {card === "escrowBalance" ? (
                <div className="">
                  <div className="border-t-2 border-[#F0F0F0] w-full ">
                    <div className="w-[233px]">
                      <p className="text-[#9B9697] text-sm pt-3">Payment ID</p>
                      <div className="flex items-center">
                        <p className="text-[#5C5959] text-[16px] font-semibold">
                          {userlink}
                        </p>
                        <div
                          onClick={() => copyToCipboard(userlink)}
                          className="cursor-pointer flex items-center relative"
                        >
                          <div>
                            <img src={copy} alt="" />
                          </div>
                          <div>
                            <p className="text-[#5271FF] text-[12px] pl-1">
                              Copy
                            </p>
                          </div>
                          {showCopied && (
                            <div className="text-sm text-bold absolute -bottom-8 tracking-wider text-white w-[80px]">
                              <p className="text-white  bg-indigo-400 rounded-lg py-1 px-3  w-full">
                                Copied!
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className=" px-1 py-2">
                  <div className=" border-t-2 border-[#F0F0F0] w-full ">
                    <div className="w-[233px] flex items-center pt-3">
                      <button
                        onClick={() => {
                          navigate("/withdraw");
                        }}
                        className="flex items-center justify-center gap-[10px] text-[#5C5959] text-[16px] py-[12px] px-[20px] bg-white rounded-[100px] h-[44px] w-[143px] hover:scale-105 transition duration-300"
                      >
                        <span>
                          <img src={send} alt="" />
                        </span>
                        Withdraw
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div> 
      </div>
    </div>
  );
};

export default Card;
