import React, { lazy, Suspense, useEffect } from "react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import Homepage from "../modules/Home-page";
import { Orders } from "../modules/orders";
import OrdersTbale from "../components/orders-table";
import DisputedOrders from "../components/Disputed-orders";
import { HashLoader } from "react-spinners";
import OrderConfirmation from "../modules/order-confirmation";
import UnAuthenticated from "../layout/unAuthenticated";
import LoginAuth from "../modules/login/email&paswrd";
import OtpCode from "../modules/login/OtpCode";
import ForgottenPassword from "../modules/login/Forgotten-password";
import ResetPassword from "../modules/login/resetPassword";
import { Transactionpage } from "../modules/transactions";
import { TransactionConfirmation } from "../modules/transactions/transactionsConfirmation";
import { Paymentlinks } from "../modules/pamentLinnks";
import { AllLinksTable } from "../modules/pamentLinnks/all-links";
import { Register } from "../modules/Register";
import { OnBoarding } from "../modules/Register/Onboarding";
import { BuyerReg } from "../modules/Register/BuyerReg";
import { CreateAcctForBuyer } from "../modules/Register/BuyerReg/Create-account";
import { EmailConfigBuyer } from "../modules/Register/BuyerReg/EmailConfig";
import { BuyerLinkPrevAcct } from "../modules/Register/BuyerReg/linkprevAcct";
import { Buyercontact } from "../modules/Register/BuyerReg/Contact";
import { SellerReg } from "../modules/Register/SellerReg";
import { CreateSellerAccout } from "../modules/Register/SellerReg/CreateAcct";
import { SellerConfigEmail } from "../modules/Register/SellerReg/EmailConfig";
import { Sellercontact } from "../modules/Register/SellerReg/Contact";
import { PaidLinksTable } from "../modules/pamentLinnks/paid-links";
import { UnPaidLinksTable } from "../modules/pamentLinnks/unpaid-links";
import { DisputesCOnfirmation } from "../modules/order-confirmation/Dispute-confirmation";
import { Generatelink } from "../modules/generate-link";
import { PaymetLInkGeneration } from "../modules/generate-link/Payment-link-generation";
import { LinkConfirmation } from "../modules/generate-link/confirmation";
import { BuyerLInkInvitation } from "../modules/generate-link/link-invitation";
import { Payment } from "../modules/Payment";
import { PaymentOPtion } from "../modules/Payment/payment-options";
import { CardPayment } from "../modules/Payment/Card-payment";
import { ReviewPaymnet } from "../modules/Payment/Review-payment";
import { ConfirmedPayment } from "../modules/Payment/succesful-payment";
import { BankTransfer } from "../modules/Payment/Bank-transfer";
import { LinkCOnfirm } from "../modules/pamentLinnks/Links-confirm";
import { Withdraw } from "../modules/Withdraw";
import WithdrawPage from "../modules/Withdraw/withdrawalPage";
import ConfirmedWithdraw from "../modules/Withdraw/Confirmed-withdraw";
import NotFoundPage from "../modules/Page-404";
import AccountPage from "../modules/Account-page";
import AcctDetails from "../modules/Account-page/Acct-details";
import AcctSecurity from "../modules/Account-page/Acct-security";
import { AcctDocs } from "../modules/Account-page/Acct-documents";
import Waitlist from "../layout/waitlist";
import { JoinOption } from "../modules/join-us/joinPage";
import { JoinAsBuyer } from "../modules/join-us/join-as-buyer";
import { SellerLinkAuthenticated } from "../modules/generate-link/link-invitation/authenticated";
import ReviewWithdraw from "../modules/Withdraw/Review-withdraw";
import { Hometable } from "../components/home-table";

const AuthenticatedLayout = lazy(() => import("../layout/authenticated"));

const MainBody = ({ children }) => {
  useEffect(() => {
    document.title = "Vendyz";
  }, []);
  return (
    <main className="h-screen  w-full font-main_font ">
      {children ? children : <Outlet />}
    </main>
  );
};

const MainRoutes = () => {
  const routes = createBrowserRouter([
    {
      element: <MainBody />,
      children: [
        {
          path: "/",
          element: (
            <Suspense
              fallback={
                <div className="h-screen w-full flex items-center justify-center">
                  <HashLoader color="#5271FF" speedMultiplier={0.8} />
                </div>
              }
            >
              <AuthenticatedLayout />
            </Suspense>
          ),
          children: [
            {
              index: true,
              element: <Homepage />,
            },
            {
              path: "/accept-order",
              element: <SellerLinkAuthenticated />,
            },
            {
              path: "/orders",
              element: (
                <Suspense
                  fallback={
                    <div className="h-screen w-full flex items-center justify-center">
                      <HashLoader color="#5271FF" speedMultiplier={0.8} />
                    </div>
                  }
                >
                  <Orders />
                </Suspense>
              ),
              children: [
                {
                  index: true,
                  element: (
                    <Suspense
                      fallback={
                        <div className="h-screen w-full flex items-center justify-center">
                          <HashLoader color="#5271FF" speedMultiplier={0.8} />
                        </div>
                      }
                    >
                      <OrdersTbale />
                    </Suspense>
                  ),
                },
                {
                  path: "disputes",
                  element: <DisputedOrders />,
                },
              ],
            },
            {
              path: "/orders/:orderConfirmation",
              element: (
                <Suspense
                  fallback={
                    <div className="h-screen w-full flex items-center justify-center">
                      <HashLoader color="#5271FF" speedMultiplier={0.8} />
                    </div>
                  }
                >
                  <OrderConfirmation />
                </Suspense>
              ),
            },
            {
              path: "/orders/disputes/:disputesConfirmation",
              element: <DisputesCOnfirmation />,
            },
            {
              path: "/transactions",
              element: <Transactionpage />,
            },
            {
              path: "/transactions/:transactionConfirmation",
              element: <TransactionConfirmation />,
            },
            {
              path: "/paymentLinks",
              element: <Paymentlinks />,
              children: [
                {
                  index: true,
                  element: (
                    <Suspense
                      fallback={
                        <div className="h-screen w-full flex items-center justify-center">
                          <HashLoader color="#5271FF" speedMultiplier={0.8} />
                        </div>
                      }
                    >
                      <AllLinksTable />
                    </Suspense>
                  ),
                },
                {
                  path: "paid",
                  element: <PaidLinksTable />,
                },
                {
                  path: "unpaid",
                  element: <UnPaidLinksTable />,
                },
              ],
            },
            {
              path: "/paymentLinks/:linksConfirmation",
              element: <LinkCOnfirm />,
            },
            {
              path: "/account",
              element: <AccountPage />,
              children: [
                {
                  index: true,
                  element: <AcctDetails />,
                },
                {
                  path: "security",
                  element: <AcctSecurity />,
                },
                {
                  path: "documents",
                  element: <AcctDocs />,
                },
              ],
            },
            {
              path: "/withdraw",
              element: <Withdraw />,
              children: [
                {
                  index: true,
                  element: <WithdrawPage />,
                },
                {
                  path: "review-withdraw",
                  element: <ReviewWithdraw />,
                },
                {
                  path: "confirmed-withdraw",
                  element: <ConfirmedWithdraw />,
                },
              ],
            },
          ],
        },
        {
          path: ":paymentLink",
          element: <BuyerLInkInvitation />,
        },
        {
          path: "/auth",
          element: <UnAuthenticated />,
          children: [
            {
              index: true,
              element: <LoginAuth />,
            },

            {
              path: "forgot-Password",
              element: <ForgottenPassword />,
            },
            {
              path: ":OtpEmail/:req_token",
              element: <OtpCode />,
            },
            {
              path: "resetPassword",
              element: <ResetPassword />,
            },
            // {
            //   path: ":new_reg_config",
            //   element: <MerchantNewConfig />,
            // },
          ],
        },
        {
          path: "/auth/register",
          element: <Register />,
          children: [
            {
              index: true,
              element: <OnBoarding />,
            },
            {
              path: "buyer",
              element: <BuyerReg />,
              children: [
                {
                  index: true,
                  element: <CreateAcctForBuyer />,
                },
                {
                  path: ":buyeremail/:req_token",
                  element: <EmailConfigBuyer />,
                },
                {
                  path: "linkPreviousAccount",
                  element: <BuyerLinkPrevAcct />,
                },
                {
                  path: "contact-information",
                  element: <Buyercontact />,
                },
              ],
            },
            {
              path: "seller",
              element: <SellerReg />,
              children: [
                {
                  index: true,
                  element: <CreateSellerAccout />,
                },
                {
                  path: ":selleremail/:req_token",
                  element: <SellerConfigEmail />,
                },
                {
                  path: "contact-information",
                  element: <Sellercontact />,
                },
              ],
            },
          ],
        },
        {
          path: "/generate-link",
          element: (
            <Suspense
              fallback={
                <div className="h-screen w-full flex items-center justify-center ">
                  <HashLoader color="#5271FF" speedMultiplier={0.8} />
                </div>
              }
            >
              <Generatelink />
            </Suspense>
          ),
          children: [
            {
              index: true,
              element: (
                <Suspense
                  fallback={
                    <div className="h-screen w-full flex items-center justify-center">
                      <HashLoader color="#5271FF" speedMultiplier={0.8} />
                    </div>
                  }
                >
                  <PaymetLInkGeneration />
                </Suspense>
              ),
            },
            {
              path: "create-link",
              element: <LinkConfirmation />,
            },

            {
              path: "payment",
              element: <Payment />,
              children: [
                {
                  index: true,
                  element: <PaymentOPtion />,
                },
                {
                  path: "bank-transfer",
                  element: <BankTransfer />,
                },
                {
                  path: "card-payment",
                  element: <CardPayment />,
                },
                {
                  path: "payment-review",
                  element: <ReviewPaymnet />,
                },
                {
                  path: "payment-confirmed",
                  element: <ConfirmedPayment />,
                },
              ],
            },
          ],
        },
        {
          path: "waitlist",
          element: <Waitlist />,
          children: [
            {
              index: true,
              element: <JoinOption />,
            },
            {
              path: ":type",
              element: <JoinAsBuyer />,
            },
          ],
        },
        {
          path: "*",
          element: <NotFoundPage />,
        },
      ],
    },
  ]);

  return <RouterProvider router={routes} />;
};

export default MainRoutes;
