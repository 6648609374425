import React, { useState } from "react";
import ContainerWithGradient from "../../../components/container-with-gradient";
import notice from "../../../assets/imgs/info-circle.svg";
import vedorLogo from "../../../assets/imgs/mooiIcon.svg";
import productImg from "../../../assets/imgs/Ellipse 2858.svg";
import lock from "../../../assets/imgs/lock.svg";
import copy from "../../../assets/imgs/copy.svg";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";

export const BankTransfer = () => {
  const [accountNumber, setAccountNumber] = useState("5560014293");
  const [accountNumberCopied, setAccountNumberCopied] = useState(false);
  const [showCopied, setShowCopied] = React.useState(false);
  const [itemID, setItemId] = React.useState("VD5477956P");
  const [btnDisabled, setBtnDisabled] = React.useState(false);

  const bankName = "uba";
  const accountName = "Escrow Account";

  function copyToCipboard(value) {
    const result = navigator.clipboard.writeText(value);

    result.then(() => {
      setShowCopied(true);
      setTimeout(() => {
        setShowCopied(false);
      }, [1500]);
    });
  }
  function copyAccToCipboard(value) {
    const result = navigator.clipboard.writeText(value);

    result.then(() => {
      setAccountNumberCopied(true);
      setTimeout(() => {
        setAccountNumberCopied(false);
      }, [1500]);
    });
  }

  const navigate = useNavigate();

  return (
    <section>
      <div className="flex justify-center flex-col items-center gap-3  w-full  pt-10">
        <ContainerWithGradient
          height={"h-[44px]"}
          width={" w-[90%] 2xl:w-[79%]"}
        >
          <div className="flex justify-center items-center">
            <div className="flex gap-2  items-center ">
              <div>
                <img src={notice} alt="" />
              </div>
              <div>
                <h1 className="text-[#232323] 2xl:text-sm text-[12px]  ">
                  {" "}
                  Please complete your payment before
                  <span className="font-bold">
                    {" "}
                    March 16, 12:18 PM GMT.
                  </span>{" "}
                  after which the link expires. Contact your vendor for any
                  additional information
                </h1>
              </div>
            </div>
          </div>
        </ContainerWithGradient>

        <div className="flex items-center gap-3 pt-4">
          <div>
            <img src={lock} alt="" />
          </div>
          <div>
            <p className="text-[12px] text-[#9B9697] ">
              Secured by{" "}
              <span className="text-[#5C5959] font-[500]">Vendyz</span>
            </p>
          </div>
        </div>

        <div className="flex justify-center flex-col items-center gap-4 2xl:gap-8">
          <div className="2xl:pt-4">
            <img src={vedorLogo} alt="" className="w-28" />
          </div>
          <div className="text-center">
            <p className="text-[#232323] font-[700] text-[16px]">
              Mooi Gadgets asks for
            </p>
            <p className="text-[#232323] font-[700] text-[24px]">₦200,000.00</p>
          </div>
        </div>

        <div className="h-[156px] p-3 w-[592px] bg-[#FAFAFA] rounded-[25px] flex justify-center">
          <div className="flex items-center gap-3   justify-between 2xl:px-10 px-5">
            <div className="flex items-center w-[60%] justify-between gap-3">
              <div className="rounded">
                <img src={productImg} alt="" className="w-56" />
              </div>

              <div>
                <p className="text-[500] text-[12px] text-[#5C5959] font-[500]">
                  iPhone 15 Pro Max - 6.7Inch - 526 GB ROM, 6GB RAM iOS 18-
                  Midnight Titanium design Ceramic Shield front Textured matte
                  glass back
                </p>
              </div>
            </div>

            <div className="flex justify-end border-l-2 w-[30%]">
              <div className="flex flex-col items-center justify-center w-full gap-3">
                <div>
                  <p className="text-[12px] font-[700] ">₦200,000.00</p>
                </div>
                <div>
                  <p className="text-[12px] font-[500] text-[#5C5959] pb-2">
                    {itemID}
                  </p>
                  <div
                    onClick={() => copyToCipboard(itemID)}
                    className="cursor-pointer flex items-center relative"
                  >
                    <div>
                      <img src={copy} alt="" />
                    </div>
                    <div>
                      <p className="text-[#5271FF] text-[12px] pl-1">Copy</p>
                    </div>
                    {showCopied && (
                      <div className="text-sm text-bold absolute -bottom-8 tracking-wider text-white w-[80px]">
                        <p className="text-white  bg-indigo-400 rounded-lg py-1 px-3  w-full">
                          Copied!
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[592px] pt-3">
          <div className="text-sm text-[#5C5959] font-400 pb-1">
            Account number
          </div>
          <div className=" w-full  flex items-center justify-between border-[1px] border-[#F0F0F0] rounded-[20px]  gap-2  outline-none shadow-[#F0F0F0] h-[50px] px-2 shadow-sm">
            <div>
              <p className="text-[16px] font-[500] text-[#232323]">
                {accountNumber}
              </p>
            </div>

            <div
              onClick={() => copyAccToCipboard(accountNumber)}
              className="cursor-pointer flex items-center relative"
            >
              <div>
                <img src={copy} alt="" />
              </div>
              <div>
                <p className="text-[#5271FF] text-[12px] pl-1">Copy</p>
              </div>
              {accountNumberCopied && (
                <div className="text-sm text-bold absolute -bottom-8 tracking-wider text-white w-[80px]">
                  <p className="text-white  bg-indigo-400 rounded-lg py-1 px-3  w-full">
                    Copied!
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center gap-3 w-full  pt-10">
            <div className="w-1/2">
              <div className="text-sm text-[#5C5959] font-400 pb-1">
                Bank name
              </div>
              <div className=" w-full  flex items-center justify-between border-[1px] border-[#F0F0F0] rounded-[20px]  gap-2  outline-none shadow-[#F0F0F0] h-[50px] px-2 shadow-sm">
                <div>
                  <p className="text-[16px] font-[500] text-[#232323]">
                    {bankName}
                  </p>
                </div>
              </div>
            </div>

            <div className="w-1/2">
              <div className="text-sm text-[#5C5959] font-400 pb-1">
                Account name
              </div>
              <div className=" w-full  flex items-center justify-between border-[1px] border-[#F0F0F0] rounded-[20px]  gap-2  outline-none shadow-[#F0F0F0] h-[50px] px-2 shadow-sm">
                <div>
                  <p className="text-[16px] font-[500] text-[#232323]">
                    {accountName}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[592px] pt-10 pb-5">
          <Button
            onClick={() => {
              navigate("/paymentLinks");
            }}
            variant="authBlue"
            size="fullwidth"
            touches={"h-[44px]"}
            isBtnDisabled={btnDisabled}
          >
            Done
          </Button>
        </div>
      </div>
    </section>
  );
};
