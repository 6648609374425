import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { getTokens } from "../service/useService";

const checkValidity = (ISODateTime) => {
  if (ISODateTime.trim() !== "") {
    const dateToCheck = DateTime.fromISO(ISODateTime);
    if (dateToCheck.isValid) {
      return dateToCheck.diffNow().milliseconds > 0;
    }
  }
  return false;
};

const useAuthCheck = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const tokens = getTokens();

    if (
      !tokens ||
      !checkValidity(tokens.expiresAT) ||
      !checkValidity(tokens.refreshExpiresAt)
    ) {
      navigate("/auth");
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [navigate]);

  return isLoading;
};

export default useAuthCheck;
