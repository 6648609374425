import React from "react";
import Textfield from "../../../components/Textfield";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/imgs/Logo.svg";
import Button from "../../../components/Button";
import { useEffect } from "react";
import { useAppContext } from "../../../context";
import createAxiosInstance from "../../../service";
import { ClockLoader } from "react-spinners";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import useService, {
  getTokens,
  updateToken,
} from "../../../service/useService";
import { getFromStore, saveInStore } from "../../../utils/helpers";
import axios from "axios";
// import { Response } from "aws-sdk";

const LoginAuth = () => {
  const navigate = useNavigate();
  const {
    setUserType,
    setUserINFO,
    setVendorBussness,
    vendorBussness,
    setId,
    id,
  } = useAppContext();

  const basUrl = process.env.REACT_APP_BASE_URL;
  const service = createAxiosInstance(basUrl);
  const Service = useService(basUrl);

  const vendorBaseUrl = process.env.REACT_APP_VENDOR_BASE_URL;
  const vendorUseService = useService(vendorBaseUrl);
  const vendorPostService = createAxiosInstance(vendorBaseUrl);

  const [email, updateEmail] = React.useState("");
  const [password, updatePassword] = React.useState("");
  const [disabledbtn, setDisabledBtn] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [inputDisabled, setInputDisabled] = React.useState(false);
  const [longitude, setLongitude] = React.useState("");
  const [latitude, setLatitude] = React.useState("");

  useEffect(() => {
    function checkField() {
      if (email !== "" && password !== "") {
        setDisabledBtn(false);
      } else {
        setDisabledBtn(true);
      }
    }
    checkField();
  }, [email, password]);

  const validate = async () => {
    try {
      setDisabledBtn(true);
      setLoading(true);
      setInputDisabled(true);
      const response = await service.post("/auth/login", { email, password });
      if (response.status === 201) {
        const ref_token = response.data.data.refresh_token;
        const accesstoken = response.data.data.access_token;
        const decoded = jwtDecode(ref_token);

        const expirationTime = response.data.data.expiresAt;

        const dbresponse = {
          access_token: accesstoken,
          refreshtoken: ref_token,
          expiresAt: expirationTime,
          expiresIn: expirationTime,
        };
        updateToken(dbresponse);
        const userID = decoded.user_id;
        const userType = decoded.user_type;
        // console.log(userID);

        try {
          const res = await Service.get(`/user/${userID}`);
          if (res.status === 200) {
            const userData = res.data.data;
            setUserINFO(userData);
            // console.log(res);
            saveInStore("vendyz_user", userData);

            try {
              const owernerDocs = await Service.get(
                `/owner/docs/user/${userID}`
              );
              if (owernerDocs.status === 200) {
                // console.log(owernerDocs);

                const MainId = owernerDocs.data.data;
                saveInStore("main_id", MainId);

                try {
                  const response = await Service.get(
                    "/config/security/questions"
                  );
                  if (response.status === 200) {
                    // console.log(response);
                    localStorage.setItem(
                      "questions",
                      JSON.stringify(Array.from(response.data.data))
                    );

                    try {
                      const getIp = await axios.get(
                        "https://api.ipify.org?format=json"
                      );
                      if (getIp.status === 200) {
                        const ip = getIp.data.ip;

                        saveInStore("ipaddress", getIp);

                        if (navigator.geolocation) {
                          navigator.geolocation.getCurrentPosition(
                            async (position) => {
                              const latitude = position.coords.latitude;
                              const longitude = position.coords.longitude;
                              setLongitude(longitude);
                              setLatitude(latitude);

                              try {
                                const body = {
                                  token: accesstoken,
                                  ip: String(ip),
                                  long: String(longitude),
                                  lat: String(latitude),
                                };

                                const response = await service.post(
                                  "/auth/validate/token",
                                  body
                                );
                                if (response.status === 201) {
                                  navigate("/");
                                  toast.success("Log in Success");
                                  setUserType(userType);
                                }
                              } catch (error) {
                                console.log(error);
                              }
                            },
                            (err) => {
                              setLoading(false);
                              toast.error("Something went wrong");
                              setDisabledBtn(false);
                            },
                            { maximumAge: 0 }
                          );
                        } else {
                          console.log(
                            "Geolocation is not supported by this browser."
                          );
                          navigate("/auth");
                          setLoading(false);
                          toast.error("Something went wrong");
                          setDisabledBtn(false);
                        }
                      }
                    } catch (error) {
                      // console.log(error);

                      navigate("/auth");
                      setLoading(false);
                      if (error.message === "timeout exceeded") {
                        toast.error("Network timeout");
                        // setPageLoad(true);
                      } else if (error.message === "Network Error") {
                        toast.error("Network Error");
                        // setPageLoad(true);
                      } else {
                        toast.error("something went wrong");
                      }
                      setDisabledBtn(false);
                    }
                  }
                } catch (error) {
                  console.log(error);
                }
              }
            } catch (error) {
              console.log(error);
            }
          }
        } catch (error) {
          setDisabledBtn(false);
          setLoading(false);
          setInputDisabled(false);
        }
      }
    } catch (error) {
      setDisabledBtn(false);
      setLoading(false);

      setInputDisabled(false);

      if (error.message === "Network Error") {
        toast.error("Network error");
      } else if (error.message === "Invalid Email or Password") {
        toast.error("Incorrect email or password");
      } else if (error.message === "timeout exceeded") {
        toast.error(" connection Timeout exceeded");
      } else if (error.response.data.message === "Unrecognized User Details") {
        try {
          setDisabledBtn(true);
          setLoading(true);
          setInputDisabled(true);
          const response = await vendorPostService.post(
            "/merchant/auth/login",
            {
              email,
              password,
            }
          );
          if (response.status === 201) {
            const access_token = response.data.data.access_token;
            const req_token = response.data.data.refresh_token;
            const exp = response.data.data.expiresIn;
            const decoded = jwtDecode(req_token);
            const dbResponse = {
              access_token: access_token,
              refreshtoken: req_token,
              expiresAt: exp,
              expiresIn: exp,
            };
            updateToken(dbResponse);
            const userId = decoded.user_id;
            const merchantID = decoded.merchant_business_id;

            try {
              const response = await vendorUseService.get(
                `/merchant/users/${userId}`
              );
              if (response.status === 200) {
                const merchantB = response.data.data.merchant_business;
                setVendorBussness(decoded);

                saveInStore("merchant-B", merchantB);
                saveInStore("merchant_id", merchantID);
                saveInStore("vendyz_user", response.data.data);
                try {
                  const owernerDocs = await Service.get(
                    `/owner/docs/user/${userId}`
                  );

                  if (owernerDocs.status === 200) {
                    const MainId = owernerDocs.data.data;
                    saveInStore("main_id", MainId);

                    setUserType("seller");
                    try {
                      const response = await vendorUseService.get(
                        `/config/security/questions`
                      );

                      localStorage.setItem(
                        "questions",
                        JSON.stringify(Array.from(response.data.data))
                      );
                      // navigate("/");
                      try {
                        const getIp = await axios.get(
                          "https://api.ipify.org?format=json"
                        );
                        if (getIp.status === 200) {
                          const ip = getIp.data.ip;

                          if (navigator.geolocation) {
                            navigator.geolocation.getCurrentPosition(
                              async (position) => {
                                const latitude = position.coords.latitude;
                                const longitude = position.coords.longitude;
                                setLongitude(longitude);
                                setLatitude(latitude);

                                try {
                                  const body = {
                                    token: access_token,
                                    ip: String(ip),
                                    long: String(longitude),
                                    lat: String(latitude),
                                  };

                                  const response = await vendorPostService.post(
                                    "/merchant/auth/validate/token",
                                    body
                                  );

                                  if (response.status === 201) {
                                    navigate("/");
                                    toast.success("Log in Success");
                                    saveInStore(
                                      "vendyz_user",
                                      response.data.data
                                    );
                                  }
                                } catch (error) {
                                  if (error.message === "Network Error") {
                                    toast.error("Network Error");
                                    setDisabledBtn(false);
                                    setLoading(false);
                                    setInputDisabled(false);
                                  } else {
                                    setDisabledBtn(false);
                                    setLoading(false);
                                    setInputDisabled(false);
                                  }
                                }
                              },
                              (err) => {
                                setLoading(false);
                                toast.error("Something went wrong");
                                setDisabledBtn(false);
                              },
                              { maximumAge: 0 }
                            );
                          } else {
                            navigate("/auth");
                            setLoading(false);
                            toast.error("Something went wrong");
                            setDisabledBtn(false);
                          }
                        }
                      } catch (error) {
                        navigate("/auth");
                        setLoading(false);
                        toast.error("Something went wrong");
                        setDisabledBtn(false);
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  }
                } catch (error) {
                  if (error.message === "Network Error") {
                    toast.error("Network Error");
                    setDisabledBtn(false);
                    setLoading(false);
                    setInputDisabled(false);
                  } else {
                    setDisabledBtn(false);
                    setLoading(false);
                    setInputDisabled(false);
                  }
                }
              }
            } catch (error) {
              setDisabledBtn(false);
              setLoading(false);
              setInputDisabled(false);
            }
          }
        } catch (error) {
          setDisabledBtn(false);
          setLoading(false);
          setInputDisabled(false);
          if (error.message === "Network Error") {
            toast.error("Network Error");
          } else if (
            error.response.data.message === "User Not Found" ||
            error.response.data.message === "Invalid Credentials"
          ) {
            toast.error("Invalid username or password");
          }
        }
      } else {
        const errormsg = error.response.data.message;
        toast.error(errormsg);
        setDisabledBtn(false);
        setLoading(false);
        setInputDisabled(false);
      }
    }
  };

  return (
    <section className="w-full flex justify-center  h-full">
      <div className="  w-full">
        <div className="flex justify-center">
          <img src={logo} alt="" className="" />
        </div>
        <div className="xl:pt-5 pt-2">
          <div className="flex justify-center">
            <h1 className=" text-2xl font-[700] text-[#232323]">Login</h1>
          </div>

          <div className="flex justify-center pt-2">
            <p className="text-[#9B9697] text-[16px] text-[400]">
              Please provide your email and password.
            </p>
          </div>
        </div>

        <div className="flex justify-center w-[70%]  mx-auto">
          <form className="flex flex-col justify-center   gap-2 xl:gap-4 w-full pt-6 ">
            <Textfield
              labal={"Email"}
              type={"text"}
              value={email}
              onChange={(e) => {
                updateEmail(e.target.value);
              }}
              placeholder={"emmanuel@gmail.com"}
              disabled={inputDisabled}
              labelStyle={"xl:text-[14px] text-sm text-[#5C5959] font-[400]"}
              // className={"xl:h-[50px] h-[30px]"}
            />

            <Textfield
              labal={"Password"}
              type={"password"}
              onChange={(e) => {
                updatePassword(e.target.value);
              }}
              value={password}
              placeholder={"*********"}
              disabled={inputDisabled}
              labelStyle={"xl:text-[14px] text-sm text-[#5C5959] font-[400]"}
            />

            <div className="2xl:pt-5 pt-1 flex justify-end w-full ">
              <span
                onClick={() => navigate("/auth/forgot-Password")}
                className="text-[#5C5959] text-[400] text-[14px] text-right cursor-pointer "
              >
                Forgot password?
              </span>
            </div>

            <div className="2xl:pt-5 pt-2  w-full">
              <Button
                isBtnDisabled={disabledbtn}
                variant="authBlue"
                touches={"h-[44px]"}
                onClick={(e) => {
                  validate();
                  e.preventDefault();
                }}
              >
                {" "}
                {loading ? (
                  <ClockLoader
                    size={"30px"}
                    speedMultiplier={0.8}
                    color="#fafafa"
                  />
                ) : (
                  "Continue"
                )}
              </Button>

              <div className="pt-2  w-full justify-center 2xl:pt-8">
                <div className="  w-full">
                  <p className="text-[#5C5959] text-[400] text-[16px] text-center">
                    New user?
                    <span
                      onClick={() => navigate("/auth/register")}
                      className="text-[#5271FF] pl-5 cursor-pointer"
                    >
                      Create an account
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default LoginAuth;
