import React from "react";
import PropTypes from "prop-types";

const SelectInput = ({ label, options = [], onChange, labelStyle, value }) => {
  return (
    <div className="w-full">
      <label
        htmlFor="select"
        className={`text-[#5C5959] font-[400] tracking-wider ${labelStyle}`}
      >
        {label}
      </label>
      <select
        onChange={onChange}
        id="select"
        className="block w-full h-[44px] px-6 py-2 outline-none text-[#9B9697] bg-white border-[#F0F0F0] border-[1px] rounded-[20px]"
      >
        <option value="">{value ? value : "Select option"}</option>
        {options.map((option, i) => (
          <option key={i} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

SelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  labelStyle: PropTypes.string,
};

SelectInput.defaultProps = {
  labelStyle: "",
  options: [], // Default options to an empty array
};

export default SelectInput;
