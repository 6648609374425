import React from "react";
import copy from "../../assets/imgs/copy.svg";
import {
  convertTime,
  formatCurrency,
  getTimeFromISOString,
} from "../../utils/helpers";
import { TruncateDate } from "../truncatedDate";
import TruncatedText from "../truncatedText";

export const GetLinkDataTable = ({ tableHeadData, data }) => {
  const isoString = data.createdAt;
  const time = getTimeFromISOString(isoString);

  const bgFilter = (value) => {
    if (value === "completed" || value === "active" || value === "credit") {
      return "capitalize font-medium h-[28px] w-[107px] text-sm  pt-1 pb-1 rounded-[100px] bg-[#EFFFF6] text-[#449E6A] border-[1px] border-[#83F3B2] ";
    } else if (value === "pending") {
      return `capitalize font-medium h-[28px] w-[107px] text-sm  pt-1 pb-1 rounded-[100px] 
        text-[#232323] border-[1px] border-[#F0F0F0] bg-zinc-200`;
    } else if (
      value === "cancelled" ||
      value === "expired" ||
      value === "debit"
    ) {
      return "capitalize font-medium h-[28px] w-[107px] text-sm  pt-1 pb-1 rounded-[100px] bg-[#FFE8E8] text-[#EF5959] border-[1px] border-[#FFBBBB] ";
    }
  };

  return (
    <section>
      <div className="pt-20 px-4">
        <table className="w-full  ">
          <thead className="text-left h-[44px] text-[#9B9697] text-[14px] font-[400] border-[#F0F0F0]">
            <tr>
              {Object.keys(tableHeadData[0]).map(
                (key) =>
                  key !== "id" && (
                    <th
                      key={key}
                      className="capitalize pl-3 tracking-wider font-light"
                    >
                      {key}
                    </th>
                  )
              )}
            </tr>
          </thead>
          <tbody>
            <tr
              key={data.id}
              className={`h-[70px] border-[#F0F0F0]  cursor-pointer `}
            >
              <td>
                <div>
                  <p className="text-[#232323] font-[500] text-lg bg-[#F4F4F4] p-3 rounded-xl w-fit ">
                    <TruncatedText maxLength={35} text={data.payment_link} />
                  </p>

                  <div className="flex gap-2 items-center pt-3">
                    <img src={copy} alt="" />
                    <p className="text-[#5271FF] text-[14px] font-[400]">
                      Copy
                    </p>
                  </div>
                </div>
              </td>

              <td className="ml-6">
                <div>
                  <p className="text-[#232323] font-[500] text-lg  ">
                    {formatCurrency(data.order_amount)}
                  </p>
                </div>
              </td>

              <td>
                {<TruncateDate data={data.updatedAt} intTl={true} />}

                <p className="text-[#232323] text-[14px] font-[400] pt-2 ">
                  {time.time12}
                </p>
              </td>

              <td>
                {
                  <TruncateDate
                    data={data.expiry_date}
                    intTl={true}
                    touches={"text-[#EF5959] "}
                  />
                }

                <p className="text-[#EF5959] font-[400] text-[14px]  pt-2">
                  {convertTime(data.expiry_time)}
                </p>
              </td>

              <td className=" pl-2">
                <p
                  className={`${bgFilter(
                    "active"
                  )} text-center cursor-pointer `}
                >
                  active
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};
