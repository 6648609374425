import React from "react";
import securityIcon from "../../assets/imgs/Badge.svg";
import notice from "../../assets/imgs/info-circle.svg";
import container from "../../assets/imgs/Container.svg";
import dumieBtns from "../../assets/imgs/Actions.svg";
import dimmieInptField from "../../assets/imgs/Container (1).svg";
import companyIcon from "../../assets/imgs/mooiIcon.svg";
import gradientBG from "../../assets/imgs/BG (1).svg";

export const PaymentPreview = () => {
  return (
    <div className="flex justify-center items-center flex-col overflow-auto">
      <div className="w-[90%]  h-fit shadow-sm rounded-[10.24px] bg-white flex flex-col items-center pb-4 ">
        <div className="pt-4 flex justify-center items-center">
          <div className="relative px-4">
            <div className="">
              <img
                src={gradientBG}
                alt=""
                className="rounded-[100px] w-[100%] "
              />
            </div>
            <div className="flex gap-1 items-center justify-center absolute top-[2px] 2xl:top-[3px] left-5">
              <div>
                <img src={notice} alt="" className="w-3" />
              </div>
              <div>
                <h1 className="text-[#232323] text-[6px] 2xl:text-[7px]">
                  {" "}
                  Please complete your payment before
                  <span className="font-bold">
                    {" "}
                    March 16, 12:18 PM GMT.
                  </span>{" "}
                  after which the link expires. Contact your vendor for any
                  additional information.
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-4">
          <img src={securityIcon} alt="" className="w-24" />
        </div>
        <div className="pt-5">
          <img src={companyIcon} alt="" className="w-10" />
        </div>
        <div>
          <p className="text-[#232323] font-semibold text-[6px] pt-1">
            Mooi Gadgets asks for
          </p>
          <p className="text-[#232323] font-semibold text-[9px] pt-1">
            ₦200,000.00
          </p>
        </div>
        <div className="py-2">
          <div className="w-[104.8px] h-[9px] rounded-[8px] bg-[#EEEEEE]"></div>
        </div>
        <div className="pt-3">
          <img src={container} alt="" />
        </div>
        <div className="pt-3">
          <img src={dimmieInptField} alt="" />
        </div>
        <div>
          <img src={dumieBtns} alt="" />
        </div>
      </div>
    </div>
  );
};
