import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const OrderNavBar = ({ notificationValue, notiification = false }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className="pt-14 ">
      <div className="bg-[#F6F6F6] rounded-[100px] w-[290px] h-[39px] flex justify-between  items-center ">
        <div
          onClick={() => navigate("/orders")}
          className={` ${
            pathname === "/orders"
              ? "bg-[#FFFFFF] font-semibold shadow-sm rounded-[100px] text-[#232323]"
              : ""
          } hover:bg-[#FFFFFF] hover:font-semibold hover:shadow-sm hover:rounded-[100px] h-[35px] w-[50%] text-center pt-1 hover:text-[#232323] text-[#9B9697] duration-200 cursor-pointer `}
        >
          <p className={`${pathname === "/orders" ? " text-[#232323]" : ""}`}>
            All orders
          </p>
        </div>

        <div
          onClick={() => {
            navigate("/orders/disputes");
          }}
          className={` ${
            pathname === "/orders/disputes"
              ? "bg-[#FFFFFF] shadow-sm rounded-[100px] text-[#232323] font-semibold flex items-center"
              : ""
          }flex items-center hover:bg-[#FFFFFF] hover:shadow-sm hover:rounded-[100px] h-[35px] w-[50%] text-center justify-center gap-1 cursor-pointer hover:text-[#232323] text-[#9B9697] hover:font-semibold`}
        >
          <p
            className={`${
              pathname === "/orders/disputes" ? " text-[#232323]" : ""
            }`}
          >
            Disputes
          </p>
          {notiification && (
            <div className="h-5 w-5 rounded-full  bg-red-500  z-10 text-white text-center text-sm">
              {notificationValue}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderNavBar;
