import React, { useEffect } from "react";
import lock from "../../../assets/imgs/lock.svg";
import profilePicture from "../../../assets/profilePictures/Avatar.svg";
import copy from "../../../assets/imgs/copy.svg";
import Button from "../../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import createAxiosInstance from "../../../service";
import { HashLoader } from "react-spinners";
import { saveInStore } from "../../../utils/helpers";
import { getTokens } from "../../../service/useService";
import logo from "../../../assets/imgs/Logo.svg";

export const BuyerLInkInvitation = () => {
  const [showCopied, setShowCopied] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [orderDetail, setOrderDetail] = React.useState(null);

  const navigate = useNavigate();
  const params = useParams();

  const basUrl = process.env.REACT_APP_ODER_BASE_URL;
  const Service = createAxiosInstance(basUrl);

  const tokens = getTokens();

  // :U2FsdGVkX1%2FKZWI%2Bzd7g96RtTCnHhiIgvxTFI%2F1Up28aiQqZhpApHtrYcbTh%2By2I

  useEffect(() => {
    setIsLoading(true);
    if (tokens) {
      saveInStore("payment_link", window.location.href, "local");
      setTimeout(() => {
        navigate("/accept-order");
      }, 300);
    }
  }, [navigate, tokens]);

  useEffect(() => {
    const getPaymenetDetails = async () => {
      setIsLoading(true);
      try {
        const body = {
          payment_link: `https://www.app.vendyz.com/${params.paymentLink}`,
        };
        const response = await Service.post(
          "/buyer/orders/validate/link",
          body
        );

        if (response.status === 201) {
          const detail = response.data.data.order_details;

          setOrderDetail(detail);
          setIsLoading(false);
        }
      } catch (error) {
        const errmsg = error.response.data.message;
        if (errmsg === "Invalid Payment Link") {
          navigate("*");
        }

        setIsLoading(false);
      }
    };

    getPaymenetDetails();
  }, []);

  console.log(orderDetail);

  function copyToCipboard(value) {
    const result = navigator.clipboard.writeText(value);

    result.then(() => {
      setShowCopied(true);
      setTimeout(() => {
        setShowCopied(false);
      }, [1500]);
    });
  }

  console.log(orderDetail);

  if (isLoading) {
    return (
      <div className="h-screen w-full flex items-center justify-center">
        <HashLoader color="#5271FF" speedMultiplier={0.8} />
      </div>
    );
  }

  return (
    <div className="bg-neutral-50 h-screen ">
      <div className="h-full">
        <div className="h-[10%] xl:h-[100px]  w-full bg-neutral-50 flex justify-start pl-7 items-center cursor-pointer">
          <div>
            <img src={logo} alt="" />
          </div>
        </div>
        <div className="flex bg-neutral-50 h-[90%] xl:h-[calc(100%-100px)]">
          <div className=" h-full w-[10%]"></div>
          <div className=" px-14 pt-6 2xl:pt-12 bg-white w-full rounded-tl-[50px] ">
            <div className="flex justify-center flex-col items-center gap-3 w-full h-full">
              <div className="flex items-center gap-3">
                <div>
                  <img src={lock} alt="" />
                </div>
                <div>
                  <p className="text-[12px] text-[#9B9697] ">
                    Secured by{" "}
                    <span className="text-[#5C5959] font-[500]">Vendyz</span>
                  </p>
                </div>
              </div>

              <div className="flex justify-center flex-col items-center gap-4 2xl:gap-8">
                <div className="2xl:pt-4">
                  <img src={profilePicture} alt="" className="w-28" />
                </div>
                <div className="text-center">
                  <p className="text-[#232323] font-[700] text-[16px]">
                    {/* {orderDetail.order_payment_history[0]} wants to pay */}
                  </p>
                  <p className="text-[#232323] font-[700] text-[24px]">
                    ₦{orderDetail.order_payment_history[0].amount_initiated}
                  </p>
                </div>
              </div>

              <div className="h-[156px] p-3 w-[45%] bg-[#FAFAFA] rounded-[25px] flex justify-center">
                <div className="flex items-center gap-3   justify-between 2xl:px-10 px-5">
                  <div className="flex items-center w-[60%] justify-between gap-3">
                    <div className="rounded">
                      <img
                        src={orderDetail.item_image_url}
                        alt=""
                        className="w-56"
                      />
                    </div>

                    <div>
                      <p className="text-[500] text-[12px] text-[#5C5959] font-[500]">
                        {orderDetail.item_name}
                      </p>
                    </div>
                  </div>

                  <div className="flex justify-end border-l-2 w-[30%]">
                    <div className="flex flex-col items-center justify-center w-full gap-3">
                      <div>
                        <p className="text-[12px] font-[700] ">
                          ₦
                          {
                            orderDetail.order_payment_history[0]
                              .amount_initiated
                          }
                        </p>
                      </div>
                      <div>
                        <p className="text-[12px] font-[500] text-[#5C5959] pb-2">
                          {orderDetail.order_ref}
                        </p>
                        <div
                          onClick={() => copyToCipboard(orderDetail.order_ref)}
                          className="cursor-pointer flex items-center relative"
                        >
                          <div>
                            <img src={copy} alt="" />
                          </div>
                          <div>
                            <p className="text-[#5271FF] text-[12px] pl-1">
                              Copy
                            </p>
                          </div>
                          {showCopied && (
                            <div className="text-sm text-bold absolute -bottom-8 tracking-wider text-white w-[80px]">
                              <p className="text-white  bg-indigo-400 rounded-lg py-1 px-3  w-full">
                                Copied!
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex gap-4 justify-center w-[592px] pt-10">
                <div className="w-1/2">
                  <Button
                    onClick={() => {
                      navigate("/generate-link");
                    }}
                    size="fullwidth"
                    variant="white"
                    touches={"h-[44px]"}
                  >
                    Back
                  </Button>
                </div>
                <div className="w-1/2">
                  <Button
                    onClick={() => {
                      saveInStore(
                        "payment_link",
                        window.location.href,
                        "local"
                      );
                      navigate("/auth");
                    }}
                    variant="authBlue"
                    size="fullwidth"
                    touches={"h-[44px]"}
                  >
                    {orderDetail.order_type === "VENDOR_ORDER"
                      ? "Login to make payment"
                      : "Login to accept payment"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
