import React, { useEffect, useState } from "react";
import { TablE } from "../ReuableTable";
import TableFilter from "../Table-filter";
// import { data } from "../../constants/data";
import useService from "../../service/useService";
import { toast } from "react-toastify";
import { getFromStore } from "../../utils/helpers";
import { PuffLoader } from "react-spinners";
import { useAppContext } from "../../context";
import TableTop from "../TableTop";
import NotFound from "../../assets/imgs/notFoundImg.jpg";

const tableHeadData = [
  {
    id: 1,
    name: "Name",
    amount: "Amount",
    OrderID: "OrderID",
    Date: "Date",
    status: "Status",
  },
];

const OrdersTable = () => {
  const { setAlOrderData } = useAppContext();
  const [originalData, setOriginalData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [pageLoad, setPageLoad] = useState(true);
  const [hasOrders, setHasOrders] = useState(false);

  const details = getFromStore("vendyz_user");
  const userID = details.id;
  const userType = details.user_type;
  const basUrl = process.env.REACT_APP_ODER_BASE_URL;
  const Service = useService(basUrl);
  const merchant_id = getFromStore("merchant_id");

  useEffect(() => {
    const getDetails = async () => {
      try {
        const response =
          userType === "buyer"
            ? await Service.get(`/buyer/orders/user/${userID}`)
            : await Service.get(`/vendor/orders/vendor/${merchant_id}`);
        if (response.status === 200) {
          const data = response.data.data.data;
          if (data.length === 0) {
            setHasOrders(true);
          } else {
            setHasOrders(false);
          }
          setOrderData(data);
          setOriginalData(data);
          setAlOrderData(data);
          setPageLoad(false);
        }
      } catch (error) {
        if (error.message === "timeout exceeded") {
          toast.error("Timeout exceeded");
        } else if (error.message === "Network Error") {
          toast.error("Network error");
        }
      }
    };
    // getdetails
    getDetails();
  }, []);

  const filterTable = (e) => {
    const input = e.target.value.toLowerCase();
    if (input.length > 0) {
      const filteredData = originalData.filter((item) =>
        item.item_description.toLowerCase().includes(input)
      );
      setOrderData(filteredData);
    } else {
      setOrderData(originalData);
    }
  };

  return pageLoad ? (
    <div className="h-72 w-full flex justify-center pt-44">
      <PuffLoader color="#5271FF" speedMultiplier={0.8} />
    </div>
  ) : (
    <div className="pt-10">
      <div className="w-full border-[1px] rounded-[20px]">
        <div className="px-3">
          <div className="flex flex-col gap-3">
            <div>
              <TableFilter title="All orders" onChange={filterTable} />
            </div>
            {!hasOrders ? (
              <div>
                <TablE
                  tHeaders={tableHeadData}
                  tRows={orderData}
                  variant="orders"
                />
              </div>
            ) : (
              <div className="rounded-[25px] py-[42px] px-[18px] border-[1px] h-[446px] ">
                <div className="flex justify-center items-center h-full">
                  <div className="flex flex-col items-center justify-center">
                    <div>
                      <img
                        src={NotFound}
                        alt="/"
                        className="w-64 h-64 rounded-s-lg"
                      />
                    </div>

                    <p className="text-[14px] font-[400] text-[#9B9697] w-[60%] text-center">
                      It looks like you haven’t made any transactions yet. Start
                      making transactions to see your history here.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersTable;
