import React from "react";

export const SellerRemarkOutput = ({
  vendorRemark,
  vendorRecieved,
  vendorResolutionDetail,
  vendorFiles,
}) => {
  return (
    <tbody>
      <tr className="text-[#5C5959] font-[400] pl-3 tracking-wider">
        <td className="pl-3 tracking-wider capitalize text-sm text-[#232323]">
          {vendorRemark}
        </td>
        <td className="pl-3 tracking-wider capitalize text-sm text-[#232323]">
          {vendorRecieved}
        </td>
        <td className="pl-3 tracking-wider capitalize text-sm text-[#232323]">
          {vendorResolutionDetail}
        </td>
        <td className="flex gap-3 ">
          <div className="mr-2  border-2 border-[#F0F0F0] rounded-[20px] ">
            <div className=" border-2 border-[#F0F0F0]  rounded-[20px] p-[3px] bg-[#FAFAFA]">
              <img
                src={vendorFiles}
                alt=""
                className="h-20 w-20 object-cover rounded-[20px]"
              />
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  );
};
