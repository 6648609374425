import React, { useEffect, useRef, useState } from "react";
import AWS from "aws-sdk";
import createAxiosInstance from "../../service";
import { useAppContext } from "../../context";
import useService, { getTokens } from "../../service/useService";
import { getFromStore } from "../../utils/helpers";
import { HashLoader } from "react-spinners";
import { toast } from "react-toastify";
import avatar from "../../assets/imgs/avatar.jpg";

const UploadAcctImage = ({ uploadedImage, setUploadedImage }) => {
  const fileInputRef = useRef(null);
  // const [uploadedImage, setUploadedImage] = useState(null);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  // const [pics, setPics] = React.useState(userAvatar);

  const token = getTokens();
  const main_id = getFromStore("main_id");
  const details = token.userInfo;
  const userID = details.id;
  const userType = details.user_type;
  const userAvatar = details.user_avatar_url;
  const [pics, setPics] = React.useState(userAvatar);

  // console.log(main_id);
  // console.log(details);

  const basUrl = process.env.REACT_APP_BASE_URL;
  const Service = createAxiosInstance(basUrl);
  // const service = useService(basUrl);
  const vendorBaseUrl = process.env.REACT_APP_VENDOR_BASE_URL;
  const vendorPostService = createAxiosInstance(vendorBaseUrl);
  const { setProfilePicture } = useAppContext();
  // console.log(profilePicture);

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  const s3 = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME },
  });

  const uploadFileToS3 = async (file, description) => {
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: `${description}/${file.name}`,
      Body: file,
    };

    try {
      const uploadResult = await s3.upload(params).promise();
      console.log("File uploaded to S3:", uploadResult.Location);
      const MainId = main_id.id;
      if (uploadResult) {
        const body2 = {
          address: details.address,
          user_avertar_url: uploadResult.Location,
          city: details.city,
          state: details.state,
          country: details.country,
        };

        const body = {
          user_id: userID,
          user_avatar_url: uploadResult.Location,
          temp_login_pin: details.temp_login_pin,
          txn_pin: details.txn_pin,
          country: details.country,
          marital_status: details.marital_status,
          gender: details.gender,
          user_token: details.user_token,
          account_status: details.account_status,
          phone: details.phone,
        };
        console.log(userType);
        if (userType === "buyer") {
          try {
            const response = await Service.patch(`/user/${userID}`, body);
            if (response.status === 200) {
              // console.log(response);
              const pics = response.data.data.user_avatar_url;
              setProfilePicture(pics);
              setPics(pics);
              // console.log(pics);

              toast.success("Upload successful");
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          try {
            console.log(body);
            const response = await vendorPostService.patch(
              `/merchant/users/${userID}`,
              body2
            );
            if (response.status === 200) {
              // console.log(response);
              const pics = response.data.data.user_avertar_url;
              setProfilePicture(pics);
              setPics(pics);
              // console.log(pics);

              toast.success("Upload successful");
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    } catch (error) {
      console.error("Error uploading file to S3:", error);
      toast.error("Error occured try again");
      return null;
    }
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageDataUrl = e.target.result;
        setUploadedImage(imageDataUrl);
      };
      reader.readAsDataURL(file);
      setFileToUpload(file); // Store the file to upload
    }
  };

  const handleDeleteClick = () => {
    setUploadedImage(null);
    setFileToUpload(null);
  };

  const handleUploadToS3 = async () => {
    if (fileToUpload) {
      setIsUploading(true);
      const description = "user-uploads"; // You can customize this as needed
      await uploadFileToS3(fileToUpload, description);
      setIsUploading(false);
      setFileToUpload(null); // Reset the file after upload
    }
  };

  return (
    <div className="relative flex items-center gap-10">
      <div
        id="image-container"
        className="w-[90px] h-[90px] rounded-[100px] relative flex justify-center items-center bg-[#5271FF]"
      >
        {uploadedImage && (
          <img
            src={uploadedImage}
            alt="Uploaded"
            className="w-full h-full rounded-[100px] object-cover"
          />
        )}
        {!uploadedImage && <img src={uploadedImage || avatar} alt="" />}
      </div>
      <div className="relative z-20 flex gap-4">
        <button
          onClick={handleUploadClick}
          className="text-[#5271FF] font-[400] bg-white px-4 py-2 rounded-[100px] shadow-md hover:bg-gray-100 border-[1px] border-[#fafafa]"
        >
          Select Image
        </button>
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          className="hidden"
          onChange={handleFileChange}
        />
        {fileToUpload && (
          <>
            <button
              onClick={handleUploadToS3}
              className="text-[#5271FF] font-[400] bg-white px-4 py-2 rounded-[100px] shadow-md hover:bg-gray-100 border-[1px] border-[#fafafa]"
              disabled={isUploading}
            >
              {isUploading ? (
                <HashLoader size={20} color="#5271FF" />
              ) : (
                "Upload"
              )}
            </button>
            {/* <button
              onClick={handleDeleteClick}
              className="text-[#FF5271] font-[400] bg-white px-4 py-2 rounded-[100px] shadow-md hover:bg-gray-100 border-[1px] border-[#fafafa]"
            >
              Delete
            </button> */}
          </>
        )}
      </div>
    </div>
  );
};

export default UploadAcctImage;
