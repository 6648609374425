import React from "react";
import copy from "../../assets/imgs/copy.svg";
import { useAppContext } from "../../context";
import { TruncateDate } from "../truncatedDate";
import { formatCurrency } from "../../utils/helpers";

export const GetDataTable = ({ tableHeadData, data, variant = false }) => {
  const { userType } = useAppContext();

  const bgFilter = (value) => {
    if (value === "completed" || value === "active" || value === "credit") {
      return "capitalize font-medium h-[28px] w-[107px] text-sm  pt-1 pb-1 rounded-[100px] bg-[#EFFFF6] text-[#449E6A] border-[1px] border-[#83F3B2] ";
    } else if (value === "PENDING") {
      return `capitalize font-medium h-[28px] w-[107px] text-sm  pt-1 pb-1 rounded-[100px] 
        text-[#232323] border-[1px] border-[#F0F0F0] bg-zinc-200`;
    } else if (
      value === "cancelled" ||
      value === "expired" ||
      value === "debit"
    ) {
      return "capitalize font-medium h-[28px] w-[107px] text-sm  pt-1 pb-1 rounded-[100px] bg-[#FFE8E8] text-[#EF5959] border-[1px] border-[#FFBBBB] ";
    }
  };

  return (
    <section>
      <div className="pt-20 px-4">
        <table className="w-full  ">
          <thead className="text-left h-[44px] text-[#9B9697] text-[14px] font-[400] border-[#F0F0F0]">
            <tr>
              {Object.keys(tableHeadData[0]).map(
                (key) =>
                  key !== "id" && (
                    <th
                      key={key}
                      className="capitalize pl-3 tracking-wider font-light"
                    >
                      {key}
                    </th>
                  )
              )}
            </tr>
          </thead>
          <tbody>
            <tr
              key={data.id}
              className={`h-[70px] border-[#F0F0F0]  cursor-pointer `}
            >
              {/* name */}
              <td className=" w-[35%]">
                <div className="flex gap-4 items-center cursor-pointer ">
                  <div className=" h-40 w-40 p-6 border flex justify-center items-center bg-neutral-100 rounded-[200px]">
                    <img
                      src={data.item_image_url}
                      alt=""
                      className="w-[96px] h-[96px]"
                    />
                  </div>
                  <div>
                    <p className="text-[#232323] font-[500] text-lg text-bold  w-[90%]">
                      {data.item_description}
                    </p>
                  </div>
                </div>
              </td>
              {/* amount */}
              <td className="ml-6">
                <div>
                  <p
                    className={` ${
                      userType === "buyer" ? "text-[#232323]" : "text-[#449E6A]"
                    }   font-[500] text-lg`}
                  >
                    {formatCurrency(data.order_amount)}
                  </p>
                </div>
              </td>
              {/* order id */}

              <td>
                <div>
                  <p className="text-[#232323] font-[500] text-lg">
                    {data.order_ref}
                  </p>

                  <div className="flex gap-2 items-center pt-3">
                    <img src={copy} alt="" />
                    <p className="text-[#5271FF] text-[14px] font-[400]">
                      Copy
                    </p>
                  </div>
                </div>
              </td>
              {/* date */}
              <td>
                {variant ? (
                  <TruncateDate data={data.updatedAt} />
                ) : (
                  <TruncateDate
                    data={data.updatedAt}
                    intTl={true}
                    touches={"text-lg"}
                  />
                )}
              </td>

              {/* status */}
              <td className=" pl-2">
                <p
                  className={`${bgFilter(
                    data.order_status
                  )} text-center cursor-pointer `}
                >
                  {data.order_status}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};
