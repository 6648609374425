import React from "react";
import curveBorder from "../../assets/imgs/Ellipse 2857.svg";
import curveBorderOrange from "../../assets/imgs/Ellipse 2857 (1).svg";
import { FaNairaSign } from "react-icons/fa6";

export const ColorCards = ({ delivered, bg, alignItems, title }) => {
  const filterIcon = () => {
    if (title === "Delivered") {
      return <img src={curveBorder} alt="" />;
    } else {
      return <img src={curveBorderOrange} alt="" />;
    }
  };
  return (
    <div className={`w-[45%] rounded-[20px] ${bg} flex ${alignItems}  p-4`}>
      <div className="flex flex-col ">
        <div className={`flex ${alignItems}`}>{filterIcon()}</div>

        <div>
          <div className="pt-5">
            <p className="text-sm text-[#9B9697] ">{title}</p>
          </div>

          <div className="flex items-center">
            <div className="">
              <FaNairaSign size={"15px"} color="black" />
            </div>

            <p className="text-lg text-[#232323] font-[700]">{delivered}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
