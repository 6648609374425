import React from "react";

export default function Button({
  children,
  isBtnDisabled,
  onClick,
  touches,
  size = "fullwidth",
  variant = "contained",
  id,
}) {
  const getBackgroundColor = () => {
    if (variant === "blue") {
      return isBtnDisabled
        ? "bg-[#F6F6F6] cursor-not-allowed text-[#9B9697]"
        : "bg-[#5271FF] hover:bg-indigo-600 text-white";
    } else if (variant === "white") {
      return isBtnDisabled
        ? "bg-[#F6F6F6] cursor-not-allowed text-[#9B9697]"
        : "border-[1px] border-[#F0F0F0] bg-white text-[#5C5959] hover:border-indigo-500 shadow-sm";
    } else if (variant === "authBlue") {
      return isBtnDisabled
        ? "bg-[#C3CEFF] cursor-not-allowed text-[#9B9697]"
        : "bg-[#5271FF] hover:bg-indigo-600 text-white";
    }
  };

  return (
    <button
      id={id}
      onClick={onClick}
      disabled={isBtnDisabled}
      className={` ${getBackgroundColor()}

${
  size === "fullwidth"
    ? "w-full"
    : size === "medium"
    ? "w-1/2"
    : size === "small"
    ? "px-6"
    : ""
}

      
         py-2 rounded-[100px] text-sm  tracking-wider ${touches} hover:scale-105 transition duration-300`}
    >
      {children}
    </button>
  );
}
