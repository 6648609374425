import React from "react";
import { Outlet } from "react-router-dom";
import logo from "../assets/imgs/Logo.svg";
import { useLocation } from "react-router-dom";
export default function Waitlist() {
  const { pathname } = useLocation();
  return (
    <div className="bg-neutral-50 h-screen ">
      <div className=" h-full">
        <div className="h-[10%] 2xl:h-[100px]  w-full bg-neutral-50 flex justify-start pl-7 items-center">
          <div>
            <img src={logo} alt="" />
          </div>
        </div>
        <div className="flex bg-neutral-50 h-[90%] 2xl:h-[calc(100%-100px)]">
          <div
            className={`h-full  ${
              pathname === "/waitlist/buyer"
                ? " w-0 md:w-1 lg:w-[10%]"
                : " w-0 md:w-1 lg:w-[10%]"
            }`}
          ></div>
          <div
            className={`bg-white w-full rounded-tl-[50px] overflow-auto ${
              pathname === "/waitlist/buyer"
                ? "px-2 pt-0 lg:px-14 md:pt-12 "
                : "lg:px-14 md:pt-12 px-2 pt-0"
            }`}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
