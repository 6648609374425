import React from "react";
import circle from "../../assets/imgs/info-circleRed.svg";
import { useNavigate } from "react-router-dom";
import arrowRight from "../../assets/imgs/arrow-right (1).svg";
import TruncatedText from "../truncatedText";
import CopyIcon from "../Icons/CopyIcon";
import { TruncateDate } from "../truncatedDate";
import { formatCurrency, getFromStore } from "../../utils/helpers";
import { GrNext } from "react-icons/gr";

export const TablE = ({
  tHeaders,
  tRows,
  variant,
  activeLink,
  showDetails = true,
}) => {
  const navigate = useNavigate();

  const [currentpage, setCurrentPage] = React.useState(1);
  const [copiedRowId, setShowCopied] = React.useState(null);
  const details = getFromStore("vendyz_user");
  const userType = details.user_type;

  // padgination
  const daTa = tRows;
  const recordsPerPage = 6;
  const lastIndex = currentpage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = daTa.slice(firstIndex, lastIndex);
  const npage = Math.ceil(daTa.length / recordsPerPage);

  const getPaginationGroup = () => {
    let start = Math.max(1, currentpage - 2);
    let end = Math.min(npage, currentpage + 2);
    let pages = [];

    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    if (start > 1) {
      pages.unshift("...");
      pages.unshift(1);
    }

    if (end < npage) {
      pages.push("...");
      pages.push(npage);
    }

    return pages;
  };

  // copy to clip board
  const copyToClipboard = (value, id) => {
    navigator.clipboard.writeText(value).then(() => {
      setShowCopied(id);
      setTimeout(() => {
        setShowCopied(null);
      }, 1500);
    });
  };

  const bgFilter = (value) => {
    if (value === "completed" || value === "active" || value === "credit") {
      return "capitalize font-medium h-[28px] w-[107px] text-sm pt-1 pb-1 rounded-[100px] bg-[#EFFFF6] text-[#449E6A] border-[1px] border-[#83F3B2] ";
    } else if (value === "PENDING") {
      return `capitalize font-medium h-[28px] w-[107px] text-sm pt-1 pb-1 rounded-[100px] 
    text-[#232323] border-[1px] border-[#F0F0F0] bg-zinc-200`;
    } else if (
      value === "cancelled" ||
      value === "expired" ||
      value === "debit"
    ) {
      return "capitalize font-medium h-[28px] w-[107px] text-sm  pt-1 pb-1 rounded-[100px] bg-[#FFE8E8] text-[#EF5959] border-[1px] border-[#FFBBBB] ";
    }
  };

  const transactionAmounColorFilter = (value) => {
    if (value === "credit") {
      return "text-[#449E6A]";
    } else {
      return "text-[#232323]";
    }
  };

  const sortTableRowByPage = (variant, data) => {
    if (variant === "index" && data.length > 0) {
      return (
        <tbody>
          {data.map((data) => (
            <tr
              onClick={() => {
                if (!showDetails) {
                  return;
                } else {
                  navigate(`/orders/${data.id}`);
                }

                console.log(data.id);
              }}
              key={data.id}
              className="h-[70px] border-[#F0F0F0] border-b-[1px] hover:bg-[#F6F6F6] cursor-pointer "
            >
              {/* name */}
              <td>
                <div className="flex gap-2 items-center cursor-pointer">
                  <div>
                    <img
                      src={data.item_image_url}
                      alt=""
                      className="w-[23px] h-[23px] rounded-lg"
                    />
                  </div>
                  <div>
                    <div className="text-[#232323] font-[500] text-[14px]">
                      <TruncatedText
                        maxLength={16}
                        text={data.item_description}
                      />
                    </div>

                    <p className="text-[#9B9697] font-[500] text-sm">
                      {data.merchant_business_name}
                    </p>
                  </div>
                </div>
              </td>
              {/* amount */}
              <td>
                <div>
                  <p className="text-[#232323] font-[500] text-sm">
                    {formatCurrency(data.order_amount)}
                  </p>
                  {data.actionReq && (
                    <div className="flex gap-2 items-center">
                      <img src={circle} alt="" />
                      <p className="text-[#E51837] text-[14px] font-[400]">
                        Action Required
                      </p>
                    </div>
                  )}
                </div>
              </td>
              {/* order id */}

              <td>
                <p className="text-[#232323] text-[14px] font-[400]">
                  {data.order_ref}
                </p>
              </td>

              {/* status */}
              <td className=" pl-2">
                <p
                  className={`${bgFilter(
                    data.order_status
                  )} text-center cursor-pointer`}
                >
                  {data.order_status}
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      );
    } else if (variant === "allLinks" && data.length > 0) {
      return (
        <tbody>
          {data.map((data) => (
            <tr
              on
              key={data.id}
              className={`h-[70px] border-[#F0F0F0] border-b-[1px] hover:bg-[#F6F6F6]`}
            >
              <td>
                <div className="flex gap-2 items-center cursor-pointer">
                  <div>
                    <img
                      src={data.item_image_url}
                      alt=""
                      className="w-[23px] h-[23px] rounded-lg"
                    />
                  </div>
                  <div>
                    <div className="text-[#232323] font-[500] text-[14px]">
                      <TruncatedText
                        maxLength={16}
                        text={data.item_description}
                      />
                    </div>
                  </div>
                </div>
              </td>

              <td>
                <div className="flex gap-2 items-center ">
                  <div
                    className={`bg-[#FfFF] p-1 px-4 rounded-[100px] border-[#F0F0F0] border-[1px] ${
                      data.order_status !== "expired"
                        ? "text-[#232323]"
                        : " text-[#9B9697]"
                    }`}
                  >
                    <TruncatedText maxLength={15} text={data.payment_link} />
                  </div>
                  <div
                    className="cursor-pointer relative"
                    onClick={() => copyToClipboard(data.payment_link, data.id)}
                  >
                    <CopyIcon
                      active={data.order_status !== "expired" ? false : true}
                    />
                    {copiedRowId === data.id && (
                      <div className="text-sm text-bold absolute -bottom-8 tracking-wider text-white w-[80px]">
                        <p className="text-white bg-indigo-400 rounded-lg py-1 px-3 w-full">
                          Copied!
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </td>

              <td>
                <div>
                  <p className="text-[#232323] font-[500] text-sm ">
                    {formatCurrency(data.order_amount)}
                  </p>
                  {data.actionReq && (
                    <div className="flex gap-2 items-center">
                      <img src={circle} alt="" />
                      <p className="text-[#E51837] text-[14px] font-[400]">
                        Action Required
                      </p>
                    </div>
                  )}
                </div>
              </td>

              <td>
                <p className="text-[#232323] text-[14px] font-[400]">
                  <TruncateDate data={data.updatedAt} intTl={true} />
                </p>
              </td>

              <td className=" pl-2">
                <p
                  className={`${bgFilter(
                    activeLink
                  )} text-center cursor-pointer`}
                >
                  {activeLink}
                </p>
              </td>
              {userType !== "buyer" ? (
                <td
                  className="cursor-pointer"
                  onClick={() => {
                    navigate(`/paymentLinks/${data.id}`);
                  }}
                >
                  <GrNext size={20} color="#5271FF" />
                </td>
              ) : (
                ""
              )}
            </tr>
          ))}
        </tbody>
      );
    } else if (variant === "disputes" && data.length > 0) {
      return (
        <tbody>
          {data.map((data) => (
            <tr
              onClick={() => {
                navigate(`/orders/disputes/${data.id}`);
              }}
              key={data.id}
              className={`h-[70px] border-[#F0F0F0] border-b-[1px] hover:bg-[#F6F6F6] cursor-pointer `}
            >
              {/* name */}
              <td>
                <div className="flex gap-2 items-center cursor-pointer">
                  <div>
                    <img
                      src={data.item_image_url}
                      alt=""
                      className="w-[23px] h-[23px] rounded-lg"
                    />
                  </div>
                  <div>
                    <div className="text-[#232323] font-[500] text-[14px]">
                      <TruncatedText
                        maxLength={16}
                        text={data.item_description}
                      />
                    </div>
                    <p className="text-[#9B9697] font-[500] text-sm">
                      {data.merchant_business_name}
                    </p>
                  </div>
                </div>
              </td>
              {/* amount */}
              <td>
                <div>
                  <p className="text-[#232323] font-[500] text-sm">
                    {formatCurrency(data.order_amount)}
                  </p>
                  {data.actionReq && (
                    <div className="flex gap-2 items-center">
                      <img src={circle} alt="" />
                      <p className="text-[#E51837] text-[14px] font-[400]">
                        Action Required
                      </p>
                    </div>
                  )}
                </div>
              </td>
              {/* order id */}

              <td>
                <p className="text-[#232323] text-[14px] font-[400]">
                  {data.order_ref}
                </p>
              </td>
              {/* date */}
              <td>
                <p className="text-[#232323] text-[14px] font-[400]">
                  <TruncateDate data={data.updatedAt} />
                </p>
              </td>

              {/* status */}
              <td className=" pl-2">
                <p
                  className={`${bgFilter(
                    data.order_status
                  )} text-center cursor-pointer `}
                >
                  {data.order_status}
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      );
    } else if (variant === "orders" && data.length > 0) {
      return (
        <tbody>
          {data.map((data) => (
            <tr
              onClick={() => {
                navigate(`/orders/${data.id}`);
              }}
              key={data.id}
              className={`h-[70px] border-[#F0F0F0] border-b-[1px] hover:bg-[#F6F6F6] cursor-pointer `}
            >
              {/* name */}
              <td>
                <div className="flex gap-2 items-center cursor-pointer">
                  <div>
                    <img
                      src={data.item_image_url}
                      alt=""
                      className="w-[23px] h-[23px] rounded-lg"
                    />
                  </div>
                  <div>
                    <div className="text-[#232323] font-[500] text-[14px]">
                      <TruncatedText
                        maxLength={16}
                        text={data.item_description}
                      />
                    </div>
                    <p className="text-[#bda2a7] font-[500] text-sm">
                      {data.merchant_business_name}
                    </p>
                  </div>
                </div>
              </td>
              {/* amount */}
              <td>
                <div>
                  <p className="text-[#232323] font-[500] text-sm">
                    {formatCurrency(data.order_amount)}
                  </p>
                  {data.actionReq && (
                    <div className="flex gap-2 items-center">
                      <img src={circle} alt="" />
                      <p className="text-[#E51837] text-[14px] font-[400]">
                        Action Required
                      </p>
                    </div>
                  )}
                </div>
              </td>
              {/* order id */}

              <td>
                <p className="text-[#232323] text-[14px] font-[400]">
                  {data.order_ref}
                </p>
              </td>

              <td>
                <TruncateDate data={data.updatedAt} />
              </td>

              {/* status */}
              <td className=" pl-2">
                <p
                  className={`${bgFilter(
                    data.order_status
                  )} text-center cursor-pointer `}
                >
                  {data.order_status}
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      );
    } else if (variant === "transactions" && data.length > 0) {
      return (
        <tbody>
          {data.map((data) => (
            <tr
              onClick={() => {
                console.log(data);
                navigate(`/transactions/${data.id}`);
              }}
              key={data.id}
              className={`h-[70px] border-[#F0F0F0] border-b-[1px] hover:bg-[#F6F6F6]`}
            >
              {/* name */}
              <td>
                <div className="flex gap-2 items-center">
                  <div>
                    <img src={data.profilePicture} alt="" />
                  </div>
                  <div>
                    <p className="text-[#232323] font-[500] text-[14px]">
                      {data.AccounTname}
                    </p>
                  </div>
                </div>
              </td>
              {/* amount */}
              <td>
                <div>
                  <p
                    className={`${transactionAmounColorFilter(
                      data.status
                    )} font-[500] text-sm`}
                  >
                    {data.amount}
                  </p>
                  {data.actionReq && (
                    <div className="flex gap-2 items-center">
                      <img src={circle} alt="" />
                      <p className="text-[#E51837] text-[14px] font-[400]">
                        Action Required
                      </p>
                    </div>
                  )}
                </div>
              </td>
              {/* reference number */}
              <td>
                <div className="">
                  <div>
                    <p className="py-[20px] px-[33px] text-[#232323] ">
                      {data.referenceNo}
                    </p>
                  </div>
                </div>
              </td>
              {/* date */}
              <td>
                <p className="text-[#232323] text-[14px] font-[400]">
                  {data.date}
                </p>
              </td>
              {/* status */}
              <td className=" pl-2">
                <p
                  className={`${bgFilter(
                    data.status
                  )} text-center cursor-pointer`}
                >
                  {data.status}
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      );
    }
  };

  const active = () => {
    return "bg-indigo-100 border-[#5271FF] border-[1px] ";
  };

  const prevPage = () => {
    if (currentpage !== 1) {
      setCurrentPage(currentpage - 1);
    }
  };

  const nextPage = () => {
    if (currentpage !== npage) {
      setCurrentPage(currentpage + 1);
    }
  };

  return (
    <section>
      <table className="w-full">
        <thead className="text-left h-[44px] text-[#9B9697] text-[14px] font-[400] border-[#F0F0F0] border-b-[1px]">
          <tr>
            {Object.keys(tHeaders[0]).map(
              (key) =>
                key !== "id" && (
                  <th key={key} className="capitalize pl-3 tracking-wider">
                    {key}
                  </th>
                )
            )}
          </tr>
        </thead>
        {sortTableRowByPage(variant, records)}
      </table>
      <nav className="w-full flex justify-end pt-5 pb-4">
        <ul className="flex gap-2 items-center">
          <li
            onClick={prevPage}
            className="h-[31px] w-[31px] hover:border-[#5271FF] rounded-[100px] bg-[#FAFAFA] shadow-sm text border-[1px] text-[#5C5959] text-sm font-[500] text-center flex justify-center items-center rotate-180 cursor-pointer"
          >
            <img src={arrowRight} alt="" />
          </li>

          {getPaginationGroup().map((n, i) => (
            <li
              key={i}
              onClick={() => typeof n === "number" && setCurrentPage(n)}
              className={`h-[31px] w-[31px] rounded-[100px] bg-[#FAFAFA] shadow-lg text text-[#5C5959] text-sm font-[500] text-center cursor-pointer pt-[4px] ${
                currentpage === n ? active() : ""
              }`}
            >
              {n}
            </li>
          ))}

          <li
            onClick={nextPage}
            className="h-[31px] w-[31px] hover:border-[#5271FF] rounded-[100px] bg-[#FAFAFA] shadow-lg text border-[1px] border-[#F0F0F0] text-[#5C5959] text-sm font-[500] text-center flex justify-center items-center cursor-pointer"
          >
            <img src={arrowRight} alt="" />
          </li>
        </ul>
      </nav>
    </section>
  );
};
