import React, { useEffect } from "react";
import { BuyerLinkConfirm } from "./buyer";
import { VendorLinkConfirm } from "./vendor";
// import { useAppContext } from "../../../context";
import { getFromStore } from "../../../utils/helpers";

export const LinkConfirmation = () => {
  const [userView, setUserVIew] = React.useState(false);
  const [vendorView, setVendorVIew] = React.useState(false);

  // const { userType } = useAppContext();
  const details = getFromStore("vendyz_user");
  const userType = details.user_type;

  useEffect(() => {
    if (userType === "buyer") {
      setUserVIew(true);
      setVendorVIew(false);
    } else {
      setUserVIew(false);
      setVendorVIew(true);
    }
  }, [userType]);
  return (
    <div className="h-full">
      {userView && <BuyerLinkConfirm />}
      {vendorView && <VendorLinkConfirm />}
    </div>
  );
};
