import React from "react";

import logo from "../../../assets/imgs/Logo.svg";
import { useParams } from "react-router-dom";
import OtpInput from "../../../components/OTP";
import { validateOTP, validateStrongPassword } from "../../../utils/validate";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import CountdownTimer from "../../../components/countdownTimer";
import { useEffect } from "react";

const OtpCode = () => {
  const [isBtnDisabled, setisBtnDisabled] = React.useState(true);

  const navigate = useNavigate();

  const [otpValue, setOtpValue] = React.useState("");

  const params = useParams();

  const handleOtpChange = (otp) => {
    setOtpValue(otp);
  };

  useEffect(() => {
    if (otpValue !== "") {
      setisBtnDisabled(false);
    } else {
      setisBtnDisabled(true);
    }
  }, [otpValue]);

  const validate = () => {
    const otpInput = otpValue;
    navigate(`/auth/resetPassword`);
  };

  return (
    <section className="h-full">
      <div className="flex justify-center">
        <img src={logo} alt="" className="" />
      </div>
      <div className="pt-4">
        <div className="flex justify-center">
          <h1 className="text-[27px] font-[700] text-[#232323]">
            Recover password
          </h1>
        </div>
        <div className="flex justify-center pt-5">
          <p className="text-[#9B9697] text-[16px] text-[400] ">
            Enter the code sent to{" "}
            <span className="text-[#232323] text-[16px] pl-2 font-semibold ">
              {params.OtpEmail}
            </span>
          </p>
        </div>
        <div className="flex justify-center w-full ">
          <div className=" flex flex-col items-center gap-3 justify-center pt-12 w-[70%]">
            <div className=" flex justify-center  ">
              <OtpInput
                length={4}
                handleOtpChange={handleOtpChange}
                validateOTP={validateOTP}
              />
            </div>
            <div className="pt-5 w-full justify-center flex flex-col gap-5 items-center ">
              <div className="pl-44">
                <div className="flex gap-4 items-center">
                  <p className="text-[#5C5959] text-[400] text-[16px] text-right">
                    Didn’t get a code?{" "}
                  </p>
                  <span className="text-[#5271FF] pl-5">
                    <CountdownTimer />
                  </span>
                </div>
              </div>
              <div className="flex justify-center items-center  w-[500px] pt-12">
                <div className="w-full">
                  <Button
                    isBtnDisabled={isBtnDisabled}
                    variant="authBlue"
                    touches={"h-[44px]"}
                    onClick={(e) => {
                      e.preventDefault();
                      validate();
                    }}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center gap-4 pt-6 pb-5">
              <div>
                <h1 className="text-[16px] font-[400] text-[#5C5959]">
                  Already have an account?
                </h1>
              </div>

              <div>
                <h1
                  onClick={() => navigate("/auth")}
                  className="text-[16px] font-[500] text-[#5271FF] cursor-pointer"
                >
                  Log in
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OtpCode;
