/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import lock from "../../../assets/imgs/lock.svg";
// import profilePicture from "../../../assets/profilePictures/Avatar.svg";
// import productImg from "../../../assets/imgs/Ellipse 2858.svg";
import copy from "../../../assets/imgs/copy.svg";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import vedorLogo from "../../../assets/imgs/mooiIcon.svg";
import { AES, enc } from "crypto-js";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
// import Textfield from "../../../components/Textfield";
// import MonthYearInput from "../../../components/yearAndMontInput";
// import ThreeDigitPasswordInput from "../../../components/three-digit-password";

import notice from "../../../assets/imgs/info-circle.svg";
import ContainerWithGradient from "../../../components/container-with-gradient";
import {
  convertTimeToGMT,
  formatCurrency,
  formatDate,
  getFromStore,
  removeFromStore,
} from "../../../utils/helpers";
import useService, { getTokens } from "../../../service/useService";
import { HashLoader } from "react-spinners";

export const CardPayment = () => {
  const [showCopied, setShowCopied] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [orderDetail, setOrderDetail] = useState(null);

  const basUrl = process.env.REACT_APP_ODER_BASE_URL;
  const walletBaseurl = process.env.REACT_APP_WALLET_BASE_URL;
  const Service = useService(basUrl);
  const WalletService = useService(walletBaseurl);

  const token = getTokens();
  const userInfo = token.userInfo;

  const ipAddress = getFromStore("ipaddress") || null;
  const navigate = useNavigate();
  const paymentLink = getFromStore("payment_link", "local") || null;

  function copyToCipboard(value) {
    const result = navigator.clipboard.writeText(value);

    result.then(() => {
      setShowCopied(true);
      setTimeout(() => {
        setShowCopied(false);
      }, [1500]);
    });
  }

  // const handleCardNumberChange = (e) => {
  //   const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
  //   const formattedValue = value.replace(/(.{4})/g, "$1 ").trim(); // Add space every 4 digits
  //   setCardNumber(formattedValue);
  // };

  useEffect(() => {
    const fetchData = async () => {
      const body = {
        payment_link: paymentLink,
      };

      try {
        const response = await Service.post(
          "/buyer/orders/validate/link",
          body
        );

        if (response.status === 201) {
          const detail = response.data.data.order_details;

          setOrderDetail(detail);
          setIsLoading(false);
        } else return;
      } catch (error) {
        const errmsg = error.response.data.message;
        if (errmsg === "Invalid Payment Link") {
          navigate("/");
        }

        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleMakePayment = async () => {
    setIsLoading(true);

    const body = {
      user_id: userInfo.id,
      wallet_ref: userInfo.user_txn_ref,
      amount: Number(orderDetail.order_amount),
      channel_id: "WEB",
      user_ip: ipAddress.data.ip,
      transaction_location: "nigeria",
      funding_option: "card",
      request_ref: orderDetail.order_ref,
      service_type: "ACCOUNTS",
      redirect_url: "https://www.app.vendyz.com/",
    };

    const bodyWithId = () => {
      if (orderDetail.order_type === "VENDOR_ORDER") {
        return {
          ...body,
          vendor_order_id: orderDetail.id,
        };
      } else if (orderDetail.order_type === "BUYER_ORDER") {
        return {
          ...body,
          buyer_order_id: orderDetail.id,
        };
      } else return body;
    };

    try {
      const response = await WalletService.post(
        "/wallet/fund/with/card",
        bodyWithId()
      );

      if (response.status === 201) {
        const secretKey = process.env.REACT_APP_SECRECT_KEY;

        const dcryptPublicKey = (publicKey, secret) => {
          let bytes;
          try {
            bytes = AES.decrypt(publicKey, secret);
            const decrypted = bytes.toString(enc.Utf8);
            return decrypted;
          } catch (error) {
            console.log("Unable to Decipher", error);
            return null;
          }
        };

        const decryptedPublicKey = dcryptPublicKey(
          response.data.data.public_key,
          secretKey
        );

        if (!decryptedPublicKey) {
          console.error("Decryption failed or public_key is invalid");
          setIsLoading(false);
          return;
        }

        response.data.data.public_key = decryptedPublicKey;

        const handleFlutterPayment = useFlutterwave(response.data.data);

        handleFlutterPayment({
          callback: async (paymentResponse) => {
            console.log(paymentResponse);
            if (paymentResponse.status === "successful") {
              setTimeout(() => {
                navigate("/");
                removeFromStore("payment_link", "local");
                closePaymentModal();
              }, 1200);
            }
            setIsLoading(false);
          },
          onClose: () => {
            setIsLoading(false);
          },
        });
      }
    } catch (error) {
      console.error("Error in handleMakePayment:", error);
      setIsLoading(false);
    }
  };

  console.log(orderDetail);

  if (!orderDetail) {
    return (
      <div className="h-screen w-full flex items-center justify-center">
        <HashLoader color="#5271FF" speedMultiplier={0.8} />
      </div>
    );
  }

  // return <div className=""></div>;

  return (
    <section className=" h-full">
      <div className="flex justify-center flex-col items-center gap-3  w-full  pt-10">
        <ContainerWithGradient
          height={"h-[44px]"}
          width={" w-[90%] 2xl:w-[79%]"}
        >
          <div className="flex justify-center items-center">
            <div className="flex gap-2  items-center ">
              <div>
                <img src={notice} alt="" />
              </div>
              <div>
                {orderDetail.order_type === "BUYER_ORDER" && (
                  <h1 className="text-[#232323] 2xl:text-sm text-[12px]  ">
                    {" "}
                    Please complete your payment to continue
                  </h1>
                )}
                {orderDetail.order_type === "VENDOR_ORDER" && (
                  <h1 className="text-[#232323] 2xl:text-sm text-[12px]  ">
                    {" "}
                    Please complete your payment before
                    <span className="font-bold">
                      {" "}
                      {formatDate(orderDetail?.expiry_date)},{" "}
                      {convertTimeToGMT(orderDetail?.expiry_time)}.
                    </span>{" "}
                    after which the link expires. Contact your vendor for any
                    additional information
                  </h1>
                )}
              </div>
            </div>
          </div>
        </ContainerWithGradient>

        <div className="flex items-center gap-3 pt-4">
          <div>
            <img src={lock} alt="" />
          </div>
          <div>
            <p className="text-[12px] text-[#9B9697] ">
              Secured by{" "}
              <span className="text-[#5C5959] font-[500]">Vendyz</span>
            </p>
          </div>
        </div>

        <div className="flex justify-center flex-col items-center gap-4 2xl:gap-8">
          <div className="2xl:pt-4">
            <img src={vedorLogo} alt="" className="w-28" />
          </div>
          <div className="text-center">
            <p className="text-[#232323] font-[700] text-[16px]">
              {orderDetail.merchant_business_name} asks for
            </p>
            <p className="text-[#232323] font-[700] text-[24px]">
              {formatCurrency(orderDetail.order_amount)}
            </p>
          </div>
        </div>

        <div className="h-[156px] p-3 w-[592px] bg-[#FAFAFA] rounded-[25px] flex justify-center">
          <div className="flex items-center gap-3   justify-between w-[70%]">
            <div className="flex items-center w-[60%] gap-4">
              <div className="rounded">
                <img
                  src={orderDetail.item_image_url}
                  alt=""
                  className="w-[100px] h-[100px] object-contain"
                />
              </div>

              <div>
                <p className="text-[500] text-[12px] text-[#5C5959] font-[500]">
                  {orderDetail.item_description}
                </p>
              </div>
            </div>

            <div className="flex justify-end border-l-2 w-[30%]">
              <div className="flex flex-col items-center justify-center w-full gap-3">
                <div>
                  <p className="text-[12px] font-[700] ">
                    {" "}
                    {formatCurrency(orderDetail.order_amount)}
                  </p>
                </div>
                <div>
                  <p className="text-[12px] font-[500] text-[#5C5959] pb-2">
                    {orderDetail.order_ref}
                  </p>
                  <div
                    onClick={() => copyToCipboard(orderDetail.order_ref)}
                    className="cursor-pointer flex items-center relative"
                  >
                    <div>
                      <img src={copy} alt="" />
                    </div>
                    <div>
                      <p className="text-[#5271FF] text-[12px] pl-1">Copy</p>
                    </div>
                    {showCopied && (
                      <div className="text-sm text-bold absolute -bottom-8 tracking-wider text-white w-[80px]">
                        <p className="text-white  bg-indigo-400 rounded-lg py-1 px-3  w-full">
                          Copied!
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="w-full flex justify-center items-center flex-col pt-3 gap-7">
          <div className="w-[592px] flex flex-col items-center justify-center gap-4">
            <div className="w-full">

              <div className=" w-full flex flex-col gap-1 ">
                <label
                  className={`text-[#5C5959] font-[400] tracking-wider font-400 text-[12px]`}
                >
                  Card number
                </label>
                <div
                  className={`border-[1px] border-[#F0F0F0] rounded-[20px] items-center gap-2 w-full outline-none hover:shadow-[#F0F0F0] h-[50px] px-2 flex  `}
                >
                  <input
                    type="text"
                    value={cardNumber}
                    onChange={handleCardNumberChange}
                    className="border-none outline-none bg-transparent h-full w-full px-3"
                    placeholder="0012 3456 7890 1234"
                    maxLength={19} // 16 digits + 3 spaces
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center gap-3 w-full">
              <div className="w-full">
                <MonthYearInput
                  value={cardExpiryDate}
                  setValue={setCardExpiryDate}
                  label={"Expiry date"}
                  labelStyle={"text-[#5C5959] font-400 text-[12px]"}
                />
              </div>
              <div className="w-full">
                <ThreeDigitPasswordInput
                  setValue={setCvvValue}
                  label={"CVV"}
                  labelStyle={"text-[#5C5959] font-400 text-[12px]"}
                  value={cvvvalue}
                  placeholder
                />
              </div>
            </div>
          </div>
        </div>

        <div className="py-6 w-[592px] cursor-pointer">
          <div className="flex items-center">
            <input
              className="cursor-pointer"
              value={rememberCard}
              type="checkbox"
              name=""
              id="card"
              onClick={(e) => {
                setRememberCard(e.target.value);
                rememberCard === "no"
                  ? setRememberCard("yes")
                  : setRememberCard("no");
              }}
            />
            <div className="pl-2 text-sm text-[#5C5959] ">
              <label htmlFor="card" className="cursor-pointer">
                Remember this card next time
              </label>
            </div>
          </div>
        </div>

        <div className="w-[592px] pb-4">
          <p className="text-sm text-[#5C5959]">
            By continuing, you confirm to have accepted Vendyz{" "}
            <span className="underline text-[#5271FF] pl-2 cursor-pointer">
              privacy policy.
            </span>{" "}
          </p>
        </div> */}

        <div className="flex gap-4 justify-center w-[592px] pb-4 ">
          <div className="w-1/2">
            <Button
              onClick={() => {
                navigate(-1);
              }}
              size="fullwidth"
              variant="white"
              touches={"h-[44px]"}
            >
              Back
            </Button>
          </div>
          <div className="w-1/2">
            <Button
              onClick={handleMakePayment}
              variant="authBlue"
              size="fullwidth"
              touches={"h-[44px]"}
              // isBtnDisabled={buttonDisabled}
            >
              Proceed
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
