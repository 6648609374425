import React, { useEffect, useState } from "react";
import Button from "../../../../components/Button";
import Textfield from "../../../../components/Textfield";
import TextArea from "../../../../components/TextArea";
// import InputFile from "../../../../components/drag-and-drop";kju
import MajorTitle from "../../../../components/MajorTitle";
import { useNavigate } from "react-router-dom";
import monitorIcon from "../../../../assets/imgs/monitor.svg";
import inActiveMonitorIcon from "../../../../assets/imgs/inActiveMonitor.svg";
import inActiveRecieptIcon from "../../../../assets/imgs/receipt.svg";
import activeRecieptIcon from "../../../../assets/imgs/acvtiveRecieptIcon.svg";
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";
import { PaymentPreview } from "../../../../components/payment-preview";
import { CustomRecieptPreview } from "../../../../components/cunstom-reciept-preview";
// import TimeInput from "../../../../components/time-textfield";
import IdCardInputFile from "../../../../components/idCardDragDrop";
import createAxiosInstance from "../../../../service";
import { getFromStore, saveInStore } from "../../../../utils/helpers";
import AWS from "aws-sdk";
import { ClockLoader, PuffLoader } from "react-spinners";
import { toast } from "react-toastify";
import ParentComponent from "../../../../components/number-input";
import GetTime from "../../../../components/useTIme-input";

export const VendorLinkGeneration = () => {
  const [selectedNumber, setSelectedNumber] = React.useState(1);
  const [description, setDescription] = useState("");
  const [busnessDescription, setBusnessDescription] = useState("");
  const [footNote, setFootNote] = useState("");
  const [amount, setAmount] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [customLink, setCustomLink] = useState("https://www.pay.vendyz.com/");
  const [itemImage, setItemImage] = useState(null);
  const [bussinessLogo, setBussnessLogo] = useState(null);
  const [customRecieptForm, setCustomRecieptForm] = useState(false);
  const [paymentPreview, setPaymentPreview] = useState(true);
  const [customRecieptPreview, setCustomRecieptPreview] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [pageLoad, setPageLoad] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [itemName, setItemName] = useState("");
  const [businessName, setBusinessName] = React.useState("");
  // const [expTime, setExptime] = useState("");
  const [timeString, setTimeString] = useState("");
  const navigate = useNavigate();
  const merchantBusinessINfo = getFromStore("merchant-B");
  // const businesName = merchantBusinessINfo.business_name;
  // const details = getFromStore("vendyz_user");
  // const userID = details.id;
  // const userType = details.user_type;
  const basUrl = process.env.REACT_APP_ODER_BASE_URL;
  const Service = createAxiosInstance(basUrl);

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });
  const s3 = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME },
  });

  const uploadFileDocs = async () => {
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: `${itemImage.name}`,
      Body: itemImage,
    };
    try {
      setButtonDisabled(true);
      setLoading(true);
      const uploadResult = await s3.upload(params).promise();

      if (uploadResult) {
        try {
          const body = {
            merchant_business_id: merchantBusinessINfo.id,
            item_image_url: uploadResult.Location,
            item_name: itemName,
            item_description: description,
            order_amount: Number(amount),
            merchant_business_name: merchantBusinessINfo.business_name,
            quantity: selectedNumber,
            expiry_date: expiryDate,
            expiry_time: timeString,
          };

          const response = await Service.post(`/vendor/orders`, body);
          if (response.status === 201) {
            setLoading(false);
            setButtonDisabled(false);
            saveInStore("generatedOrder", response.data.data.order_details);
            navigate("/generate-link/create-link");
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
          setButtonDisabled(false);

          if (error.message === "timeout exceeded") {
            toast.error("Timeout exceeded");
          } else if (error.message === "Network Error") {
            toast.error("Network error");
          } else if (
            error.response.data.message ===
            "Untreated Order Payment With The Same Name Already Exist"
          ) {
            toast.error(
              "Untreated Order Payment With The Same Name Already Exist"
            );
          }
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // console.log(merchantBusinessINfo);
  // console.log(description);

  const handleRecieptForm = () => {
    if (!customRecieptForm) {
      setCustomRecieptForm(true);
      setPaymentPreview(false);
      setCustomRecieptPreview(true);
    } else {
      setCustomRecieptForm(false);
      setPaymentPreview(true);
      setCustomRecieptPreview(false);
    }
  };
  const handleTimeChange = (time, timeString) => {
    console.log(timeString);
    setTimeString(timeString);
  };
  // useEffect(() => {
  //   if (!customRecieptForm) {
  //     setButtonDisabled(false);
  //   } else {
  //     setButtonDisabled(true);
  //   }
  // }, [customRecieptForm]);

  useEffect(() => {
    description !== "" &&
    amount !== "" &&
    itemImage !== null &&
    expiryDate !== "" &&
    businessName !== "" &&
    itemName !== "" &&
    timeString !== ""
      ? setButtonDisabled(false)
      : setButtonDisabled(true);
  }, [
    description,
    amount,
    businessName,
    itemName,
    itemImage,
    expiryDate,
    timeString,
  ]);

  if (pageLoad) {
    return (
      <div className="h-72 w-full flex justify-center pt-44">
        <PuffLoader color="#5271FF" speedMultiplier={0.8} />
      </div>
    );
  }

  return (
    <section className="h-full ">
      <div>
        <MajorTitle title={"New escrow transaction"} />
      </div>
      <div className="px-4 w-full h-full overflow-hidden">
        <div className="w-full flex justify-between h-full">
          <div className="w-[45%] bg-white h-full overflow-auto">
            <div className="p-4 pt-7">
              <div>
                <h1 className="text-[#232323] text-[16px] font-[700]">
                  Order details
                </h1>
              </div>

              <div className="pt-3">
                <div>
                  <p className="text-[#5C5959] text-sm font-sm pb-2">
                    Item image
                  </p>
                  <div>
                    <IdCardInputFile
                      id={"card-1"}
                      selectedFile={itemImage}
                      setSelectedFile={setItemImage}
                      n
                      // handleSubmit={uploadFileToS3}
                    />
                  </div>
                  <div className="pt-4">
                    <TextArea
                      maxiumum={1000}
                      counterMaxLength={1000}
                      description={description}
                      setDescription={setDescription}
                      placeholder={"Between 4 - 1000 characters"}
                      counter
                      label={"Description"}
                    />
                  </div>

                  <div className="pt-4">
                    <Textfield
                      moneyIcon={true}
                      type={"number"}
                      placeholder={"Minimum of 100 NGN"}
                      onChange={(e) => setAmount(`${e.target.value}`)}
                      value={`${amount}`}
                      labal={"Amount:"}
                      labelStyle={"text-[14px] font-[400] text-[#5C5959]"}
                    />
                  </div>

                  <div className="pt-4">
                    <Textfield
                      type={"text"}
                      placeholder={"Item name"}
                      onChange={(e) => setItemName(e.target.value)}
                      value={itemName}
                      labal={"Item name"}
                      labelStyle={"text-[14px] font-[400] text-[#5C5959]"}
                    />
                  </div>
                  <div className="pt-4">
                    <Textfield
                      labelStyle={"text-[14px] text-[#5C5959] font-[400]"}
                      type={"text"}
                      labal={"Vendor business name"}
                      placeholder={"who are you buying from"}
                      value={businessName}
                      onChange={(e) => {
                        setBusinessName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="pt-4">
                    <ParentComponent
                      selectedNumber={selectedNumber}
                      setSelectedNumber={setSelectedNumber}
                    />
                  </div>

                  <div className="flex flex-col gap3 pt-5 pb-7">
                    <div className="flex items-center justify-between gap-3">
                      <div className="w-1/2">
                        <Textfield
                          type={"date"}
                          value={expiryDate}
                          onChange={(e) => setExpiryDate(e.target.value)}
                          labal={" Payment date"}
                          labelStyle={"text-[14px] font-[400] text-[#5C5959]"}
                        />
                      </div>
                      <div className="w-1/2 ">
                        <GetTime onChange={handleTimeChange} />
                      </div>
                    </div>

                    <div className="flex items-center w-full pb-5">
                      <div className="w-full">
                        <Textfield
                          className={"rounded-r-none bg-[#F6F6F6]"}
                          labal={"Custom link (Optional)"}
                          value={customLink}
                          disabled
                          onChange={(e) => {
                            setCustomLink(e.target.value);
                          }}
                          labelStyle={"text-[14px] font-[400] text-[#5C5959]"}
                        />
                      </div>
                      <div className="w-full pt-6">
                        <Textfield
                          className={"rounded-l-none"}
                          disabled={true}
                          value={""}
                        />
                      </div>
                    </div>

                    <div className="pt-4">
                      <div className="flex justify-between items-center border-t-2 py-5">
                        <div className="">
                          <h1
                            className={"text-[#232323] text-[16px] font-[700]"}
                          >
                            Receipt Customization (Optional)
                          </h1>
                        </div>
                        <div
                          className="cursor-pointer"
                          onClick={handleRecieptForm}
                        >
                          {!customRecieptForm ? (
                            <CiCirclePlus size={28} />
                          ) : (
                            <CiCircleMinus size={28} />
                          )}
                        </div>
                      </div>
                    </div>

                    {customRecieptForm && (
                      <div className="flex flex-col gap-3">
                        <div>
                          <p className="text-[#5C5959] text-sm font-sm pb-2">
                            Business logo
                          </p>

                          <IdCardInputFile
                            id={"card-2"}
                            selectedFile={bussinessLogo}
                            setSelectedFile={setBussnessLogo}
                            // handleSubmit={uploadFileToS3}
                          />
                        </div>

                        <div className="pt-3">
                          <Textfield
                            labal={"Business name"}
                            labelStyle={"text-[14px] font-[400] text-[#5C5959]"}
                            placeholder={"Vendyz"}
                          />
                        </div>

                        <div>
                          <TextArea
                            maxiumum={200}
                            counterMaxLength={200}
                            description={busnessDescription}
                            setDescription={setBusnessDescription}
                            placeholder={"Between 4 - 200 characters"}
                            label={"About business"}
                          />
                        </div>

                        <div>
                          <TextArea
                            maxiumum={200}
                            counterMaxLength={200}
                            description={footNote}
                            setDescription={setFootNote}
                            placeholder={"Between 4 - 200 characters"}
                            label={"Foot note"}
                          />
                        </div>
                      </div>
                    )}

                    <div className="pt-7 pb-7 2xl:pt-10 mb-2 border-t-2">
                      <div className="flex gap-4 justify-center w-full">
                        <div className="w-1/2">
                          <Button
                            size="fullwidth"
                            variant="white"
                            touches={"h-[44px]"}
                            onClick={() => navigate("/")}
                          >
                            Cancel
                          </Button>
                        </div>

                        <div className="w-1/2">
                          <Button
                            variant="authBlue"
                            size="fullwidth"
                            touches={"h-[44px]"}
                            onClick={uploadFileDocs}
                            isBtnDisabled={buttonDisabled}
                          >
                            {loading ? (
                              <ClockLoader
                                size={"30px"}
                                speedMultiplier={0.8}
                                color="#fafafa"
                              />
                            ) : (
                              " Generate and share link"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* right */}
          <div className="w-[50%] bg-[#FAFAFA] rounded-[20px] h-full ">
            <div className="w-full">
              <div className="p-4 pl-6 pt-7 flex justify-between items-center">
                <div>
                  <p className="text-[#5C5959] text-[16px] font-[500]">
                    Preview
                  </p>
                </div>

                <div className="flex items-center gap-9 2xl:gap-14">
                  <div
                    onClick={() => {
                      setCustomRecieptPreview(true);
                      setPaymentPreview(false);
                    }}
                    className={`rounded-[100px] w-[150px] h-[40px] flex items-center justify-center gap-2 border-[#F0F0F0] cursor-pointer ${
                      !customRecieptPreview
                        ? "bg-transparent"
                        : "bg-white border-[1px] shadow-sm"
                    }`}
                  >
                    <img
                      src={
                        !customRecieptPreview
                          ? inActiveRecieptIcon
                          : activeRecieptIcon
                      }
                      alt="Receipt"
                    />
                    <h1
                      className={`font-semibold text-sm ${
                        !customRecieptPreview
                          ? "text-[#9B9697]"
                          : "text-[#232323]"
                      }`}
                    >
                      Receipt
                    </h1>
                  </div>

                  <div
                    onClick={() => {
                      setCustomRecieptPreview(false);
                      setPaymentPreview(true);
                    }}
                    className={`rounded-[100px] w-[150px] h-[40px] flex items-center justify-center gap-2 border-[#F0F0F0] cursor-pointer ${
                      !paymentPreview
                        ? "bg-transparent"
                        : "bg-white border-[1px] shadow-sm"
                    }`}
                  >
                    <img
                      src={!paymentPreview ? inActiveMonitorIcon : monitorIcon}
                      alt="Payment page"
                    />
                    <h1
                      className={`font-semibold text-sm ${
                        !paymentPreview ? "text-[#9B9697]" : "text-[#232323]"
                      }`}
                    >
                      Payment page
                    </h1>
                  </div>
                </div>
              </div>

              {paymentPreview && <PaymentPreview />}
              {customRecieptPreview && <CustomRecieptPreview />}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
