import React, { useEffect, useState } from "react";
import MajorTitle from "../../components/MajorTitle";
import OrdersCard from "../../components/orders-card";
import { Outlet } from "react-router-dom";
import PaymentLinkNavBar from "../../components/PaymentLinkNav";
import { getFromStore } from "../../utils/helpers";
import useService from "../../service/useService";
import { toast } from "react-toastify";
import { PuffLoader } from "react-spinners";

export const Paymentlinks = () => {
  const [linkKLength, setLinkLength] = useState("");
  const [pageLoad, setPageLoad] = useState(true);
  const details = getFromStore("vendyz_user");
  const userID = details.id;
  const userType = details.user_type;
  const basUrl = process.env.REACT_APP_ODER_BASE_URL;
  const Service = useService(basUrl);
  const merchant_id = getFromStore("merchant_id");

  useEffect(() => {
    const getDetails = async () => {
      try {
        const response =
          userType === "buyer"
            ? await Service.get(`/buyer/orders/user/${userID}`)
            : await Service.get(`/vendor/orders/vendor/${merchant_id}`);
        if (response.status === 200) {
          console.log(response);
          setLinkLength(response.data.data.data.length);
          setPageLoad(false);
        }
      } catch (error) {
        if (error.message === "timeout exceeded") {
          toast.error("Timeout exceeded");
        } else if (error.message === "Network Error") {
          toast.error("Network error");
        }
      }
    };

    getDetails();
  }, []);

  if (pageLoad) {
    return (
      <div className="h-72 w-full flex justify-center pt-44">
        <PuffLoader color="#5271FF" speedMultiplier={0.8} />
      </div>
    );
  }
  return (
    <section>
      <div>
        <MajorTitle title={"Payment links"} />
      </div>

      <div className="flex w-full pt-8 gap-8">
        <div className="w-[23%]">
          <OrdersCard title={"Links"} figure={linkKLength} amount={0} />
        </div>
        <div className="w-[23%]">
          <OrdersCard title={"Expired"} figure={0} amount={0} />
        </div>
        <div className="w-[23%]">
          <OrdersCard title={"Paid"} figure={0} amount={0} />
        </div>
        <div className="w-[23%]">
          <OrdersCard title={"Unpaid"} figure={0} amount={0} />
        </div>
      </div>
      <div>
        <PaymentLinkNavBar />
      </div>

      <Outlet />
    </section>
  );
};
