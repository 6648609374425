import React, { useEffect, useState } from "react";
import MajorTitle from "../../../components/MajorTitle";
import SelectInput from "../../../components/select-input";
import copy from "../../../assets/imgs/copy.svg";
import Textfield from "../../../components/Textfield";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";

const WithdrawPage = () => {
  const [accountNumber, setAccountNumber] = useState("");
  const [bank, setBank] = useState("");
  const [accountName, setAccountName] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [remarks, setRemarks] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);

  const [accountNumberCopied, setAccountNumberCopied] = useState(false);

  const navigate = useNavigate();

  // function copyAccToCipboard(value) {
  //   const result = navigator.clipboard.writeText(value);

  //   result.then(() => {
  //     setAccountNumberCopied(true);
  //     setTimeout(() => {
  //       setAccountNumberCopied(false);
  //     }, [1500]);
  //   });
  // }

  // useEffect(() => {
  //   if (
  //     accountNumber !== "" &&
  //     bank !== "" &&
  //     accountName !== "" &&
  //     withdrawAmount !== ""
  //   ) {
  //     setButtonDisable(false);
  //   } else {
  //     setButtonDisable(true);
  //   }
  // }, [accountName, bank, accountNumber, withdrawAmount]);

  const walletBalance = "₦200,000.00";

  return (
    <section className="">
      <div>
        <MajorTitle title={"Withdraw"} />
      </div>

      <div className="flex justify-center items-center flex-col gap-5">
        <div className="flex px-2 w-[226px] h-[44px] border-[1px] border-[#F0F0F0] rounded-[25px] items-center justify-center gap-2 ">
          <div className="text-[12px] font-[500] text-[#5C5959]">
            {" "}
            Wallet balance
          </div>
          <div className="text-[#232323] font-[700] text-[12px] border-l-2 pl-3 py-2">
            {walletBalance}
          </div>
        </div>

        <div>
          <div className="pb-3">
            <p className="text-[16px] font-[700] text-[#232323] text-center">
              {" "}
              Enter amount
            </p>
          </div>

          <div className="flex justify-center bg-[#FAFAFA] w-fit h-[50px] items-center pl-8 rounded-[25px]">
            <div className=" w-1/2  ">
              <p className="text-[24px] font-[700] text-[#9B9697] text-right">
                ₦
              </p>
            </div>

            <div>
              <input
                value={withdrawAmount}
                onChange={(e) => setWithdrawAmount(e.target.value)}
                type="number"
                className="border-none outline-none text-[24px] font-[700] text-[#232323] bg-transparent w-1/2"
                placeholder="0.000"
              />
            </div>
          </div>
        </div>

        <div className="w-[592px]">
          <SelectInput
            label={"Bank"}
            labelStyle={"text-[#5C5959] text-sm"}
            value={bank}
            onChange={(e) => setBank(e.target.value)}
          >
            <option value="">Select option</option>
            <option value="Nigeria" className="text-[#9B9697]">
              Zenith bank
            </option>
            <option value="USA" className="text-[#9B9697]">
              UBA
            </option>
          </SelectInput>
        </div>

        <div className="w-[592px] flex flex-col items-center gap-5">
          <div className="w-full">
            <div className="text-sm text-[#5C5959] font-400 pb-1 text-left w-full">
              Account number
            </div>
            <div className=" w-full  flex items-center justify-between border-[1px] border-[#F0F0F0] rounded-[20px]  gap-2  outline-none shadow-[#F0F0F0] h-[50px] px-2 shadow-sm">
              <div>
                <input
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                  type="number"
                  placeholder="5560014293"
                  className="text-[16px] font-[500] text-[#232323] border-none outline-none w-full bg-transparent"
                ></input>
              </div>

              <div
                //   onClick={() => copyAccToCipboard(accountNumber)}
                className="cursor-pointer flex items-center relative"
              >
                <div>
                  <img src={copy} alt="" />
                </div>
                <div>
                  <p className="text-[#5271FF] text-[12px] pl-1">Paste</p>
                </div>
                {accountNumberCopied && (
                  <div className="text-sm text-bold absolute -bottom-8 tracking-wider text-white w-[80px]">
                    <p className="text-white  bg-indigo-400 rounded-lg py-1 px-3  w-full">
                      Copied!
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="w-full">
            <Textfield
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
              type={"text"}
              labal={"Account Name"}
              labelStyle={"text-[#5C5959] text-sm"}
              className={"bg-[#F6F6F6]"}
            />
          </div>

          <div className="w-full">
            <Textfield
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              type={"text"}
              labal={"Remarks (Optional)"}
              labelStyle={"text-[#5C5959] text-sm"}
              placeholder={"For lunch"}
            />
          </div>

          <div className="w-full pt-12 flex justify-center">
            <div>
              <div className="flex gap-4 justify-center w-[592px] ">
                <div className="w-1/2">
                  <Button
                    onClick={() => {
                      navigate("/");
                    }}
                    size="fullwidth"
                    variant="white"
                    touches={"h-[44px]"}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="w-1/2">
                  <Button
                    isBtnDisabled={buttonDisable}
                    onClick={() => {
                      navigate("/withdraw/review-withdraw");
                    }}
                    variant="authBlue"
                    size="fullwidth"
                    touches={"h-[44px]"}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WithdrawPage;
