import React, { useState, useRef, useEffect } from "react";

const OtpInput = ({ length, handleOtpChange, validateOTP }) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const [error, setError] = useState("");

  const inputRefs = useRef([]);

  useEffect(() => {
    handleOtpChange(otp.join(""));
  }, [handleOtpChange, otp]);

  const handleChange = (index, event) => {
    const value = event.target.value;
    const updatedOtp = [...otp];
    updatedOtp[index] = value;

    if (index === length - 1 && value !== "") {
      const otpString = updatedOtp.join("");
      const validationResult = validateOTP(otpString);

      if (validationResult !== true) {
        setError(validationResult);
      } else {
        setError("");
        setOtp(updatedOtp);
      }
    } else {
      setOtp(updatedOtp);
    }

    if (index < length - 1 && value !== "") {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      const updatedOtp = [...otp];
      updatedOtp[index - 1] = "";
      setOtp(updatedOtp);

      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData
      .getData("text/plain")
      .slice(0, length);
    const updatedOtp = [...otp];
    pasteData.split("").forEach((character, index) => {
      if (!isNaN(character)) {
        updatedOtp[index] = character;
        if (index < length - 1) {
          inputRefs.current[index + 1].focus();
        }
      }
    });
    setOtp(updatedOtp);
  };

  useEffect(() => {
    const validationResult = validateOTP(otp.join(""));
    if (validationResult !== true) {
      setError(validationResult);
    } else {
      setError("");
    }
  }, [otp, validateOTP]);

  return (
    <div className="flex flex-col gap-2">
      {/* <label htmlFor="">OTP</label> */}
      <div className="flex gap-2 justify-between">
        {Array.from({ length }).map((_, index) => (
          <input
            key={index}
            ref={(el) => (inputRefs.current[index] = el)}
            type="number"
            maxLength={1}
            value={otp[index]}
            onChange={(e) => {
              handleChange(index, e);
            }}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onPaste={(e) => handlePaste(e)}
            className="mx-1 w-[95px] h-[74px] text-center border border-gray-300 rounded-[20px] focus:outline-none focus:border-[#5271FF] "
          />
        ))}
      </div>
      {/* {error && <small className="text-red-500 text-sm">{error}</small>} */}
    </div>
  );
};

export default OtpInput;
