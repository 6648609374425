import React from "react";
import securityIcon from "../../assets/imgs/Badge.svg";
import notice from "../../assets/imgs/info-circle.svg";
import container from "../../assets/imgs/Container.svg";
import dumieBtns from "../../assets/imgs/Actions.svg";
import dimmieInptField from "../../assets/imgs/Container (1).svg";
import companyIcon from "../../assets/imgs/mooiIcon.svg";
import gradientBG from "../../assets/imgs/BG (1).svg";
import demoHead from "../../assets/imgs/blankInpurHEader.svg";
import dummieTable from "../../assets/imgs/Section.svg";

export const CustomRecieptPreview = () => {
  return (
    <div className="flex justify-center items-center flex-col">
      <div className="w-[70%]  h-fit 2xl:h-[60vh] shadow-sm  bg-white flex flex-col items-center p-3 px-5">
        <div className="w-full border-b-[1px] pb-4">
          <img src={demoHead} alt="" />
        </div>
        <div className="flex justify-end w-full pt-2">
          <h1 className="text-[10px] text-[#232323] text-bold font-[700]">
            VD5477956P
          </h1>
        </div>
        <div className="flex justify-end items-end w-full pt-2">
          <div className="w-[60%] flex justify-between items-center gap-3">
            <div className="bg-[#EEEEEE] rounded-[8px] h-[11px] w-[70px]"></div>
            <div>
              <p className="text-[#9B9697] text-[9px] "> Seller</p>
            </div>
          </div>
        </div>
        <div className="flex justify-end items-end w-full pt-2">
          <div className="w-[60%] flex justify-between items-center">
            <div className="bg-[#EEEEEE] rounded-[8px] h-[6px] w-[70px]"></div>
            <div>
              <p className=" text-[9px] text-[#232323] font-[700]">
                {" "}
                Mooi Gadgets
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-end items-end w-full pt-2">
          <div className="w-[60%] flex justify-between gap-3 items-center">
            <div className="bg-[#EEEEEE] rounded-[8px] h-[6px] w-[91px]"></div>
            <div>
              <p className=" text-[9px] text-[#232323] font-[700]">
                {" "}
                mootigadgets@gmail.com
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-end items-end w-full pt-2">
          <div>
            <p className=" text-[9px] text-[#232323] font-[700] ">
              {" "}
              +2348048740391
            </p>
          </div>
        </div>
        <div className="w-full border-b-[1px] pb-4 pt-5">
          <img src={dummieTable} alt="" className="w-full " />
        </div>
        <div className="flex flex-col w-full gap-4 pt-4">
          <div className="flex justify-end items-end w-full pt-2">
            <div className="w-[30%] flex justify-between gap-4 items-center">
              <div className="bg-[#EEEEEE] rounded-[8px] h-[11px] w-[70px]"></div>
              <div className="bg-[#EEEEEE] rounded-[8px] h-[6px] w-[70px]"></div>
            </div>
          </div>
          <div className="flex justify-end items-end w-full pt-2">
            <div className="w-[30%] flex justify-between gap-4 items-center">
              <div className="bg-[#EEEEEE] rounded-[8px] h-[11px] w-[70px]"></div>
              <div className="bg-[#EEEEEE] rounded-[8px] h-[6px] w-[70px]"></div>
            </div>
          </div>
        </div>
        <div className="w-full flex items-center justify-center pt-7 2xl:pt-10">
          <div>
            <p className="text-[#9B9697] text-[9px] ">
              {" "}
              Thank you for your petronage
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
