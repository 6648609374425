import React, { useEffect, useState } from "react";
import MajorTitle from "../../../../components/MajorTitle";
import monitorIcon from "../../../../assets/imgs/monitor.svg";
import InputFile from "../../../../components/drag-and-drop";
import TextArea from "../../../../components/TextArea";
import Textfield from "../../../../components/Textfield";
import Button from "../../../../components/Button";
import profilePicture from "../../../../assets/profilePictures/Avatar.svg";
import badge from "../../../../assets/imgs/Badge.svg";
import container from "../../../../assets/imgs/Container.svg";
import dumieBtns from "../../../../assets/imgs/Actions.svg";
import { useNavigate } from "react-router-dom";
import { getFromStore, saveInStore } from "../../../../utils/helpers";
import useService from "../../../../service/useService";
import { toast } from "react-toastify";
import { PuffLoader } from "react-spinners";
import createAxiosInstance from "../../../../service";
import AWS from "aws-sdk";
import { ClockLoader } from "react-spinners";
import ParentComponent from "../../../../components/number-input";

export const BuyerLinkGenration = () => {
  const [businessName, setBusinessName] = React.useState("");
  const [itemName, setItemName] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [buyeritemImage, setBuyerItemImage] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [pageLoad, setPageLoad] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedNumber, setSelectedNumber] = React.useState(1);

  const navigate = useNavigate();
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  const s3 = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME },
  });

  const details = getFromStore("vendyz_user");
  const userID = details.id;
  const userType = details.user_type;

  const basUrl = process.env.REACT_APP_ODER_BASE_URL;
  const Service = createAxiosInstance(basUrl);

  // console.log(details, "for payment");

  const uerName = `${details.first_name} ${details.last_name}`;
  // console.log(uerName);

  const uploadFileDocs = async () => {
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: `${buyeritemImage.name}`,
      Body: buyeritemImage,
    };
    try {
      setBtnDisabled(true);
      setLoading(true);
      const uploadResult = await s3.upload(params).promise();

      if (uploadResult) {
        try {
          const body = {
            user_id: userID,
            buyer_name: uerName,
            item_image_url: uploadResult.Location,
            item_name: itemName,
            item_description: description,
            order_amount: Number(amount),
            merchant_business_name: businessName,
            quantity: selectedNumber,
          };

          const response = await Service.post(`/buyer/orders`, body);

          if (response.status === 201) {
            setLoading(false);
            setBtnDisabled(false);
            saveInStore("generatedOrder", response.data.data.order_details);
            navigate("/generate-link/create-link");
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
          setBtnDisabled(false);

          if (error.message === "timeout exceeded") {
            toast.error("Timeout exceeded");
          } else if (error.message === "Network Error") {
            toast.error("Network error");
          } else if (
            error.response.data.message ===
            "Untreated Order Payment With The Same Name Already Exist"
          ) {
            toast.error(
              "Untreated Order Payment With The Same Name Already Exist"
            );
          }
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    description !== "" &&
    businessName !== "" &&
    amount !== "" &&
    buyeritemImage !== null
      ? setBtnDisabled(false)
      : setBtnDisabled(true);
  }, [description, businessName, amount, buyeritemImage]);

  if (pageLoad) {
    return (
      <div className="h-72 w-full flex justify-center pt-44">
        <PuffLoader color="#5271FF" speedMultiplier={0.8} />
      </div>
    );
  }

  return (
    <section className="h-full">
      <div>
        <MajorTitle title={"New escrow transaction"} />
      </div>
      <div className="px-4 h-full w-full">
        <div className="w-full flex justify-between h-[calc(100%-50px)] ">
          <div className="w-[45%] bg-white h-full overflow-auto">
            <div className="p-4 pt-7">
              <div>
                <h1 className=" text-[#232323] text-[16px] font-[700]">
                  Order details
                </h1>
              </div>

              <div className=" h-full">
                <div className=" flex flex-col justify-between  gap-6 h-full">
                  <div className="">
                    <p className="text-[#5C5959] text-sm font-sm pb-2">
                      Item image
                    </p>
                    <div>
                      <InputFile
                        selectedFile={buyeritemImage}
                        setSelectedFile={setBuyerItemImage}
                      />
                    </div>
                    <div className="flex flex-col gap-4 pt-3">
                      <Textfield
                        type={"text"}
                        placeholder={"Item name"}
                        onChange={(e) => setItemName(e.target.value)}
                        value={itemName}
                        labal={"Item name"}
                        labelStyle={"text-[14px] font-[400] text-[#5C5959]"}
                      />
                      <TextArea
                        description={description}
                        setDescription={setDescription}
                        counter
                        label={"Description"}
                        maxiumum={1000}
                        counterMaxLength={1000}
                        placeholder={"Between 4 - 1000 characters"}
                      />
                      <ParentComponent
                        selectedNumber={selectedNumber}
                        setSelectedNumber={setSelectedNumber}
                      />
                      <Textfield
                        moneyIcon={true}
                        type={"number"}
                        placeholder={"Minimum of 100 NGN"}
                        onChange={(e) => setAmount(e.target.value)}
                        value={amount}
                        labal={"Amount:"}
                        labelStyle={"text-[14px] font-[400] text-[#5C5959]"}
                      />
                      <Textfield
                        labelStyle={"text-[14px] text-[#5C5959] font-[400]"}
                        type={"text"}
                        labal={"Vendor business name"}
                        placeholder={"who are you buying from"}
                        value={businessName}
                        onChange={(e) => {
                          setBusinessName(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="mb-6  pt-8  ">
                    <div className="flex gap-4 justify-center w-full  ">
                      <div className="w-1/2">
                        <Button
                          size="fullwidth"
                          variant="white"
                          touches={"h-[44px]"}
                          onClick={() => navigate("/")}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="w-1/2">
                        <Button
                          variant="authBlue"
                          size="fullwidth"
                          touches={"h-[44px]"}
                          isBtnDisabled={btnDisabled}
                          onClick={() => {
                            uploadFileDocs();
                          }}
                        >
                          {loading ? (
                            <ClockLoader
                              size={"30px"}
                              speedMultiplier={0.8}
                              color="#fafafa"
                            />
                          ) : (
                            " Generate and share link"
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* right */}
          <div className=" w-[50%] bg-[#FAFAFA] rounded-[20px] h-full">
            <div className=" w-full">
              <div className="p-4 pl-6 pt-7 flex justify-between items-center">
                <div>
                  <p className=" text-[#5C5959] text-[16px] font-[500]">
                    Preview
                  </p>
                </div>

                <div className=" rounded-[100px] w-[150px] h-[40px] flex  items-center shadow-sm justify-center gap-2 border-[1px] border-[#F0F0F0] cursor-pointer bg-white ">
                  <div>
                    <img src={monitorIcon} alt="" />
                  </div>
                  <div>
                    <h1 className="text-[#232323] font-semibold text-sm ">
                      Payment page
                    </h1>
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center flex-col">
                <div className="w-[90%]  h-[394.75px] shadow-sm rounded-[10.24px] bg-white flex flex-col items-center ">
                  <div className="pt-20">
                    <img src={badge} alt="" className="w-24" />
                  </div>
                  <div className="pt-5">
                    <img src={profilePicture} alt="" className="w-10" />
                  </div>
                  <div>
                    <h1 className="text-[#232323] font-semibold text-[6px] pt-1">
                      Emmanuel Israel wants to pay
                    </h1>
                  </div>
                  <div className="py-2">
                    <div className="w-[104.8px] h-[9px] rounded-[8px] bg-[#EEEEEE]"></div>
                  </div>
                  <div className="pt-3">
                    <img src={container} alt="" />
                  </div>
                  <div>
                    <img src={dumieBtns} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
