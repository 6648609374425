import React, { useEffect } from "react";
import logo from "../../../../assets/imgs/Logo.svg";
import Textfield from "../../../../components/Textfield";
import Button from "../../../../components/Button";
import { useNavigate } from "react-router-dom";
import createAxiosInstance from "../../../../service";
import { toast } from "react-toastify";
import { ClockLoader } from "react-spinners";
// import axios from "axios";

export const CreateAcctForBuyer = () => {
  const navigate = useNavigate();

  const basUrl = process.env.REACT_APP_BASE_URL;
  const Service = createAxiosInstance(basUrl);

  // get input fields

  // get input fields
  const [confirmPassword, updateConfirmPassword] = React.useState("");
  const [email, updateEmail] = React.useState("");
  const [password, updatePassword] = React.useState("");
  // button state
  const [disabledbtn, setDisabledBtn] = React.useState(true);

  // error messages
  const [emailError, setEmailError] = React.useState("");
  const [passswordError, setPassswordError] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState("");

  // error icon
  const [showErrorEmail, setShowErrorEmail] = React.useState(false);
  const [showErrorPassword, setShowErrorPassword] = React.useState(false);
  const [showErrorConfirmPassword, setShowErrorConfirmPassword] =
    React.useState(false);
  const [textFieldDisabled, setTextFieldDisabled] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  //  monitor input fields
  useEffect(() => {
    function checkField() {
      if (email !== "" && password !== "" && confirmPassword !== "") {
        setDisabledBtn(false);
      } else {
        setDisabledBtn(true);
      }
    }
    checkField();
  }, [email, password, confirmPassword]);

  const validate = async (e) => {
    e.preventDefault();
    const userInfo = {
      userConfirmPasword: "",
      userEmail: "",
      userPasEsword: "",
      userType: "buyer",
    };

    // Email regex
    let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!regEmail.test(email)) {
      setEmailError("Email must be valid");
      setShowErrorEmail(true);
    } else {
      setEmailError("");
      userInfo.userEmail = email;
      setShowErrorEmail(false);
    }

    // Password validation
    let regPassword = /^(?=.*\d).{6,}$/;
    if (!regPassword.test(password)) {
      setPassswordError("Password must a number and atleast 6 characters");
      setShowErrorPassword(true);
    } else {
      setPassswordError("");
      userInfo.userPasEsword = password;
      setShowErrorPassword(false);
    }

    // confirm validation
    if (confirmPassword !== password) {
      setConfirmPasswordError("Password must match");
      setShowErrorConfirmPassword(true);
    } else {
      userInfo.userConfirmPasword = confirmPassword;
      setConfirmPasswordError("");
      setShowErrorConfirmPassword(false);
    }

    // Check if all fields are valid before navigating
    if (
      userInfo.userConfirmPasword !== "" &&
      userInfo.userEmail !== "" &&
      userInfo.userPasEsword !== ""
    ) {
      const body = {
        email: userInfo.userEmail,
        password: userInfo.userPasEsword,
        password_confirm: userInfo.userConfirmPasword,
        device_hash: "web",
        registration_channel: "web",
        user_type: userInfo.userType,
      };
      try {
        setDisabledBtn(true);
        setLoading(true);
        setTextFieldDisabled(true);
        const response = await Service.post(`/auth/register`, body);
        if (response.status === 201) {
          console.log(response.data);
          const req_token = response.data.data.request_token;
          setLoading(false);
          navigate(`/auth/register/buyer/${userInfo.userEmail}/${req_token}`);
        }
      } catch (error) {
        if (error.message === "Network Error") {
          toast.error("Network error");
        } else if (error.response.data.message === "Email Already Exist") {
          toast.error("Email Already Exist");
        } else {
          console.log(error);
        }
        console.log(error);
        setDisabledBtn(false);
        setLoading(false);
        setTextFieldDisabled(false);
      }
    }
  };

  return (
    <section className="w-full flex justify-center h-full">
      <div>
        <div className="flex justify-center">
          <img src={logo} alt="" className="" />
        </div>
        <div className="pt-2">
          <div className="flex justify-center">
            <h1 className="text-[27px] font-[700] text-[#232323]">
              Create an account
            </h1>
          </div>

          <div className="flex justify-center">
            <p className="text-[#9B9697] text-[16px] text-[400]">
              Please provide your email and choose a password.
            </p>
          </div>
        </div>
        <form className="flex flex-col 2xl:gap-5 gap-3 w-[496px] pt-3  ">
          <Textfield
            labal={"Email"}
            type={"email"}
            value={email}
            onChange={(e) => {
              updateEmail(e.target.value);
            }}
            msg={emailError}
            error={showErrorEmail}
            placeholder={"example@gmail.com"}
            disabled={textFieldDisabled}
            labelStyle={"text-[14px] text-[#5C5959] font-[400] w-full"}
          />
          <Textfield
            labal={"Password"}
            type={"password"}
            onChange={(e) => {
              updatePassword(e.target.value);
            }}
            value={password}
            msg={passswordError}
            error={showErrorPassword}
            placeholder={"********"}
            disabled={textFieldDisabled}
            labelStyle={"text-[14px] text-[#5C5959] font-[400] w-full"}
          />
          <Textfield
            labal={"Confirm Password"}
            type={"password"}
            value={confirmPassword}
            onChange={(e) => {
              updateConfirmPassword(e.target.value);
            }}
            msg={confirmPasswordError}
            error={showErrorConfirmPassword}
            placeholder={"********"}
            disabled={textFieldDisabled}
            labelStyle={"text-[14px] text-[#5C5959] font-[400] w-full"}
          />
          <div className="2xl:pt-8 pt-3 pb-3 ">
            <Button
              isBtnDisabled={disabledbtn}
              variant="authBlue"
              touches={"h-[44px]"}
              onClick={validate}
            >
              {loading ? (
                <ClockLoader
                  size={"30px"}
                  speedMultiplier={0.8}
                  color="#fafafa"
                />
              ) : (
                "Continue"
              )}
            </Button>
            <div className="2xl:pt-5 pt-3 w-full justify-center pb-3">
              <div className="  w-full">
                <p className="text-[#5C5959] text-[400] text-[16px] text-center">
                  Already have an account?
                  <span
                    onClick={() => navigate("/auth")}
                    className="text-[#5271FF] pl-5 cursor-pointer"
                  >
                    Login
                  </span>
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};
