import React from "react";
import bg from "../../assets/imgs/BG.svg";
import notice from "../../assets/imgs/info-circle.svg";

export const ConfirmOrderNotificatioN = () => {
  return (
    <div className="pt-5">
      <div className="relative">
        <div className="">
          <img src={bg} alt="" className="rounded-[100px] w-[500px]" />
        </div>
        <div className="flex gap-2 absolute top-2 left-3">
          <div>
            <img src={notice} alt="" />
          </div>
          <div>
            <h1 className="text-[#232323] ">
              You have until
              <span className="font-bold"> March 17, 12:18 PM GMT .</span>
              to open a dispute.
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};
