import React from "react";
import bg from "../../../assets/imgs/BG.svg";
import notice from "../../../assets/imgs/info-circle.svg";
import { motion, AnimatePresence } from "framer-motion";
import { formatDate, getFromStore } from "../../../utils/helpers";

export const UnConfirmedOrderNotificatioN = ({ orderDet }) => {
  const details = getFromStore("vendyz_user");

  const userType = details.user_type;
  return (
    <AnimatePresence>
      <motion.div
        initial={{ scale: 1, opacity: 0, x: -100, y: 0 }}
        animate={{ scale: 1, opacity: 1, x: 0, y: 0 }}
        transition={{
          duration: 1,
        }}
        exit={{
          scale: 0,
          opacity: 0,
          x: 100,
          y: -20,
        }}
        className="relative"
      >
        <div className="">
          <img src={bg} alt="" className="rounded-[100px] " />
        </div>
        <div className="flex gap-2 absolute top-7 left-3">
          <div>
            <img src={notice} alt="" />
          </div>
          <div>
            <h1 className="text-[#232323] ">
              {" "}
              Please confirm that the order has been delivered before{" "}
              <span className="font-bold">
                {`${formatDate(orderDet.expiry_date)} ${
                  orderDet.expiry_time
                }  GMT.`}
              </span>
              If you do not respond, Vendyz will release the payment to the
              vendor.{" "}
            </h1>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
