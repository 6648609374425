import security from "../../assets/imgs/security-safe.svg";
import walleticon from "../../assets/imgs/wallet.svg";
import atmcard from "../../assets/imgs/card-pos (1).svg";
import { FaNairaSign } from "react-icons/fa6";
import daTa from "../../assets/imgs/arrow-swap.svg";
import fade from "../../assets/imgs/Group.svg";
import arrowLft from "../../assets/imgs/arrow-right.svg";
import Card from "../../components/cards";
import { Smallcard } from "../../components/smallcard";
import RangeSlider from "../../components/RangeSlider";
import ArcDesign from "../../components/Guage";
import { ColorCards } from "../../components/colorCards";
import RangeSliderTop from "../../components/RangesliderTop";
import { RangeText } from "../../components/RangeText";
import MajorTitle from "../../components/MajorTitle";
import { TablE } from "../../components/ReuableTable";
import TableTop from "../../components/TableTop";
import { Outlet, useNavigate } from "react-router-dom";
import useService, { getTokens } from "../../service/useService";
import React, { useEffect, useState } from "react";
import { PuffLoader } from "react-spinners";
import { formatCurrency, getFromStore, saveInStore } from "../../utils/helpers";
import useAuthCheck from "../../hooks/useAuthCheck";
import { toast } from "react-toastify";
import ContainerWithGradient from "../../components/container-with-gradient";
import notice from "../../assets/imgs/info-circle.svg";
import Button from "../../components/Button";
import { Hometable } from "../../components/home-table";

const Homepage = () => {
  const [pageLoad, setPageLoad] = React.useState(true);
  const [pageLoadOne, setPageLoadOne] = React.useState(true);
  const [orderData, setOrderData] = React.useState([]);
  const [ecBalance, setEcBalance] = React.useState("00.00");
  const [walletBalance, setWalletBalance] = React.useState("00.00");
  const [ordersLength, setOrdersLength] = React.useState(0);
  const [activateAccount, setActivateAccount] = React.useState(true);
  const [hasOrders, setOrderStatus] = React.useState(false);
  const [disputeLength, setDisputeLength] = React.useState("");
  const [bvnstatus, setBvnStatus] = React.useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  // const [recentEscrowtranscatalog, setRecentEscrowTranscatalog] = useState([]);
  // const [hasrecentEscrowtranscatalog, setRecentEscrowTranscatalog] = useState([]);

  const navigate = useNavigate();
  const token = getTokens();
  const details = getFromStore("vendyz_user");
  const merchant_id = getFromStore("merchant_id");
  const merchant = getFromStore("merchant-B");
  const userType = details.user_type;
  const baseUrl = process.env.REACT_APP_ODER_BASE_URL;
  const walletUrl = process.env.REACT_APP_WALLET_BASE_URL;
  const walletService = useService(walletUrl);
  const Service = useService(baseUrl);

  // For to get document status
  const docsUrl = process.env.REACT_APP_BASE_URL;
  const userDocsService = useService(docsUrl);

  const vendorDocsUrl = process.env.REACT_APP_VENDOR_BASE_URL;
  const vendorUseService = useService(vendorDocsUrl);

  const userID = details.id;
  const userDetails = token.userInfo;
  const totalWithdrawals = "0";
  const totalTransaction = "0";

  console.log(details);

  const handleOnChange = (value) => {
    const now = new Date();

    const filterOrders = (daysAgo) => {
      const pastDate = new Date(now);
      pastDate.setDate(now.getDate() - daysAgo);
      return orderData.filter((order) => new Date(order.createdAt) >= pastDate);
    };

    let filtered = [];
    switch (value) {
      case "Today":
        const startOfDay = new Date(now);
        startOfDay.setHours(0, 0, 0, 0);
        filtered = orderData.filter(
          (order) => new Date(order.createdAt) >= startOfDay
        );
        break;
      case "Last 3 days":
        filtered = filterOrders(3);
        break;
      case "Last 7 days":
        filtered = filterOrders(7);
        break;
      case "Last Month":
        const oneMonthAgo = new Date(now);
        oneMonthAgo.setMonth(now.getMonth() - 1);
        filtered = orderData.filter(
          (order) => new Date(order.createdAt) >= oneMonthAgo
        );
        break;
      default:
        filtered = orderData;
    }

    setFilteredOrders(filtered);
    setOrdersLength(filtered.length);
  };
  const tableHeadData = [
    {
      id: 1,
      name: "Name",
      amount: "Amount",
      orderid: "OrderID",
      status: "Status",
    },
  ];

  useEffect(() => {
    const paymentLink = getFromStore("payment_link", "local") || null;
    const tokens = getTokens();

    const getPaymenetDetails = async () => {
      setPageLoadOne(true);

      if (paymentLink) {
        try {
          const body = {
            payment_link: paymentLink,
          };
          const response = await Service.post(
            "/buyer/orders/validate/link",
            body
          );

          if (response.status === 201) {
            const orderType = response.data.data.order_details.order_type;
            const userType = tokens.userInfo.user_type;

            if (
              orderType === "VENDOR_ORDER" &&
              userType === "VENDOR_SUPER_ADMIN"
            ) {
              setPageLoadOne(false);
              return;
            } else if (orderType === "VENDOR_ORDER" && userType === "buyer") {
              navigate("/accept-order");
            } else if (orderType === "BUYER_ORDER" && userType === "buyer") {
              setPageLoadOne(false);
              return;
            } else {
              navigate("/accept-order");
            }
          }
        } catch (error) {
          const errmsg = error.response.data.message;
          toast.error(errmsg);
          // removeFromStore("payment_link", "local");
          setPageLoadOne(false);
        }
      } else {
        setPageLoadOne(false);
      }
    };
    getPaymenetDetails();
  }, [navigate]);

  useEffect(() => {
    const getDetails = async () => {
      try {
        const response =
          userType === "buyer"
            ? await Service.get(`/buyer/orders/user/${userID}`)
            : await Service.get(`/vendor/orders/vendor/${merchant_id}`);
        if (response.status === 200) {
          const data = response.data.data;
          setOrderData(data.data);
          setOrdersLength(data.data.length);
          // setFilteredOrders(data);
          setFilteredOrders(data.data);

          try {
            const response =
              userType === "buyer"
                ? await Service.get(`/buyer/disputes/all/${userID}`)
                : await Service.get(`/vendor/disputes/all/${merchant_id}`);
            if (response.status === 200) {
              const data = response.data.data;
              setDisputeLength(data.length);
            }
          } catch (error) {
            console.log(error);
          }

          try {
            const accountStatus =
              userType === "buyer"
                ? await userDocsService.get(`/owner/docs/user/${userID}`)
                : await vendorUseService.get(
                    `/merchant/owner/docs/merchant/${merchant_id}`
                  );
            if (accountStatus.status === 200) {
              const addressUrl = accountStatus.data.data.proof_of_address_url;
              const idDocs = accountStatus.data.data.identification_doc_url;
              if (addressUrl === null && idDocs === null) {
                setActivateAccount(true);
              } else {
                setActivateAccount(false);
              }
              // setPageLoad(false);
            }
          } catch (error) {
            console.log(error);
          }

          try {
            const getAllUsers = await walletService.get("/wallet/user/types");
            if (getAllUsers.status === 200) {
              if (userType === "buyer") {
                try {
                  const balance = await walletService.get(
                    `/wallet/s/all?user_id=${userID}&user_type=USER`
                  );
                  if (balance.status === 200) {
                    saveInStore("walletDetails", balance);
                    const walletBalance =
                      balance.data.data[0].available_balance;
                    const ecBalance = balance.data.data[1].available_balance;
                    setEcBalance(ecBalance);
                    const bvn = balance.data.data[0].bvn;
                    setBvnStatus(bvn);
                    setWalletBalance(walletBalance);
                    setPageLoad(false);
                  }
                } catch (error) {
                  console.log(error);
                }
              } else {
                try {
                  const balance = await walletService.get(
                    `/wallet/s/all?user_id=${userID}&merchant_user_id=${merchant.merchant_id}&merchant_business_id=${merchant.id}&user_type=MERCHANT`
                  );
                  if (balance.status === 200) {
                    console.log(balance, "wallet balance");
                    saveInStore("walletDetails", balance);
                    const walletBalance =
                      balance.data.data[0].available_balance;
                    const ecBalance = balance.data.data[1].available_balance;
                    setEcBalance(ecBalance);
                    const bvn = balance.data.data[0].bvn;
                    setBvnStatus(bvn);
                    setWalletBalance(walletBalance);
                    setPageLoad(false);
                  }
                } catch (error) {
                  console.log(error);
                }
              }
            }
          } catch (error) {
            console.log(error);
          }

          if (data.data.length === 0) {
            setOrderStatus(false);
          } else {
            setOrderStatus(true);
          }
        }
      } catch (error) {
        if (error.message === "timeout exceeded") {
          toast.error("Timeout exceeded");
        } else if (error.message === "Network Error") {
          toast.error("Network error");
        }
      }
    };

    getDetails();
  }, [merchant_id, userID, userType]);

  const isLoading = useAuthCheck();

  if (isLoading) {
    return (
      <div className="h-96 w-full flex justify-center pt-44">
        <PuffLoader color="#5271FF" speedMultiplier={0.8} />
      </div>
    );
  }

  if (pageLoad || pageLoadOne) {
    return (
      <div className="h-96 w-full flex justify-center pt-44">
        <PuffLoader color="#5271FF" speedMultiplier={0.8} />
      </div>
    );
  }

  return (
    <section>
      {userType !== "buyer" ? (
        <div>
          {activateAccount && bvnstatus === null ? (
            <ContainerWithGradient height={"h-[90px]"} width={"w-full"}>
              <div className="flex items-center justify-between w-full px-6 ">
                <div className="flex gap-3">
                  <img src={notice} alt="/" />

                  <div className="flex flex-col gap-2">
                    <div className="font-[700] text-[20px] leading-[25.5px] text-[#232323]">
                      Activate your business account
                    </div>
                    <div className="font-[400] text-[1rem] leading-[25.5px] text-[#232323]">
                      {userType === "buyer"
                        ? "Activate your account by providing your Bank Verification Number (BVN)."
                        : "  Activate your business account by providing your Bank Verification Number (BVN), proof of address, and ID card."}
                    </div>
                  </div>
                </div>

                <div>
                  <Button
                    variant="blue"
                    size="small"
                    className="w-[250px] h-[50px] bg-[#5271FF] text-[#FFFFFF] font-bold"
                    onClick={() => navigate("/account/documents")}
                  >
                    {userType === "buyer" ? "Set up BVN" : "Activate account"}
                  </Button>
                </div>
              </div>
            </ContainerWithGradient>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div>
          {bvnstatus == null ? (
            <ContainerWithGradient height={"h-[90px]"} width={"w-full"}>
              <div className="flex items-center justify-between w-full px-6 ">
                <div className="flex gap-3">
                  <img src={notice} alt="/" />

                  <div className="flex flex-col gap-2">
                    <div className="font-[700] text-[20px] leading-[25.5px] text-[#232323]">
                      Activate your business account
                    </div>
                    <div className="font-[400] text-[1rem] leading-[25.5px] text-[#232323]">
                      {userType === "buyer"
                        ? "Activate your account by providing your Bank Verification Number (BVN)."
                        : "  Activate your business account by providing your Bank Verification Number (BVN), proof of address, and ID card."}
                    </div>
                  </div>
                </div>

                <div>
                  <Button
                    variant="blue"
                    size="small"
                    className="w-[250px] h-[50px] bg-[#5271FF] text-[#FFFFFF] font-bold"
                    onClick={() => navigate("/account/documents")}
                  >
                    {userType === "buyer" ? "Set up BVN" : "  Activate account"}
                  </Button>
                </div>
              </div>
            </ContainerWithGradient>
          ) : (
            ""
          )}
        </div>
      )}

      <div className="flex w-full justify-center pt-3">
        <div className="w-full ">
          <div className="leading-[40px] ">
            <div className="pb-8">
              <MajorTitle
                title={`Hi ${userDetails.first_name},`}
                subtitle={
                  "Until satisfaction with an order, your money is safe."
                }
              />
            </div>

            <div className="flex  justify-between w-full   gap-4  ">
              {/* left */}
              <div className=" w-[60%]">
                <div className="flex gap-[32px] 2xl:gap-24 justify-between w-full">
                  <Card
                    iconBg={"bg-[#5271FF]"}
                    bg={"bg-[#F6F6F6]"}
                    card={"escrowBalance"}
                    fade={fade}
                    icon={security}
                    balance={formatCurrency(ecBalance)}
                    tittle={"Escrow balance"}
                    alertMessg={
                      "Total availability funds to be released upon satisfaction. You can't withdraw this."
                    }
                  />
                  <Card
                    iconBg={"bg-[#E5EAFF]"}
                    bg={"bg-[#F3F5FD]"}
                    card={""}
                    icon={walleticon}
                    balance={formatCurrency(walletBalance)}
                    tittle={"Wallet balance"}
                    alertMessg={
                      "Total availability funds to be released upon satisfaction. You can't withdraw this."
                    }
                  />
                </div>
                {/* <div className="pt-14">
                  {hasOrders ? (
                    <div className="rounded-[25px] py-[42px] px-[18px] border-[1px] ">
                      <TableTop
                        text={"Orders"}
                        btText={"View more"}
                        icon={arrowLft}
                        onClick={() => navigate("/orders")}
                      />

                      <div className="w-full pt-10">
                        <TablE
                          tHeaders={tableHeadData}
                          tRows={filteredOrders}
                          variant={"index"}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="rounded-[25px] py-[42px] px-[18px] border-[1px] h-[446px] ">
                      <TableTop
                        text={"Transactions"}
                        btText={"View more"}
                        icon={arrowLft}
                        onClick={() => navigate("/orders")}
                      />
                      <div className="flex justify-center items-center h-full">
                        <div className="flex flex-col items-center justify-center">
                          <h1 className="text-[1rem] font-[500] text-[#232323]">
                            No transaction history
                          </h1>
                          <p className="text-[14px] font-[400] text-[#9B9697]">
                            It looks like you haven’t made any transactions yet.{" "}
                            <br /> Start making transactions to see your history
                            here.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div> */}

                <Hometable
                  filteredOrders={filteredOrders}
                  hasOrders={hasOrders}
                />
              </div>

              {/* right */}
              <div className="flex flex-col gap-9 px-4  w-[40%] ">
                <div className="w-full flex justify-between ">
                  <Smallcard
                    icon={atmcard}
                    transactionType={"Withdrawals"}
                    amount={totalWithdrawals}
                    moneySign={<FaNairaSign size={"20px"} color="black" />}
                    sign
                  />
                  <Smallcard
                    icon={daTa}
                    transactionType={"Transaction"}
                    amount={totalTransaction}
                    moneySign={""}
                  />
                </div>
                <div className="w-full  rounded-[25px] py-[32px] px-[30px] border-[1px]">
                  <div className="flex-col flex gap-4">
                    <RangeSliderTop
                      onchamge={handleOnChange}
                      title={"Orders"}
                      btnTitle={"Withdraw"}
                      figure={ordersLength}
                    />
                  </div>
                  <div className="flex justify-between items-center pt-7 ">
                    <RangeText title={"Delivered"} value={0} />
                    <div>
                      <div className="bg-indigo-50 h-[42px] w-[258px] rounded-[100px] ">
                        <RangeSlider
                          min={0}
                          max={100}
                          value={0}
                          rangeBg={"bg-indigo-500"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between items-center pt-2 ">
                    <RangeText title={"Disputes"} value={disputeLength} />
                    <div>
                      <div className="bg-[#FBE3CB] h-[42px] w-[258px] rounded-[100px] ">
                        <RangeSlider
                          min={0}
                          max={100}
                          value={0}
                          rangeBg={"bg-[#FF9C33]"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full  rounded-[25px] py-[32px] px-[30px] border-[1px]">
                  <h1 className="text-[16px] text-[#5C5959] font-semibold">
                    Orders breakdown
                  </h1>
                  <div className="pt-4 flex justify-center">
                    <ArcDesign range={0} value={0} />
                  </div>
                  <div className="flex justify-center">
                    <div className="w-full 2xl:w-[80%] pt-10 flex justify-between">
                      <ColorCards
                        delivered={0}
                        alignItems={"justify-start"}
                        title={"Delivered"}
                        bg={"bg-[#F3F5FD]"}
                      />
                      <ColorCards
                        delivered={0}
                        alignItems={"justify-end"}
                        title={"Disputes"}
                        bg={"bg-[#FBE3CB]"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Homepage;
