import { formatDate } from "../../utils/helpers";

export function TruncateDate({ data, intTl = false, touches }) {
  const newdata = data.substring(0, 10);
  const addYear = data.substring(0, 4);

  if (!intTl) {
    return <p className="text-[#232323] text-[14px] font-[400]">{newdata}</p>;
  } else {
    return (
      <p className={`text-[#232323] text-[14px] font-[400] ${touches}`}>
        {`${formatDate(newdata)}, ${addYear}`}
      </p>
    );
  }
}
