import React from "react";
import { Select } from "antd";

const AntDropdown = ({ onChange }) => (
  <Select
    showSearch
    style={{
      width: "143px",
      height: "44px",
      backgroundColor: "white",
      cursor: "pointer",
    }}
    placeholder="All orders"
    optionFilterProp="label"
    onChange={onChange}
    onSearch={(value) => console.log("search:", value)}
    options={[
      {
        value: "Last 3 days",
        label: "Last 3 days",
      },
      {
        value: "Last 7 days",
        label: "Last 7 days",
      },
      {
        value: "Last Month",
        label: "Last Month",
      },
    ]}
    className="custom-ant-dropdown"
  />
);

export default AntDropdown;
