import React, { useEffect } from "react";
import { HiOutlineXMark } from "react-icons/hi2";
import linkLogo from "../../../../assets/imgs/Animation.svg";
import copy from "../../../../assets/imgs/copy.svg";
import Button from "../../../../components/Button";
import MajorTitle from "../../../../components/MajorTitle";
import { useNavigate } from "react-router-dom";
import {
  convertTimeToGMT,
  formatCurrency,
  formatDate,
  getFromStore,
} from "../../../../utils/helpers";

export const VendorLinkConfirm = () => {
  const [showCopied, setShowCopied] = React.useState(false);
  const [orderDetails, setOrderDetails] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const generatedOrder = getFromStore("generatedOrder") || null;

    if (generatedOrder) {
      setOrderDetails(generatedOrder);
      setLoading(false);
    }
  }, []);

  console.log(orderDetails);

  function copyToCipboard(value) {
    const result = navigator.clipboard.writeText(value);

    result.then(() => {
      setShowCopied(true);
      setTimeout(() => {
        setShowCopied(false);
      }, [1500]);
    });
  }

  if (!orderDetails) {
    return <></>;
  }

  return (
    <div className="  h-full w-full bg-[#F3F5FD] rounded-tl-[50px]  overflow-auto min-h-0">
      <div className="pt-12  pl-10">
        <div
          onClick={() => {
            navigate("/generate-link");
          }}
          className=" rounded-[100px] w-[101px] h-[40px] flex  items-center shadow-sm justify-center gap-2 border-[1px] border-[#F0F0F0] cursor-pointer  bg-white"
        >
          <div>
            <HiOutlineXMark color="#9B9697" size={25} />
          </div>
          <div>
            <h1 className="text-[#5C5959] font-semibold text-sm">Close</h1>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center px-4 flex-col  2xl:pt-12 ">
        <div className="">
          <img src={linkLogo} alt="" />
        </div>
        <div className=" py-3 2xl:py-6">
          <h1 className="text-[24px] text-[#232323] font-extrabold ">
            Payment link created
          </h1>
        </div>
        <div className="">
          <p className="text-sm text-[#9B9697] tracking-wide">
            Your payment link for
            <span className=" px-2 bg text-[#232323] text-[16px] font-[700]">
              {formatCurrency(orderDetails.order_amount)}
            </span>
            request was successfully created.
          </p>
        </div>

        <div className="pt-10">
          <div className="w-[592px] py-[14px] rounded-[20px] bg-white flex justify-between items-center px-4">
            <div>
              <p className="text-[#9B9697] w-[70%] text-sm break-words break-all">
                {orderDetails.payment_link}
              </p>
            </div>

            <div
              onClick={() => copyToCipboard(orderDetails.payment_link)}
              className="cursor-pointer flex items-center relative"
            >
              <div className="w-[30px] h-[30px]">
                <img src={copy} alt="" className="w-full h-ful" />
              </div>
              <div>
                <p className="text-[#5271FF] text-[12px] pl-1">Copy</p>
              </div>
              {showCopied && (
                <div className="text-sm text-bold absolute -bottom-8 tracking-wider text-white w-[80px]">
                  <p className="text-white  bg-indigo-400 rounded-lg py-1 px-3  w-full">
                    Copied!
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="pt-3">
            <p className="text-sm text-[#9B9697] tracking-wide">
              This link will expire
              <span className="pl-3 bg text-[#232323] text-[14px] font-[700]">
                {formatDate(orderDetails.expiry_date)}
                {orderDetails.expiry_time} GMT
              </span>
            </p>
          </div>
        </div>
        <div>
          <div className="w-full 2xl:pt-16  pt-3 pb-5">
            <div className="flex gap-4 justify-center w-[592px] ">
              <div className="w-1/2">
                <Button size="fullwidth" variant="white" touches={"h-[44px]"}>
                  share link
                </Button>
              </div>
              <div className="w-1/2">
                <Button
                  onClick={() => {
                    navigate("/generate-link");
                  }}
                  variant="authBlue"
                  size="fullwidth"
                  touches={"h-[44px]"}
                >
                  Generate new link
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
