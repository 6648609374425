import React from "react";
import MajorTitle from "../../components/MajorTitle";
import AccountLinkNavBar from "../../components/Accounts-navbar";
import { Outlet } from "react-router-dom";
import useAuthCheck from "../../hooks/useAuthCheck";
import { PuffLoader } from "react-spinners";

const AccountPage = () => {
  const isLoading = useAuthCheck();

  if (isLoading) {
    return (
      <div className="h-72 w-full flex justify-center pt-44">
        <PuffLoader color="#5271FF" speedMultiplier={0.8} />
      </div>
    );
  }

  return (
    <section>
      <MajorTitle
        space={"pt-3"}
        title={"Account settings"}
        subtitle={"Manage your profile here."}
      />
      <AccountLinkNavBar />
      <Outlet />
    </section>
  );
};

export default AccountPage;
