import Axios from "axios";
import { cloneDeep } from "lodash";
import * as appHelpers from "../utils/helpers";
import { getTokens, updateToken } from "./useService";

const createAxiosInstance = (baseUrl) => {
  const axiosInstance = Axios.create({
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json",
    },
  });

  const appendToken = async (config) => {
    const clonedConfig = cloneDeep(config);
    const token = appHelpers.getFromStore("vendyz_REFTK");
    clonedConfig.headers.bearerauth = `${token ? token : ""}`;
    return clonedConfig;
  };

  axiosInstance.interceptors.request.use(appendToken);

  const indicateActive = async (res) => {
    if (res.status === 200 || res.status === 201) {
      const token = getTokens();
      if (token) {
        const nToken = updateToken({
          expiresIn: token.expiresIn,
          accessToken: token.accessToken,
          refreshToken: token.refreshToken,
          partial: true,
        });
      }
    } else if (res.status === 401) {
      const ev = new Event("unAuthorize");
      window.dispatchEvent(ev);
    }

    return res;
  };

  axiosInstance.interceptors.response.use(indicateActive, (err) => {
    const errdata = err.response ? err.response : err;
    indicateActive(errdata);
    throw err;
  });

  return axiosInstance;
};

export default createAxiosInstance;
