import React from "react";
import { IndexOrdersNav } from "../home-Nav";

const TableTop = ({
  text,
  onClick,
  btText,
  icon,
  showOrders,
  setShowOrders,
  setShowAcceptedOrders,
  showAccepted,
}) => {
  return (
    <div className="flex justify-between items-center px-6  ">
      <div className="">
        <h1 className="text-[16px] text-[#5C5959] font-semibold">{text}</h1>
      </div>
      <IndexOrdersNav
        setShowAcceptedOrders={setShowAcceptedOrders}
        setShowOrders={setShowOrders}
        showAccepted={showAccepted}
        showOrders={showOrders}
      />

      <div
        onClick={onClick}
        className="flex items-center rounded-[100px] justify-center w-[119px] h-[36px] gap-2 hove:border-[#F0F0F0] hover:border-[1px] hover:bg-[#F6F6F6] hover:cursor-pointer hover:scale-125 transition duration-300"
      >
        <h1 className="text-sm text-[#5C5959] ">{btText}</h1>
        <div>
          <img src={icon} alt="" />
        </div>
      </div>
    </div>
  );
};

export default TableTop;
