import React from "react";

export const Smallcard = ({
  icon,
  amount,
  transactionType,
  moneySign,
  sign,
}) => {
  return (
    <div className="flex justify-between w-[45%] ">
      <div className=" p-3 rounded-[25px] border-[1px] w-full">
        <div className="flex flex-col w-full ">
          <div className="flex p-2 gap-2">
            <div className="h-[40px] w-[40px] rounded-full flex  justify-center px-[12px]  py-[9px]  bg-[#F4F4F4]">
              <img src={icon} alt="" className=" w-full" />
            </div>
            <div className=" ">
              <p className="text-sm text-[#9B9697]">Total</p>
              <p className="text-sm text-[#9B9697]">{transactionType}</p>
            </div>
          </div>
          <div className="flex items-center pl-7">
            <span>{sign ? moneySign : ""}</span>
            <h1 className="text-[24px] font-extrabold">{amount}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};
