import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import arrowRight from "../../../assets/imgs/arrow-right (2).svg";
import decoration from "../../../assets/imgs/authDecor.svg";

// import blueLocationIcon from "../../../assets/imgs/location blue.svg";
import blueProfileIcon from "../../../assets/imgs/frame.svg";
import Button from "../../../components/Button";
import Textfield from "../../../components/Textfield";
import logo from "../../../assets/imgs/Logo.svg";

import createAxiosInstance from "../../../service";
import { InternationalPhone } from "../../../components/international-phone-input";
import { toast } from "react-toastify";
import { ClockLoader } from "react-spinners";

export const JoinAsBuyer = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const Service = createAxiosInstance(baseUrl);
  const params = useParams();
  const navigate = useNavigate();

  const [btnDisabled, setBtnDisabled] = React.useState(true);
  const [emailErrormsg, setEmailErrormsg] = React.useState("");
  const [showErrorEmailIcon, setShowErrorEmailIcon] = React.useState(false);
  const [fullNameErrorIcon, setFullNameErrorIcon] = React.useState(false);
  const [fulNameErrMsg, setfulNameErrMsg] = React.useState("");
  const [fullName, setFullName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [textFieldDisabled, setTextFieldDisabled] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userInfo = {
      Fullname: "",
      email: "",
      phone: "",
    };

    if (fullName === "") {
      setFullNameErrorIcon(true);
      setfulNameErrMsg("must not be empty and ");
      userInfo.Fullname = "";
    } else {
      setFullNameErrorIcon(false);
      setfulNameErrMsg("");
      userInfo.Fullname = fullName;
    }
    // Email regex
    let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!regEmail.test(email)) {
      setEmailErrormsg("Email must be valid");
      setShowErrorEmailIcon(true);
    } else {
      setEmailErrormsg("");
      userInfo.email = email;
      setShowErrorEmailIcon(false);
    }

    if (userInfo.email !== "" && userInfo.Fullname !== "") {
      const body = {
        email: userInfo.email,
        phone: phone,
        full_name: userInfo.Fullname,
        user_type: params.type,
      };

      try {
        setIsLoading(true);
        setBtnDisabled(true);
        setTextFieldDisabled(true);
        const response = await Service.post(`/wait/list`, body);
        if (response.status === 201) {
          toast.success("Added to wait list");
          setFullName("");
          setEmail("");
          setPhone("");
          setTextFieldDisabled(false);
          setTimeout(function () {
            navigate("/waitlist");
          }, 2000);
        }
        setIsLoading(false);
        setBtnDisabled(false);
      } catch (error) {
        console.log(error);
        const errMsg = error.response.data.message;
        toast.error(errMsg);

        console.log(errMsg);
        setIsLoading(false);
        setBtnDisabled(false);
      }
    }
  };

  useEffect(() => {
    if (fullName !== "" && email !== "" && phone !== "") {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [fullName, email, phone]);

  return (
    <section className="w-full h-full">
      <div className="flex pt-7 h-[calc(100%-10px)] gap-5 ">
        <div className="hidden md:block w-[35%] bg-[#FAFAFA] rounded-t-[20px] relative px-3  2xl:px-0  2xl:w-[30%]">
          <nav className="flex justify-center pt-16 z-50  ">
            <ul className="flex justify-center flex-col lg:gap-9 2xl:gap-14">
              <li className={`text-[#5271FF] pl-5`}>
                <div className="md:flex md:flex-col  lg:flex  lg:flex-row gap-2 cursor-pointer ">
                  <div>
                    {/* <ProfileIcon
                      active={pathname === "/auth/buyer" ? true : false}
                    /> */}
                    <img src={blueProfileIcon} alt="" />
                  </div>
                  <div>
                    <h1 className=" text-bold font-[500] text-lg">
                      Provide your details
                    </h1>
                    <p className=" font-[500] text-sm">
                      Provide an email and your full name
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </nav>
          <div className="absolute bottom-0 right-0 w-[60%]">
            <img src={decoration} alt="" className="w-full" />
          </div>
        </div>

        <div className="2xl:w-[70%] lg:w-[60%] w-full h-full overflow-auto px-3  md:px-0">
          <section>
            <div className=" hidden md:flex justify-center">
              <img src={logo} alt="" className="" />
            </div>
            <div className="pt-2">
              <div className="flex justify-center">
                <h1 className="text-[27px] font-[700] text-[#232323] text-center md:text-left">
                  Contact information
                </h1>
              </div>

              <div className="flex justify-center">
                <p className="text-[#9B9697] text-[16px] text-center text-[400] px-3 md:px-0">
                  This information verifies you and lets us reach out to you.
                </p>
              </div>
              {/* form */}
              <div className="flex w-full justify-center items-center">
                <form className="pt-7  flex-col flex items-center justify-center md:w-[80%]">
                  <div className="md:w-[500px] w-full flex-col flex gap-8 ">
                    <div className="flex items-center flex-col justify-center gap-4  w-full 2xl:gap-8">
                      <div className="w-full">
                        <Textfield
                          type={"text"}
                          labal={"Full name"}
                          placeholder={"John Doe"}
                          onChange={(e) => {
                            setFullName(e.target.value);
                          }}
                          value={fullName}
                          msg={fulNameErrMsg}
                          error={fullNameErrorIcon}
                          disabled={textFieldDisabled}
                          labelStyle={
                            "text-[14px] text-[#5C5959] font-[400] w-full"
                          }
                        />
                      </div>

                      <div className="text-[14px] text-[#5C5959] font-[400] w-full">
                        <div className="">Phone number</div>
                        <InternationalPhone
                          backgroundColor={"white"}
                          phone={phone}
                          setPhone={setPhone}
                        />
                      </div>

                      <div className="w-full">
                        <Textfield
                          type={"email"}
                          labal={"Email"}
                          placeholder={"example@gmail.com"}
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          error={showErrorEmailIcon}
                          msg={emailErrormsg}
                          disabled={textFieldDisabled}
                          labelStyle={
                            "text-[14px] text-[#5C5959] font-[400] w-full"
                          }
                        />
                      </div>
                    </div>

                    <div>
                      <div className="2xl:pt-5 pt-3 pb-10 2xl:pb-0 ">
                        <Button
                          isBtnDisabled={btnDisabled}
                          touches={"h-[44px]"}
                          onClick={handleSubmit}
                          variant="authBlue"
                        >
                          {isLoading ? (
                            <ClockLoader
                              size={"30px"}
                              speedMultiplier={0.8}
                              color="#fafafa"
                            />
                          ) : (
                            "Join"
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};
