import React from "react";

const RangeSlider = ({
  min,
  max,
  value,
  onChange,
  onDrangStart,
  onDragEnd,
  rangeBg,
}) => {
  const [isDragging, setIsDragging] = React.useState(false);

  const handleChange = (e) => {
    if (!onChange) return;

    const { value } = e.target;
    onChange(+value);
  };
  const handleDragStart = () => {
    if (onDrangStart) onDrangStart();
    setIsDragging(true);
  };
  const handleDragEnd = (e) => {
    if (onDragEnd) onDragEnd(+e.currentTarget.value);
    setIsDragging(false);
  };

  return (
    <div className="relative flex items-center bg-transparent rounded-[100px] px-[3px] py-[3px] ">
      <input
        type="range"
        className="range-slider"
        min={min}
        max={max}
        value={value}
        onChange={handleChange}
        onMouseDown={handleDragStart}
        onMouseUp={handleDragEnd}
        style={{
          zIndex: isDragging ? 1 : 2,
        }}
      />
      <div
        style={{ width: value + "%" }}
        className={`absolute h-[36px] ${rangeBg} rounded-[100px] border-[2px] border-[#FFFFFF] top-[2.3px]`}
      ></div>
    </div>
  );
};

export default RangeSlider;
