import React, { useEffect } from "react";
import TableTop from "../TableTop";
import { TablE } from "../ReuableTable";
import arrowLft from "../../assets/imgs/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import useService, { getTokens } from "../../service/useService";
import { getFromStore } from "../../utils/helpers";
import { toast } from "react-toastify";
import NotFound from "../../assets/imgs/notFoundImg.jpg";

export const Hometable = ({ hasOrders, filteredOrders }) => {
  const [showOrders, setShowOrders] = React.useState(true);
  const [showAccepted, setShowAcceptedOrders] = React.useState(false);
  const [ifNoAcceptedData, setIfNoAcceptedData] = React.useState(false);

  const [acceptedOrders, setHasAcceptedOrders] = React.useState([]);

  const token = getTokens();
  const details = getFromStore("vendyz_user");
  const merchant_id = getFromStore("merchant_id");
  const merchant = getFromStore("merchant-B");
  const userType = details.user_type;
  const baseUrl = process.env.REACT_APP_ODER_BASE_URL;
  const Service = useService(baseUrl);
  const userID = details.id;

  useEffect(() => {
    const getDetails = async () => {
      try {
        const response =
          userType === "buyer"
            ? await Service.get(`/vendor/orders/buyer/order/requests/${userID}`)
            : await Service.get(
                `/buyer/orders/merchant/order/requets/${merchant_id}`
              );
        if (response.status === 200) {
          const data = response.data.data;
          console.log(response.data.data.data);
          setHasAcceptedOrders(data.data);
          if (data.data.length === 0) {
            setIfNoAcceptedData(true);
          }
        }
      } catch (error) {
        if (error.message === "timeout exceeded") {
          toast.error("Timeout exceeded");
        } else if (error.message === "Network Error") {
          toast.error("Network error");
        }
      }
    };

    getDetails();
  }, [merchant_id, userID, userType]);

  const tableHeadData = [
    {
      id: 1,
      name: "Name",
      amount: "Amount",
      orderid: "OrderID",
      status: "Status",
    },
  ];
  const navigate = useNavigate();

  return (
    <div className="pt-14">
      {hasOrders ? (
        <div className="rounded-[25px] py-[42px] px-[18px] border-[1px] ">
          <TableTop
            showOrders={showOrders}
            setShowOrders={setShowOrders}
            setShowAcceptedOrders={setShowAcceptedOrders}
            showAccepted={showAccepted}
            text={"Orders"}
            btText={"View more"}
            icon={arrowLft}
            onClick={() => navigate("/orders")}
          />

          <div className="w-full pt-10">
            {!showAccepted ? (
              <TablE
                tHeaders={tableHeadData}
                tRows={filteredOrders}
                variant={"index"}
              />
            ) : (
              <div>
                {!ifNoAcceptedData ? (
                  <TablE
                    tHeaders={tableHeadData}
                    tRows={acceptedOrders}
                    variant={"index"}
                    showDetails={false}
                  />
                ) : (
                  <div className="flex justify-center items-center h-full">
                    <div className="flex flex-col items-center justify-center">
                      <div>
                        <img
                          src={NotFound}
                          alt="/"
                          className="w-64 h-64 rounded-s-lg"
                        />
                      </div>

                      <p className="text-[14px] font-[400] text-[#9B9697] w-[60%] text-center">
                        It looks like you haven’t made any transactions yet.
                        Start making transactions to see your history here.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="rounded-[25px] py-[42px] px-[18px] border-[1px] h-[446px] ">
          <TableTop
            showOrders={showOrders}
            setShowOrders={setShowOrders}
            setShowAcceptedOrders={setShowAcceptedOrders}
            showAccepted={showAccepted}
            text={"Orders"}
            btText={"View more"}
            icon={arrowLft}
            onClick={() => navigate("/orders")}
          />
          {!showAccepted ? (
            <div className="flex justify-center items-center h-full">
              <div className="flex flex-col items-center justify-center">
                <div>
                  <img
                    src={NotFound}
                    alt="/"
                    className="w-64 h-64 rounded-s-lg"
                  />
                </div>

                <p className="text-[14px] font-[400] text-[#9B9697] w-[60%] text-center">
                  It looks like you haven’t made any transactions yet. Start
                  making transactions to see your history here.
                </p>
              </div>
            </div>
          ) : (
            <div>
              {!ifNoAcceptedData ? (
                <TablE
                  tHeaders={tableHeadData}
                  tRows={acceptedOrders}
                  variant={"index"}
                  showDetails={false}
                />
              ) : (
                <div className="flex justify-center items-center h-[350px] ">
                  <div className="flex flex-col items-center justify-center">
                    <div>
                      <img
                        src={NotFound}
                        alt="/"
                        className="w-64 h-64 rounded-s-lg"
                      />
                    </div>

                    <p className="text-[14px] font-[400] text-[#9B9697] w-[60%] text-center">
                      It looks like you haven’t made any transactions yet. Start
                      making transactions to see your history here.
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
