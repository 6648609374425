import React, { createContext, useState, useContext } from "react";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [userType, setUserType] = useState("buyer");
  const [OrderNotification, setOrderNotification] = useState(null);
  const [userINFO, setUserINFO] = useState(null);
  const [vendorBussness, setVendorBussness] = useState("");
  const [id, setId] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [allOrderData, setAlOrderData] = useState([]);
  const [activeAccount, setActiveAccount] = useState(null);
  const [bvncheck, setBvncheck] = useState(false);
 const[ifHasDocuments, setIfHasDocuments] = useState(false)

  const value = {
    userType,
    setUserType,
    OrderNotification,
    setOrderNotification,
    setUserINFO,
    userINFO,
    setVendorBussness,
    vendorBussness,
    id,
    setId,
    profilePicture,
    setProfilePicture,
    setAlOrderData,
    allOrderData,
    activeAccount,
    bvncheck,
    setBvncheck,
    setActiveAccount,
    ifHasDocuments,
    setIfHasDocuments
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within a SidebarProvider");
  }
  return context;
};
