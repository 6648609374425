import React from "react";
import vector from "../../assets/imgs/arrow-down.svg";
import AntDropdown from "../antdeeDropDown";

const RangeSliderTop = ({ title, btnTitle, figure, onchamge }) => {
  return (
    <>
      <div className="flex items-center justify-between ">
        <h1 className="text-[16px] text-[#5C5959] font-semibold">{title}</h1>
        <div className="h-[44px] w-[143px]">
          <AntDropdown
            onChange={onchamge}
            // className=" text-[#5C5959] text-[16px] py-[12px] px-[20px] bg-white rounded-[100px]  shadow-lg  border-1px border "
          />
        </div>
      </div>
      <div>
        <h1 className="text-[24px] text-[#232323] font-bold">{figure}</h1>
      </div>
    </>
  );
};

export default RangeSliderTop;
