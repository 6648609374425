// import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export const InternationalPhone = ({
  backgroundColor,
  setPhone,
  phone,
  disabled,
  placeHolder,
}) => {
  // const [phone, setPhone] = useState("");

  return (
    <div>
      <PhoneInput
        country={"ng"} // Set default country to Nigeria
        enableSearch={true} // Enable country search
        inputProps={{ maxLength: 17 }} // Limit input to 9 digits
        placeholder={placeHolder || "Enter Phone"}
        value={phone}
        onChange={(phone) => setPhone(phone)}
        inputStyle={{
          width: "100%",
          height: "46px",
          outline: "none",
          backgroundColor: backgroundColor || "#F0F0F0",
          border: "1px solid #F0F0F0",
          borderRadius: "20px",
          paddingLeft: "72px",
          paddingRight: "12px",
          paddingTop: "14px",
          paddingBottom: "14px",
        }}
        disabled={disabled}
        buttonStyle={{
          border: "none",
          borderRadius: "20px",
          paddingLeft: "22px",
          paddingRight: "12px",
          paddingTop: "14px",
          paddingBottom: "14px",
        }}
      />
    </div>
  );
};
