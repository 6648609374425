import React from "react";

export const RangeText = ({ title, value }) => {
  return (
    <div className="flex-col">
      <p className="text-sm text-[#9B9697]">{title}</p>
      <p className="text-[16px] font-bold text-[#232323]">{value}</p>
    </div>
  );
};
