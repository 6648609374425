import * as React from "react";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import { FaNairaSign } from "react-icons/fa6";

export default function ArcDesign({ icon, range, value }) {
  const settings = {
    width: 200,
    height: 200,
    value: 20,
  };

  return (
    <div>
      <div className="relative">
        <Gauge
          {...settings}
          cornerRadius="50%"
          sx={(theme) => ({
            [`& .${gaugeClasses.valueText}`]: {
              fontSize: 0,
            },
            [`& .${gaugeClasses.valueArc}`]: {
              fill: "#5271FF",
            },
            [`& .${gaugeClasses.referenceArc}`]: {
              fill: "#FF9C33",
            },
          })}
          startAngle={-90}
          endAngle={90}
          width={400}
          value={range}
        />
        <div className="absolute top-28 right-[100px] flex flex-col items-center w-[200px] gap-5">
          <p className="text-[16px] text-[#5C5959] font-[500]">Orders</p>
          <div className="flex items-center">
            <div className="">
              <FaNairaSign size={"25px"} color="black" />
            </div>

            <p className="text-[24px] text-[#232323] font-[700]">{value}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
