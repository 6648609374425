import React from "react";
import MajorTitle from "../../../../components/MajorTitle";

export const RemarkOutput = ({
  Remark,
  Recieved,
  ResolutionDetail,
  selectedFiles,
}) => {
  return (
    <tbody>
      <tr className="text-[#5C5959] font-[400] pl-3 tracking-wider">
        <td className="pl-3 tracking-wider capitalize">{Remark}</td>
        <td className="pl-3 tracking-wider capitalize">{Recieved}</td>
        <td className="pl-3 tracking-wider capitalize">{ResolutionDetail}</td>
        <td className="flex gap-3 ">
          <div className="mr-2  border-2 border-[#F0F0F0] rounded-[20px] ">
            {selectedFiles ? (
              <div className=" border-2  border-[#F0F0F0] rounded-[20px] p-[3px] bg-[#FAFAFA]">
                <img
                  src={selectedFiles}
                  alt=""
                  className="h-20 w-20 object-cover rounded-[20px]"
                />
              </div>
            ) : null}
          </div>
        </td>
      </tr>
    </tbody>
  );
};
