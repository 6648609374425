import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import blueTickIcon from "../../../assets/imgs/tick-circle.svg";
import Button from "../../../components/Button";
import bankTransferIcon from "../../../assets/imgs/IbankTransferIcon.svg";
import activeBankTransferIcon from "../../../assets/imgs/activeBankTransferIcon.svg";
import cardPaymentIcon from "../../../assets/imgs/card-sendIcon.svg";
import activeCardpaymentIcon from "../../../assets/imgs/Activecard-send.svg";
import useService from "../../../service/useService";

export const PaymentOPtion = () => {
  const navigate = useNavigate();

  const [highlightedCard, setHighlightedCard] = React.useState("");
  const [btnDisabled, setBtnDisabled] = React.useState(true);
  const [submit, setSubmit] = React.useState(null);

  const baseurl = process.env.REACT_APP_WALLET_BASE_URL;
  const service = useService(baseurl);

  useEffect(() => {
    const fetchOptions = async () => {
      const response = await service.get("/payment/options");
      if (response.status === 200) {
        console.log(response.data.data);
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    if (submit === null) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  }, [submit]);

  const cards = [
    {
      id: "card-1",
      bgColor: "bg-white",
      borderColor: "border-[#F0F0F0]",
      iconBg: "bg-[#F4F4F4]",
      title: "Bank Transfer",
      subtitle: " Pay directly from your bank account using bank transfer.",
      tickIcon: false,
      icon: bankTransferIcon,
      activeIcon: activeBankTransferIcon,
    },
    {
      id: "card-2",
      bgColor: "bg-white",
      borderColor: "border-[#F0F0F0]",
      iconBg: "bg-[#F4F4F4]",
      title: "Card payment",
      subtitle: " Pay instantly using your credit or debit card.",
      tickIcon: false,
      icon: cardPaymentIcon,
      activeIcon: activeCardpaymentIcon,
    },
  ];

  const highlightCard = (id) => {
    setHighlightedCard(id === highlightedCard ? null : id);
  };

  return (
    <section>
      <div className="w-full flex flex-col items-center justify-center pt-[7%]">
        {/* tittle */}
        <div className="flex items-center flex-col">
          <div className="text-[24px] font-bold text-[#232323]">
            <h1> How would you like to pay?</h1>
          </div>
        </div>
        {/* cards */}
        <div>
          <div className=" pt-16 ">
            <div className="flex gap-5">
              {cards.map((card) => (
                <div
                  key={card.id}
                  onClick={() => {
                    highlightCard(card.id);
                    highlightedCard
                      ? setBtnDisabled(true)
                      : setBtnDisabled(false);
                    setSubmit(card.id);
                  }}
                  className={`border-[1px] rounded-[25px] cursor-pointer ${
                    highlightedCard === card.id
                      ? "border-[#5271FF] shadow-sm shadow-[#5271FF] drop-shadow-sm bg-[#F3F5FD]"
                      : card.borderColor
                  } w-72 h-[260px] p-4 flex flex-col gap-20 px-6 relative`}
                >
                  {highlightedCard === card.id && (
                    <div className="absolute -top-5 -right-2">
                      <img src={blueTickIcon} alt="" />
                    </div>
                  )}
                  <div
                    className={`w-[70px] h-[70px] rounded-[100px] ${
                      highlightedCard === card.id ? "bg-[#E5EAFF]" : card.iconBg
                    } border-[1px] border-[#F6F6F6] flex justify-center items-center`}
                  >
                    <img
                      src={
                        highlightedCard === card.id
                          ? card.activeIcon
                          : card.icon
                      }
                      alt=""
                    />
                  </div>
                  <div>
                    <h1 className="text-[#232323] text-[24px] font-bold pb-2">
                      {card.title}
                    </h1>
                    <h2 className="text-sm text-[#9B9697]">{card.subtitle}</h2>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex gap-4 justify-center w-full pb-4 pt-14">
            <div className="w-1/2">
              <Button
                size="fullwidth"
                variant="white"
                touches={"h-[44px]"}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </Button>
            </div>

            <div className="w-1/2">
              <Button
                size="fullwidth"
                variant="authBlue"
                isBtnDisabled={btnDisabled}
                touches={"h-[44px]"}
                onClick={() => {
                  if (submit === "card-1") {
                    navigate(`/generate-link/payment/bankTransfer`);
                  } else if (submit === "card-2") {
                    navigate("/generate-link/payment/cardPayment");
                  } else {
                    setSubmit(null);
                    setBtnDisabled(true);
                  }
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
