import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/imgs/Logo.svg";
import helpIcon from "../../assets/imgs/formkit_help (1).svg";

export const Generatelink = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <div className="bg-neutral-50 h-full overflow-hidden">
      <div className=" h-full">
        <div className=" flex justify-center items-center  ">
          <div className="flex h-[10%] xl:h-[100px]  w-[95%] items-center justify-between py-3 xl:py-0  ">
            <div onClick={() => navigate("/")}>
              <img src={logo} alt="" />
            </div>
            <div className="border-l-2 w-[20%] flex justify-end xl:pr-14 2xl:w-[15%]">
              <div className=" rounded-[100px] w-[150px] h-[40px] flex  items-center shadow-sm justify-center gap-2 border-[1px] border-[#F0F0F0] cursor-pointer transition duration-300  hover:scale-125">
                <div>
                  <img src={helpIcon} alt="" />
                </div>
                <div>
                  <h1 className="text-[#5C5959] font-semibold text-sm ">
                    Need help?
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex bg-neutral-50  h-[90%] xl:h-[calc(100%-100px)] ">
          <div className=" h-full w-[10%]"></div>
          <div
            className={` bg-white w-full rounded-tl-[50px]  h-full relative  ${
              pathname.includes("create-link") ||
              pathname.includes("generate-link/payment")
                ? "px-0 py-0"
                : "pt-14 px-14"
            }`}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
