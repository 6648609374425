import React from "react";
import smsIcon from "../../assets/imgs/sms.svg";

export const TransactionPaymentInfoTable = ({
  senderBankName,
  senderBankLogo,
  tHeaders,
  data,
  recipientLogo,
  recipeientPhoneNum,
  recipientEmail,
  senderPhoneNumber,
  senderProfilePicture,
  remark,
  RecipientName,
}) => {
  return (
    <table className="w-full">
      <thead className="text-left h-[54px] text-[#9B9697] text-[14px] font-light ">
        <tr>
          {Object.keys(tHeaders[0]).map(
            (key) =>
              key !== "id" && (
                <th
                  key={key}
                  className="capitalize pl-3 tracking-wider font-light "
                >
                  {key}
                </th>
              )
          )}
        </tr>
      </thead>

      <tbody className="">
        <tr
          key={data.id}
          className={`h-[70px] border-[#F0F0F0]  cursor-pointer `}
        >
          {/* paymet to  */}
          <td>
            <div>
              <div className="flex gap-2 items-center cursor-pointer ">
                <div className=" w-[42px] h-[42px]">
                  <img src={recipientLogo} alt="" className="w-full h-full" />
                </div>
                <div>
                  <p className="text-[#232323] font-[500] text-[14px]">
                    {RecipientName}
                  </p>
                  <p className="text-[#9B9697] font-[500] text-sm">
                    {recipeientPhoneNum}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-3 pt-3">
                <div>
                  <img src={smsIcon} alt="" />
                </div>
                <div>
                  <p className="text-[#9B9697] text-[16px]">{recipientEmail}</p>
                </div>
              </div>
            </div>
          </td>
          {/* payment from */}
          <td>
            <div>
              <div>
                <div className="flex gap-2 items-center cursor-pointer ">
                  <div className=" w-[42px] h-[42px]">
                    <img
                      src={senderProfilePicture}
                      alt=""
                      className="w-full h-full"
                    />
                  </div>
                  <div className="py-2">
                    <p className="text-[#232323] tracking-wide font-[500] text-[14px]">
                      {data.AccounTname}
                    </p>
                    <p className="text-[#9B9697] font-[500] text-sm">
                      {senderPhoneNumber}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-3 pt-3">
                  <div>
                    <img src={senderBankLogo} alt="" />
                  </div>
                  <div>
                    <p className="text-[#9B9697] text-[16px]">
                      {senderBankName}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </td>
          {/* payment amount */}
          <td>
            <div>
              <p className="text-[#232323] font-[500] text-lg">{remark}</p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
