import React, { useState, useEffect } from "react";
import { HiOutlineXMark } from "react-icons/hi2";
import thumbsupIcon from "../../../assets/imgs/thumbsUP.svg";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { CiImageOn } from "react-icons/ci";
import Button from "../../Button";
import { useNavigate } from "react-router-dom";
import { getFromStore } from "../../../utils/helpers";
import useService from "../../../service/useService";
import AWS from "aws-sdk";
import { ClockLoader } from "react-spinners";
import { toast } from "react-toastify";
import createAxiosInstance from "../../../service";

export default function BuyerDisputeModal({
  orderDet,
  setShowDisputes,
  value,
  setValue,
  value2,
  setvalue2,
  selectedFiles,
  complaints,
  setSelectedFiles,
  setComplaints,
  setConfirmedDisputes,
  setOpenDisputeButton,
  setCofirmORderButton,
  setOpenDisputeButtonValue,
  setIfHaveBuyerRemark,
}) {
  const details = getFromStore("vendyz_user");
  const userID = details.id;

  const basUrl = process.env.REACT_APP_ODER_BASE_URL;
  const Service = createAxiosInstance(basUrl);

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  const s3 = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME },
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openDisputesBlueButton, setOpenDisputeBlueButton] = useState(true);

  const navigate = useNavigate();

  const uploadFileDocs = async () => {
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: `${selectedFiles[0].name}`,
      Body: selectedFiles[0],
    };

    try {
      setOpenDisputeBlueButton(true);
      setIsLoading(true);
      const uploadResult = await s3.upload(params).promise();

      if (uploadResult) {
        const body = {
          user_id: userID,
          order_id: orderDet.id,
          received_order: value,
          issue_details: complaints,
          issue_eveidence_url: String(uploadResult.Location),
          sought_resolution: value2,
        };

        try {
          const response = await Service.post(`/buyer/disputes`, body);

          if (response.status === 201) {
            setIsLoading(false);
            setOpenDisputeBlueButton(false);
            setSuccessMessage("Dispute submitted successfully!");
            toast.success("Dispute submitted successfully!");
            setConfirmedDisputes(true);
            setCofirmORderButton(true);
            setShowDisputes(false);
            setOpenDisputeButton(true);
            setOpenDisputeButtonValue("Dispute in progress");
            setValue(value);
            setvalue2(value2);
            setSelectedFiles(uploadResult.Location);
            setComplaints(complaints);
            setIfHaveBuyerRemark(true);
          }
        } catch (error) {
          console.log(error);
          setIsLoading(false);
          setOpenDisputeBlueButton(false);

          if (error.message === "timeout exceeded") {
            toast.error("Timeout exceeded");
          } else if (error.message === "Network Error") {
            toast.error("Network error");
          }
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setErrorMessage("Failed to upload the file. Please try again.");
    }
  };

  useEffect(() => {
    if (
      value !== "" &&
      value2 !== "" &&
      complaints !== "" &&
      selectedFiles.length !== 0
    ) {
      setOpenDisputeBlueButton(false);
    } else {
      setOpenDisputeBlueButton(true);
    }
  }, [complaints, selectedFiles.length, value, value2]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedFormats = ["image/jpeg", "image/png"];
      const maxSize = 1 * 1024 * 1024; // 1 MB

      if (!allowedFormats.includes(file.type)) {
        setErrorMessage(`${file.name} is not a supported format (PNG or JPG).`);
        setSelectedFiles([]);
        return;
      }

      if (file.size > maxSize) {
        setErrorMessage(`${file.name} exceeds the maximum file size of 1 MB.`);
        setSelectedFiles([]);
        return;
      }

      setSelectedFiles([file]);
      setErrorMessage("");
    } else {
      setSelectedFiles([]);
      setErrorMessage("No file selected.");
    }
  };

  return (
    <div className="h-full w-full bg-black/60 fixed top-0 right-0 z-50 flex justify-center items-center">
      <div className="overflow-auto bg-[#FFFFFF] border-[1px] border-[#F0F0F0] rounded-[24px] h-[95%] w-[692px]">
        <div className="p-5">
          <div className="flex justify-between items-center">
            <img src={thumbsupIcon} alt="Thumbs Up" />
            <HiOutlineXMark
              color="#9B9697"
              size={25}
              className="cursor-pointer"
              onClick={() => {
                setValue("");
                setvalue2("");
                setSelectedFiles([]);
                setShowDisputes(false);
                setComplaints("");
              }}
            />
          </div>

          <div className="pt-4">
            <h1 className="text-[16px] font-[700] text-[#232323]">
              Open dispute
            </h1>
            <p className="text-[14px] font-[400] text-[#5C5959]">
              To submit your dispute, please tell us your issue.
            </p>
          </div>

          <div className="pt-4 bg-[#FAFAFA] w-full rounded-[25px] h-[153px] flex justify-between px-3 items-center">
            <div className="w-[70%] border-r-[2px] border-[#F0F0F0] flex gap-4 items-center">
              <img src={orderDet.item_image_url} className="w-[99px]" alt="" />
              <p className="text-[#232323] font-[700] text-[14px]">
                {orderDet.item_description}
              </p>
            </div>
            <div className="w-[25%] text-left">
              <div className="font-bold">₦ {orderDet.order_amount}</div>
              <div className="pt-3">{orderDet.order_ref}</div>
            </div>
          </div>

          <div className="pt-5">
            <p className="text-[14px] font-[400] text-[#5C5959] pb-1">
              Did you receive the product or service?
            </p>
            <FormControl>
              <RadioGroup
                aria-labelledby="received-order-radio-buttons-group"
                name="received-order-radio-buttons-group"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="Yes"
                  style={{ fontSize: "12px", color: "#5C5959" }}
                />
                <FormControlLabel
                  value="No"
                  control={<Radio />}
                  label="No"
                  style={{ fontSize: "12px", color: "#5C5959" }}
                />
              </RadioGroup>
            </FormControl>
          </div>

          <div className="pt-3">
            <p className="text-[14px] font-[400] text-[#5C5959] pb-1">
              Please provide details of the issue:
            </p>
            <textarea
              onChange={(e) => setComplaints(e.target.value)}
              value={complaints}
              minLength="4"
              maxLength="200"
              placeholder="Between 4 - 200 characters"
              cols="30"
              rows="4"
              className="text-sm w-full border-[2px] border-[#F0F0F0] rounded-[20px] outline-none p-2 resize-none tracking-wide"
            ></textarea>
          </div>

          <div className="pt-2">
            <p className="text-[14px] font-[400] text-[#5C5959] pb-1">
              What resolution are you seeking?
            </p>
            <FormControl>
              <RadioGroup
                aria-labelledby="resolution-radio-buttons-group"
                name="resolution-radio-buttons-group"
                value={value2}
                onChange={(e) => setvalue2(e.target.value)}
              >
                <FormControlLabel
                  value="Refund"
                  control={<Radio />}
                  label="Refund"
                  style={{ fontSize: "12px", color: "#5C5959" }}
                />
                <FormControlLabel
                  value="Replace"
                  control={<Radio />}
                  label="Replacement of product or service"
                  style={{ fontSize: "12px", color: "#5C5959" }}
                />
              </RadioGroup>
            </FormControl>
          </div>

          <div className="pt-5">
            <div className="flex justify-between items-center">
              <p className="text-[14px] font-[400] text-[#5C5959] pb-2">
                Please upload evidence of the issue:
              </p>
              <span className="text-sm text-[#5C5959]">
                {selectedFiles.length > 0
                  ? `${selectedFiles.length} file(s) selected`
                  : "No files chosen"}
              </span>
            </div>

            <div className="flex items-center space-x-4">
              <label
                htmlFor="fileInput"
                className="h-[90px] w-[90px] rounded-[20px] bg-[#FAFAFA] cursor-pointer flex justify-center items-center shadow-sm border-[2px] border-[#F0F0F0]"
              >
                <CiImageOn color="#9B9697" size={35} />
              </label>
              <input
                type="file"
                id="fileInput"
                onChange={handleFileChange}
                className="hidden"
                accept=".jpg, .jpeg, .png"
              />
              <div className="flex">
                {selectedFiles.map((file) => (
                  <div
                    key={file.name}
                    className="mr-2 border-2 border-[#F0F0F0] rounded-[20px]"
                  >
                    <div className="border-2 border-[#F0F0F0] rounded-[20px] p-[3px] bg-[#FAFAFA]">
                      <img
                        src={URL.createObjectURL(file)}
                        alt={file.name}
                        className="h-20 w-20 object-cover rounded-[20px]"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="pt-4">
              <p className="text-[12px] font-[400] text-[#9B9697] pb-2">
                Maximum of 3 pictures with file size not more than 1 MB.
                Supported formats: PNG and JPG.
              </p>
            </div>
          </div>

          {errorMessage && (
            <p className="text-red-500 text-sm pt-4">{errorMessage}</p>
          )}

          {successMessage && (
            <p className="text-green-500 text-sm pt-4">{successMessage}</p>
          )}
          <div className="flex gap-4 justify-center w-full pt-7">
            <div className="w-1/2">
              <Button
                touches={"h-[44px]"}
                size="fullwidth"
                variant="white"
                onClick={() => {
                  setValue("");
                  setvalue2("");
                  setComplaints("");
                  setSelectedFiles([]);
                  setOpenDisputeButtonValue("Open disputes");
                }}
              >
                Cancel
              </Button>
            </div>
            <div className="w-1/2">
              <Button
                touches={"h-[44px]"}
                variant="authBlue"
                size="fullwidth"
                isBtnDisabled={openDisputesBlueButton}
                onClick={uploadFileDocs}
              >
                {isLoading ? (
                  <ClockLoader
                    size={"30px"}
                    speedMultiplier={0.8}
                    color="#fafafa"
                  />
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
