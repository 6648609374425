import SimpleCrypto from "simple-crypto-js";

export const saveInStore = (key, data, storage = "local") => {
  const _secret = process.env.REACT_APP_SECRET;
  const simpleCrypto = new SimpleCrypto(_secret);
  const payload = simpleCrypto.encrypt(data);

  if (storage === "session") {
    sessionStorage.setItem(key, payload);
  } else {
    localStorage.setItem(key, payload);
  }
};

export const getFromStore = (key, storage = "local") => {
  try {
    const _secret = process.env.REACT_APP_SECRET;
    const simpleCrypto = new SimpleCrypto(_secret);

    const data =
      storage === "session"
        ? sessionStorage.getItem(key)
        : localStorage.getItem(key);

    const result = data ? simpleCrypto.decrypt(data) : null;
    return result;
  } catch (error) {
    return null;
  }
};

export const formatDate = (dateString) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date(dateString);
  const monthName = months[date.getMonth()];
  const day = date.getDate();

  return `${monthName} ${day}`;
};

export const convertTimeToGMT = (timeString) => {
  const [time, modifier] = timeString.split(" ");
  let [hours, minutes] = time.split(":").map(Number);

  if (modifier === "pm" && hours !== 12) {
    hours += 12;
  } else if (modifier === "am" && hours === 12) {
    hours = 0;
  }

  const date = new Date();
  date.setHours(hours, minutes);

  date.setMinutes(date.getMinutes() + 12);

  const gmtHours = date.getUTCHours();
  const gmtMinutes = date.getUTCMinutes();

  const formattedHours = gmtHours % 12 || 12;
  const formattedMinutes = gmtMinutes.toString().padStart(2, "0");
  const gmtModifier = gmtHours >= 12 ? "PM" : "AM";

  return `${formattedHours}:${formattedMinutes} ${gmtModifier} GMT`;
};
export const convertTime = (timeString) => {
  const [time, modifier] = timeString.split(" ");
  let [hours, minutes] = time.split(":").map(Number);

  if (modifier === "pm" && hours !== 12) {
    hours += 12;
  } else if (modifier === "am" && hours === 12) {
    hours = 0;
  }

  const date = new Date();
  date.setHours(hours, minutes);

  date.setMinutes(date.getMinutes() + 12);

  const gmtHours = date.getUTCHours();
  const gmtMinutes = date.getUTCMinutes();

  const formattedHours = gmtHours % 12 || 12;
  const formattedMinutes = gmtMinutes.toString().padStart(2, "0");
  const gmtModifier = gmtHours >= 12 ? "PM" : "AM";

  return `${formattedHours}:${formattedMinutes} ${gmtModifier} `;
};

export const removeFromStore = (key, storage = "local") => {
  storage === "session"
    ? sessionStorage.removeItem(key)
    : localStorage.removeItem(key);
};

export const formatCurrency = (amount) => {
  return new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(amount);
};

export function getTimeFromISOString(isoString) {
  // Create a Date object from the ISO string
  const date = new Date(isoString);

  // Extract hours, minutes, and seconds for 24-hour format
  const hours24 = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");

  // Convert to 12-hour format
  let hours12 = date.getUTCHours();
  const ampm = hours12 >= 12 ? "PM" : "AM";
  hours12 = hours12 % 12;
  hours12 = hours12 ? hours12 : 12; // the hour '0' should be '12'

  // Format the time
  const time24 = `${hours24}:${minutes}:${seconds}`;
  const time12 = `${hours12
    .toString()
    .padStart(2, "0")}:${minutes}:${seconds} ${ampm}`;

  return { time24, time12 };
}
