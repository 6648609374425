import React from "react";
import bluebar from "../../assets/imgs/Rectangle 6288 (1).svg";
import { FaNairaSign } from "react-icons/fa6";

const OrdersCard = ({ title, figure, amount }) => {
  return (
    <div className="w-full h-full ">
      <div className="w-full h-full  rounded-[25px] border-[1px] border-[#F0F0F0]  flex gap-4">
        <div className="flex items-center">
          <img src={bluebar} alt="" />
        </div>
        <div className="p-4 flex flex-col justify-between gap-6">
          <div>
            <h1 className="text-[16px] text-[#9B9697] pt-4 tracking-wider">
              {title}
            </h1>
          </div>
          <div>
            <div className="flex items-center gap-1">
              <p className="text-[#232323] font-[700] text-[24px] tracking-wider">
                {figure}
              </p>
            </div>
            <div className="flex items-center gap-1 pt-1">
              <div className="">
                <FaNairaSign color="#232323" size={10} />
              </div>
              <div className="">
                <p className="text-[#9B9697] text-[12px] font-[500]  tracking-wider">
                  {amount}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersCard;
