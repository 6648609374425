import React, { useEffect } from "react";
import logo from "../../../../assets/imgs/Logo.svg";
import { useParams } from "react-router-dom";
import Button from "../../../../components/Button";
import { useNavigate } from "react-router-dom";
import CountdownTimer from "../../../../components/countdownTimer";
import ActOtp from "../../../../components/actOtp";
import createAxiosInstance from "../../../../service";
import { ClockLoader } from "react-spinners";
import { toast } from "react-toastify";
import { saveInStore } from "../../../../utils/helpers";

export const EmailConfigBuyer = () => {
  const basUrl = process.env.REACT_APP_BASE_URL;
  const Service = createAxiosInstance(basUrl);

  const [isBtnDisabled, setisBtnDisabled] = React.useState(true);
  const [otpValue, setOtpValue] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (otpValue.length <= 5) {
      setisBtnDisabled(true);
    } else {
      setisBtnDisabled(false);
    }
  }, [otpValue]);

  const handleSubmitOtp = async () => {
    setIsLoading(true);
    setisBtnDisabled(true);
    try {
      const body = {
        email: params.buyeremail,
        request_token: params.req_token,
        request_otp: otpValue,
      };

      const response = await Service.post("/auth/email/verify/confirm", body);
      if (response.status === 201) {
        const user_info = response.data.data;
        console.log(response);
        console.log(user_info);
        setIsLoading(false);
        setisBtnDisabled(false);
        saveInStore("vendyzUserData", user_info);
        // navigate(`/auth/register/buyer/linkPreviousAccount`);
        navigate("/auth/register/buyer/contact-information");
        console.log(response);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setisBtnDisabled(false);
      if (error.message === "Network Error") {
        toast.error("Network Error");
      } else {
        const errorMsg = error.response?.data.message;
        toast.error(errorMsg);
        setIsLoading(false);
      }
    }
  };

  return (
    <section className="h-full">
      <div className="flex justify-center">
        <img src={logo} alt="" className="" />
      </div>
      <div className=" xl:pt-4">
        <div className="flex justify-center">
          <h1 className="text-[27px] font-[700] text-[#232323]">
            Verify your email
          </h1>
        </div>
        <div className="flex justify-center xl:pt-5">
          <p className="text-[#9B9697] text-[16px] text-[400] ">
            Enter the code sent to{" "}
            <span className="text-[#232323] text-[16px] pl-2 font-semibold ">
              {params.buyeremail}
            </span>
          </p>
        </div>
        <div className="flex justify-center w-full ">
          <div className=" flex flex-col items-center gap-7 justify-center pt-5  xl:pt-8 2xl:pt-12 w-[70%]">
            <div className=" flex justify-center w-fit ">
              <ActOtp otp={otpValue} setOtp={setOtpValue} lenght={6} />
            </div>
            <div className="pt-0 xl:pt-3  2x:pt-5 w-full justify-center flex flex-col gap-5 items-center ">
              <div className="pl-16 xl:pl-36">
                <div className="flex gap-4 items-center  w-full">
                  <p className="text-[#5C5959] text-[400] text-sm xl:text-[16px] text-right">
                    Didn’t get a code?{" "}
                  </p>
                  <span className="text-[#5271FF] pl-5">
                    <CountdownTimer />
                  </span>
                </div>
              </div>
              <div className="flex justify-center items-center w-[500px] xl:pt-2 2xl:pt-12 ">
                <div className="w-full">
                  <Button
                    isBtnDisabled={isBtnDisabled}
                    variant="authBlue"
                    touches={"h-[44px]"}
                    onClick={handleSubmitOtp}
                  >
                    {isLoading ? (
                      <ClockLoader
                        size={"30px"}
                        speedMultiplier={0.8}
                        color="#fafafa"
                      />
                    ) : (
                      "Continue"
                    )}
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center gap-3 2xl:pt-6 pb-5">
              <div>
                <h1 className="text-[16px] font-[400] text-[#5C5959]">
                  Already have an account?
                </h1>
              </div>

              <div>
                <h1
                  onClick={() => navigate("/auth")}
                  className="text-[16px] font-[500] text-[#5271FF] cursor-pointer"
                >
                  Log in
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
