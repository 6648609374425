import React from "react";
import { motion, AnimatePresence } from "framer-motion";

const ContainerWithGradient = ({ children, height, width }) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ scale: 1, opacity: 0, x: -100, y: 0 }}
        animate={{ scale: 1, opacity: 1, x: 0, y: 0 }}
        transition={{
          duration: 1,
        }}
        exit={{
          scale: 0,
          opacity: 0,
          x: 100,
          y: -20,
        }}
        className={`${width} ${height} rounded-[20px] relative overflow-hidden`}
      >
        <div
          className="absolute inset-0 flex justify-center items-center"
          style={{
            background:
              "linear-gradient(to right, #f7fcff 50%, #b4b8fd, #d7c0f9, #c1c8ff)",
          }}
        >
          {children}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ContainerWithGradient;
