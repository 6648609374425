// import React, { useEffect } from "react";
import MajorTitle from "../../components/MajorTitle";
import OrdersCard from "../../components/orders-card";
import OrderNavBar from "../../components/Orders-menuBar";
import { Outlet } from "react-router-dom";
import { getFromStore } from "../../utils/helpers";
import useService from "../../service/useService";
import { useEffect, useState } from "react";
import { PuffLoader } from "react-spinners";

export const Orders = () => {
  const details = getFromStore("vendyz_user");
  const merchant_id = getFromStore("merchant_id");
  const userType = details.user_type;
  const baseUrl = process.env.REACT_APP_ODER_BASE_URL;
  const Service = useService(baseUrl);
  const userID = details.id;
  const [ordersLength, setOrdersLength] = useState(0);
  const [dispiteLenght, setDisputeLength] = useState("");
  const [pageLoad, setPageLoad] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        setPageLoad(true);
        const response =
          userType === "buyer"
            ? await Service.get(`/buyer/orders/user/${userID}`)
            : await Service.get(`/vendor/orders/vendor/${merchant_id}`);
        if (response.status === 200) {
          const data = response.data.data.data;
          setPageLoad(false);
          setOrdersLength(data.length);
          try {
            const response =
              userType === "buyer"
                ? await Service.get(`/buyer/disputes/all/${userID}`)
                : await Service.get(`/vendor/disputes/all/${merchant_id}`);
            if (response.status === 200) {
              const data = response.data.data.data;
              setDisputeLength(data.length);
              console.log(data);
              setPageLoad(false);
            }
          } catch (error) {
            console.log(error);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  if (pageLoad) {
    return (
      <div className="h-72 w-full flex justify-center pt-44">
        <PuffLoader color="#5271FF" speedMultiplier={0.8} />
      </div>
    );
  }

  return (
    <section>
      <div>
        <div>
          <MajorTitle title={"Orders"} />
        </div>
        <div className="flex  w-full pt-8 gap-6">
          <div className="w-[23%]">
            <OrdersCard
              nairaIcon={true}
              title={"Orders"}
              figure={ordersLength}
              amount={0}
            />
          </div>
          <div className="w-[23%]">
            <OrdersCard
              nairaIcon={true}
              title={"Completed"}
              figure={0}
              amount={0}
            />
          </div>

          <div className="w-[23%]">
            <OrdersCard
              nairaIcon={true}
              title={"Cancelled"}
              figure={0}
              amount={0}
            />
          </div>
          <div className="w-[23%]">
            <OrdersCard
              nairaIcon={true}
              title={"Dispute"}
              figure={dispiteLenght}
              amount={0}
            />
          </div>
        </div>
        <div>
          <OrderNavBar />
        </div>

        <div>
          <div>
            <div>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
