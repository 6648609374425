import React, { useEffect } from "react";
import Textfield from "../../../components/Textfield";
import { useNavigate } from "react-router-dom";
import ActOtp from "../../../components/actOtp";
import Button from "../../../components/Button";
import createAxiosInstance from "../../../service";
import useService, { clearToken, getTokens } from "../../../service/useService";
import { ClockLoader, HashLoader, PuffLoader } from "react-spinners";
import { toast } from "react-toastify";
import MultipleSelect from "../../../components/QuestionPInINput";
import { Link } from "react-scroll";
import { getFromStore } from "../../../utils/helpers";

const AcctSecurity = () => {
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const vendorUrl = process.env.REACT_APP_VENDOR_BASE_URL;
  const vendorUseService = useService(vendorUrl);
  const VendorPostService = createAxiosInstance(vendorUrl);
  const Service = createAxiosInstance(baseUrl);
  const service = useService(baseUrl);

  const questions = localStorage.getItem("questions");

  const useQuestionData = [];

  const responses = JSON.parse(questions);
  responses.forEach((ques) => {
    useQuestionData.push(ques.question);
  });

  let dropDown3 = useQuestionData.pop(); // don't delete
  let dropDown1 = useQuestionData.splice(-2);
  let dropDown2 = useQuestionData.splice(0, 2);

  const [pageLoad, setPageLoad] = React.useState(false);
  const [pageLoading, setpageLoading] = React.useState(true);
  const [runGetUserAgain, setRunGetUserAgain] = React.useState(false);

  const [disabledbtn, setDisabledBtn] = React.useState(true);
  const [disabledbtn1, setDisabledBtn1] = React.useState(true);
  const [disabledbtn2, setDisabledBtn2] = React.useState(true);

  const [answer1, setAnswer1] = React.useState("");
  const [answer2, setAnswer2] = React.useState("");
  const [answer3, setAnswer3] = React.useState("");

  const [questionn1, setQuestionn1] = React.useState("");
  const [questionn2, setQuestionn2] = React.useState("");
  const [questionn3, setQuestionn3] = React.useState("");

  //   otp values
  const [newPin, setNewPin] = React.useState("");
  const [confirmNewPin, setConfirmNewPin] = React.useState("");

  const [confrimNewpasswordErrorMsg, setConfrimNewpasswordErrorMsg] =
    React.useState("");
  const [confrimNewpasswordErrIcon, setConfrimNewpasswordErrIcon] =
    React.useState(false);

  const [NewPassworderrorMsg, setNewPaswordErrormsg] = React.useState("");
  const [NewPasswordErrorIcon, setNewPasswordErrorIcon] = React.useState(false);
  // passwords value
  const [oldPassword, setOldPassword] = React.useState("");
  const [newpassword, setNewPassword] = React.useState("");
  const [confirmPassword, updateConfirmPassword] = React.useState("");

  const [pinPassword, setPinpassword] = React.useState("");
  const [secQuesPassword, setSecQuesPassword] = React.useState("");
  const [seqQuestionID, setSeqQuestionID] = React.useState("");
  const [seqQuestion, setseqQuestion] = React.useState("");
  const [seqQuestionAnswer, setSeqQuestionAnswer] = React.useState("");

  const [loading, setLoading] = React.useState(false);
  const [loading1, setLoading1] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);

  const [texnpiN, setTxnpiN] = React.useState(null);

  const token = getTokens();

  const details = token.userInfo;
  const userEmail = details.email;
  const userID = details.id;
  const userType = details.user_type;
  // const txnPin = details.txn_pin;

  const ChangePassword = async () => {
    const userInfo = {
      NewConfirmPasword: "",
      NewPasEsword: "",
    };

    // Password validation
    let regPassword = /^(?=.*\d).{6,}$/;
    if (!regPassword.test(newpassword)) {
      setNewPaswordErrormsg("Include a number and atleast 6 characters");

      setNewPasswordErrorIcon(true);
    } else {
      setNewPaswordErrormsg("");
      userInfo.NewPasEsword = newpassword;
      setNewPasswordErrorIcon(false);
    }

    // confirm validation
    if (newpassword !== confirmPassword) {
      setConfrimNewpasswordErrorMsg("Passwords do not match");

      setConfrimNewpasswordErrIcon(true);
    } else {
      userInfo.NewConfirmPasword = confirmPassword;
      setConfrimNewpasswordErrorMsg("");
      setConfrimNewpasswordErrIcon(false);
    }

    if (userInfo.NewPasEsword !== "" && userInfo.NewConfirmPasword !== "") {
      const body = {
        email: userEmail,
        old_password: oldPassword,
        new_password: newpassword,
      };

      try {
        setDisabledBtn(true);
        setLoading(true);
        const response = await Service.post("/user/password/reset", body);
        if (response.status === 201) {
          setDisabledBtn(false);
          setLoading(false);
          toast.success("Password changed successfully");
          setOldPassword("");
          setNewPassword("");
          updateConfirmPassword("");
        }
      } catch (error) {
        if (error === "ERR_NETWORK") {
          toast.error("Network error");
        } else {
          toast.error(" Old Password not recognize");
        }

        setDisabledBtn(false);
        setLoading(false);
      }
    } else {
      return null;
    }
  };

  const ChangePasswordVEndor = async () => {
    const userInfo = {
      NewConfirmPasword: "",
      NewPasEsword: "",
    };

    // Password validation
    let regPassword = /^(?=.*\d).{6,}$/;
    if (!regPassword.test(newpassword)) {
      setNewPaswordErrormsg("Include a number and atleast 6 characters");

      setNewPasswordErrorIcon(true);
    } else {
      setNewPaswordErrormsg("");
      userInfo.NewPasEsword = newpassword;
      setNewPasswordErrorIcon(false);
    }

    // confirm validation
    if (newpassword !== confirmPassword) {
      setConfrimNewpasswordErrorMsg("Passwords do not match");

      setConfrimNewpasswordErrIcon(true);
    } else {
      userInfo.NewConfirmPasword = confirmPassword;
      setConfrimNewpasswordErrorMsg("");
      setConfrimNewpasswordErrIcon(false);
    }

    if (userInfo.NewPasEsword !== "" && userInfo.NewConfirmPasword !== "") {
      const body = {
        email: userEmail,
        old_password: oldPassword,
        new_password: newpassword,
      };
      console.log(body);
      try {
        setDisabledBtn(true);
        setLoading(true);
        const response = await VendorPostService.post(
          "/merchant/auth/password/reset",
          body
        );

        if (response.status === 200) {
          setDisabledBtn(false);
          setLoading(false);
          toast.success("Password changed successfully");
          setOldPassword("");
          setNewPassword("");
          updateConfirmPassword("");
        }
      } catch (error) {
        if (error.message === "ERR_NETWORK") {
          toast.error("Network error");
        } else {
          toast.error(" Old Password not recognize");
          console.log(error);
        }

        setDisabledBtn(false);
        setLoading(false);
      }
    } else {
      return null;
    }
  };

  const setVendorSecurityQuestion = async () => {
    const body = {
      user_id: userID,
      email: userEmail,
      password: secQuesPassword,
      security_questions: [
        { id: 1, question: questionn1, answer: answer1 },
        { id: 2, question: questionn2, answer: answer2 },
        { id: 3, question: questionn3, answer: answer3 },
      ],
    };

    try {
      setDisabledBtn1(true);
      setLoading1(true);
      const response = await VendorPostService.post("/u/security/ques", body);
      if (response.status === 201) {
        setDisabledBtn1(false);
        setLoading1(false);
        toast.success("Qustions successfuly set");
        setAnswer1("");
        setAnswer2("");
        setAnswer3("");
        setQuestionn1("");
        setQuestionn2("");
        setQuestionn3("");
        setSecQuesPassword("");
        // window.location.reload();
        setRunGetUserAgain(true);
      }
    } catch (error) {
      setDisabledBtn1(false);
      setLoading1(false);
      console.log(error);
      if (error.message === "Network Error") {
        toast.error("Network error");
      } else if (error.response.data.response_code === "006") {
        toast.error("Add answer for all question fields");
      } else if (
        error.response.data.message === "Security Question Already Added"
      ) {
        toast.error("Security Question Already Added");
      } else if (
        error.response.data.message[0] === "password should not be empty"
      ) {
        toast.error("password should not be empty");
      } else {
        const msg = error.response.data.message;
        toast.error(msg);
      }
    }
  };
  const setSecurityQuestion = async () => {
    const body = {
      user_id: userID,
      email: userEmail,
      password: secQuesPassword,
      security_questions: [
        { id: 1, question: questionn1, answer: answer1 },
        { id: 2, question: questionn2, answer: answer2 },
        { id: 3, question: questionn3, answer: answer3 },
      ],
    };

    try {
      setDisabledBtn1(true);
      setLoading1(true);
      const response = await Service.post("/u/security/ques", body);
      if (response.status === 201) {
        setDisabledBtn1(false);
        setLoading1(false);
        toast.success("Qustions successfuly set");
        setAnswer1("");
        setAnswer2("");
        setAnswer3("");
        setQuestionn1("");
        setQuestionn2("");
        setQuestionn3("");
        setSecQuesPassword("");
        // window.location.reload();
        setRunGetUserAgain(true);
      }
    } catch (error) {
      setDisabledBtn1(false);
      setLoading1(false);
      console.log(error);
      if (error.message === "Network Error") {
        toast.error("Network error");
      } else if (error.response.data.response_code === "006") {
        toast.error("Add answer for all question fields");
      } else if (
        error.response.data.message === "Security Question Already Added"
      ) {
        toast.error("Security Question Already Added");
      } else if (
        error.response.data.message[0] === "password should not be empty"
      ) {
        toast.error("password should not be empty");
      } else {
        const msg = error.response.data.message;
        toast.error(msg);
      }
    }
  };

  const setTxnPin = async () => {
    const body = {
      email: userEmail,
      password: pinPassword,
      question_id: Number(seqQuestionID),
      answer: seqQuestionAnswer,
      new_txn_pin: Number(confirmNewPin),
    };

    if (newPin.length < 4) {
      toast.error(" New pin must me 4 digits");
    } else {
      if (newPin !== confirmNewPin) {
        toast.error("Pin does not match");
      } else {
        try {
          setLoading2(true);
          setDisabledBtn2(true);
          const response = await Service.patch("/user/txn/pin/reset", body);
          if (response.status === 200) {
            setLoading2(false);
            setDisabledBtn2(false);
            setConfirmNewPin("");
            setPinpassword("");
            setNewPin("");
            setSeqQuestionAnswer("");
            toast.success("Transaction pin successfully set");
          }
        } catch (error) {
          console.log(error);
          if (error.message === "Network Error") {
            toast.error("Network Error");
          } else if (
            error.response.data.message === "Invalid Email or Password"
          ) {
            toast.error(" Password not recorgnized");
          } else if (error.response.data.response_code === "004") {
            toast.error("Kindly set security Questions");
          } else if (error.response.data.response_code === "006") {
            toast.error("Answer should not be empty");
          } else {
            const erroMsg = error.response.data.message;
            console.log(error);
            toast.error(erroMsg);
          }

          setLoading2(false);
          setDisabledBtn2(false);
        }
      }
    }
  };
  const setVendorTxnPin = async () => {
    const body = {
      email: userEmail,
      password: pinPassword,
      question_id: Number(seqQuestionID),
      answer: seqQuestionAnswer,
      new_txn_pin: Number(confirmNewPin),
    };

    if (newPin.length < 4) {
      toast.error(" New pin must me 4 digits");
    } else {
      if (newPin !== confirmNewPin) {
        toast.error("Pin does not match");
      } else {
        try {
          setLoading2(true);
          setDisabledBtn2(true);
          const response = await VendorPostService.patch(
            "/merchant/auth/txn/pin/reset",
            body
          );
          if (response.status === 200) {
            setLoading2(false);
            setDisabledBtn2(false);
            setConfirmNewPin("");
            setPinpassword("");
            setNewPin("");
            setSeqQuestionAnswer("");
            toast.success("Transaction pin successfully set");
          }
        } catch (error) {
          console.log(error);
          if (error.message === "Network Error") {
            toast.error("Network Error");
          } else if (
            error.response.data.message === "Invalid Email or Password"
          ) {
            toast.error(" Password not recorgnized");
          } else if (error.response.data.message === "Invalid Credentials") {
            toast.error("Password not recorgnized");
          } else {
            const erroMsg = error.response.data.message;
            console.log(error);
            toast.error(erroMsg);
          }

          setLoading2(false);
          setDisabledBtn2(false);
        }
      }
    }
  };

  const fetchSecurityQuestions = async () => {
    try {
      setPageLoad(true);
      const res = await (userType === "buyer"
        ? service.get(`/u/security/ques/user/${userID}`)
        : vendorUseService.get(`/u/security/ques/user/${userID}`));
      if (res.status === 200) {
        setPageLoad(false);
        const allQuestions = res.data.data;
        const index = Math.floor(Math.random() * allQuestions.length);
        const result = allQuestions[index];
        setseqQuestion(result.question);
        setSeqQuestionID(result.id);
        setTxnpiN(true);
      }
    } catch (error) {
      console.log(error);
      if (error.message === "Network Error") {
        toast.error("Network Error");
      }
      setPageLoad(true);
    }
  };
  useEffect(() => {
    fetchSecurityQuestions();
  }, []);

  const handleButtonClick = () => {
    fetchSecurityQuestions();
    userType === "buyer" ? setSecurityQuestion() : setVendorSecurityQuestion();
  };

  useEffect(() => {
    if (oldPassword !== "" && newpassword !== "" && confirmPassword !== "") {
      setDisabledBtn(false);
    } else if (
      answer1 !== "" &&
      answer2 !== "" &&
      answer3 !== "" &&
      secQuesPassword !== ""
    ) {
      setDisabledBtn1(false);
    } else if (
      newPin !== "" &&
      confirmNewPin !== "" &&
      seqQuestionAnswer !== "" &&
      seqQuestion !== "" &&
      pinPassword !== ""
    ) {
      setDisabledBtn2(false);
    } else {
      setDisabledBtn(true);
      setDisabledBtn1(true);
      setDisabledBtn2(true);
    }
  }, [
    oldPassword,
    newpassword,
    confirmPassword,
    answer1,
    answer2,
    answer3,
    newPin,
    confirmNewPin,
    seqQuestionAnswer,
    seqQuestion,
    secQuesPassword,
    pinPassword,
  ]);
  useEffect(() => {
    const getDetails = async () => {
      if (userType === "buyer") {
        try {
          const response = await Service.get(`/user/${userID}`);
          if (response.status === 200) {
            const trxn = response.data.data.txn_pin;
            setTxnpiN(trxn);

            setpageLoading(false);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const response = await vendorUseService.get(
            `/merchant/users/${userID}`
          );
          if (response.status === 200) {
            const trxn = response.data.data.txn_pin;
            setTxnpiN(trxn);
            setpageLoading(false);
          }
        } catch (error) {
          console.log(error);
          if (error.message === "timeout exceeded") {
            toast.error("Timeout exceeded");
          } else if (error.message === "Network Error") {
            toast.error("Network error");
          }
        }
      }
    };
    if (runGetUserAgain) {
      getDetails();
    }

    getDetails();
  }, [runGetUserAgain]);

  return pageLoading ? (
    <div className="h-72 w-full flex justify-center pt-44">
      <PuffLoader color="#5271FF" speedMultiplier={0.8} />
    </div>
  ) : (
    <section className="pt-10">
      <div className="flex flex-col gap-20">
        <div className="border-b-[1px]">
          <div className=" flex  w-full  pb-10 gap-3">
            <div className="w-[40%]">
              <p className="text-[#232323] text-[500] text-[14px]">
                Your password
              </p>
              <p className="text-[#9B9697] text-[14px] font-[400] w-72 2xl:w-96">
                Please create a password that is 8 characters long and includes
                at least one special character and one uppercase letter.
              </p>
            </div>

            <div className="flex flex-col gap-4  w-full ">
              <div className=" w-[492px]">
                {/* old password */}
                <Textfield
                  labelStyle={"text-[14px] text-[#5C5959] font-[400]"}
                  labal={"Old password"}
                  type={"password"}
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                  }}
                  value={oldPassword}
                  placeholder={"•••••••••••"}
                />
              </div>
              {/* new password */}
              <div className=" w-[492px]">
                <Textfield
                  labelStyle={"text-[14px] text-[#5C5959] font-[400]"}
                  labal={" New password"}
                  type={"password"}
                  value={newpassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                  msg={NewPassworderrorMsg}
                  error={NewPasswordErrorIcon}
                  placeholder={"•••••••••••"}
                />
              </div>
              {/* confirm new password */}
              <div className=" w-[492px]">
                <Textfield
                  labal={"Confirm new password"}
                  type={"password"}
                  value={confirmPassword}
                  onChange={(e) => {
                    updateConfirmPassword(e.target.value);
                  }}
                  msg={confrimNewpasswordErrorMsg}
                  error={confrimNewpasswordErrIcon}
                  placeholder={"•••••••••••"}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end items-end pb-5">
            <div className="flex gap-4 justify-center w-[592px] ">
              <div className="w-1/2">
                <Button
                  onClick={() => {
                    setOldPassword("");
                    setNewPassword("");
                    updateConfirmPassword("");
                  }}
                  size="fullwidth"
                  variant="white"
                  touches={"h-[44px]"}
                  isBtnDisabled={disabledbtn}
                >
                  cancel
                </Button>
              </div>
              <div className="w-1/2">
                <Button
                  onClick={() => {
                    userType === "buyer"
                      ? ChangePassword()
                      : ChangePasswordVEndor();
                  }}
                  variant="authBlue"
                  size="fullwidth"
                  touches={"h-[44px]"}
                  isBtnDisabled={disabledbtn}
                >
                  {loading ? (
                    <ClockLoader
                      size={"30px"}
                      speedMultiplier={0.8}
                      color="#fafafa"
                    />
                  ) : (
                    "Save changes"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div id="SecQues" className="border-b-[1px]  pb-3">
          <div className=" flex  w-full  gap-3">
            <div className="w-[40%]">
              <p className="text-[#232323] text-[500] text-[14px]">
                Security Questions
              </p>
              <p className="text-[#9B9697] text-[14px] font-[400]">
                Keep your account save from others
              </p>
            </div>

            <div className="flex flex-col gap-4  w-full ">
              <div>
                <p className="text-[#9B9697] text-[14px] font-[500] tracking-wider w-72 2xl:w-96">
                  You are required to enter amswers for three security questions
                </p>
              </div>

              <div className="flex items-center gap-7 w-full ">
                <div>
                  <p className="text-[14px] text-[#5C5959] font-[400]">
                    Question 1
                  </p>
                  <MultipleSelect
                    personName={questionn1}
                    setPersonName={setQuestionn1}
                    data={useQuestionData}
                    title={"Choose Question"}
                  />
                </div>

                <div className=" w-[400px]">
                  <Textfield
                    onChange={(e) => {
                      setAnswer1(e.target.value);
                    }}
                    placeholder={"..."}
                    touches={""}
                    value={answer1}
                    labelStyle={"text-[14px] text-[#5C5959] font-[400]"}
                    labal={"Set answer"}
                    disabled={texnpiN === null ? false : true}
                  />
                </div>
              </div>

              <div className="flex items-center gap-7 ">
                <div>
                  <p className="text-[14px] text-[#5C5959] font-[400]">
                    Question 2
                  </p>
                  <MultipleSelect
                    personName={questionn2}
                    setPersonName={setQuestionn2}
                    data={dropDown2}
                    title={"Choose Question"}
                  />
                </div>

                <div className=" w-[400px]">
                  <Textfield
                    onChange={(e) => {
                      setAnswer2(e.target.value);
                    }}
                    placeholder={"..."}
                    touches={"w-96"}
                    value={answer2}
                    labelStyle={"text-[14px] text-[#5C5959] font-[400]"}
                    labal={"Set answer"}
                    disabled={texnpiN === null ? false : true}
                  />
                </div>
              </div>

              <div className="flex items-center gap-7 ">
                <div>
                  <p className="text-[14px] text-[#5C5959] font-[400]">
                    Question 3
                  </p>
                  <MultipleSelect
                    personName={questionn3}
                    setPersonName={setQuestionn3}
                    data={dropDown1}
                    title={"choose Question"}
                  />
                </div>

                <div className=" w-[400px]">
                  <Textfield
                    onChange={(e) => {
                      setAnswer3(e.target.value);
                    }}
                    placeholder={"..."}
                    touches={"w-96"}
                    value={answer3}
                    labelStyle={"text-[14px] text-[#5C5959] font-[400]"}
                    labal={"Set answer"}
                    disabled={texnpiN === null ? false : true}
                  />
                </div>
              </div>

              <div className=" w-[422px]">
                <Textfield
                  labelStyle={"text-[14px] text-[#5C5959] font-[400]"}
                  labal={"Password"}
                  type={"password"}
                  value={secQuesPassword}
                  onChange={(e) => {
                    setSecQuesPassword(e.target.value);
                  }}
                  placeholder={"•••••••••••"}
                  disabled={texnpiN === null ? false : true}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end items-end pt-10 pb-3">
            {texnpiN === null ? (
              <div className="flex gap-4 justify-center w-[592px] ">
                <div className="w-1/2">
                  <Button
                    onClick={() => {
                      setAnswer1("");
                      setAnswer2("");
                      setAnswer3("");
                      setQuestionn1("");
                      setQuestionn2("");
                      setQuestionn3("");
                      setSecQuesPassword("");
                    }}
                    size="fullwidth"
                    variant="white"
                    touches={"h-[44px]"}
                    isBtnDisabled={disabledbtn1}
                  >
                    cancel
                  </Button>
                </div>
                <div className="w-1/2">
                  <Button
                    id={"security"}
                    onClick={handleButtonClick}
                    variant="authBlue"
                    size="fullwidth"
                    touches={"h-[44px]"}
                    isBtnDisabled={disabledbtn1}
                  >
                    {loading1 ? (
                      <ClockLoader
                        size={"30px"}
                        speedMultiplier={0.8}
                        color="#fafafa"
                      />
                    ) : (
                      "Save changes"
                    )}
                  </Button>
                </div>
              </div>
            ) : (
              <Button
                variant="authBlue"
                size="small"
                touches={"h-[44px]"}
                isBtnDisabled={true}
              >
                User security questions already set
              </Button>
            )}
          </div>
        </div>

        <div className="border-b-[1px]  pb-5">
          <div className=" flex  w-full gap-3 ">
            <div className="w-[40%] ">
              <p className="text-[#232323] text-[500] text-[14px]">
                Transaction PIN
              </p>
              <p className="text-[#9B9697] text-[14px] font-[400]  w-72 2xl:w-92 ">
                Please create or change your 4-digit transaction PIN. This PIN
                will be used to authorize withdrawals from your account.
              </p>
            </div>

            <div className=" w-full">
              <div className="flex flex-col gap-4  w-full ">
                <div className=" w-[492px]">
                  <div className="pt-4 ">
                    <p className="text-[14px] text-[#5C5959] font-[400]">
                      New PIN
                    </p>
                    <ActOtp otp={newPin} setOtp={setNewPin} />
                  </div>
                </div>

                <div className=" w-[492px]">
                  <div className="pt-4 ">
                    <p className="text-[14px] text-[#5C5959] font-[400]">
                      Confirm new PIN
                    </p>
                    <ActOtp otp={confirmNewPin} setOtp={setConfirmNewPin} />
                  </div>
                </div>

                <div className="flex items-center gap-7">
                  <div className=" w-[400px]">
                    <Textfield
                      onChange={(e) => {
                        setseqQuestion(e.target.value);
                      }}
                      touches={`w-96 ${
                        pageLoad ? "text-gray-200" : "text-black"
                      } `}
                      value={pageLoad ? "Fetching question...." : seqQuestion}
                      labal={"Security question"}
                      labelStyle={"text-[14px] text-[#5C5959] font-[400]"}
                      disabled
                    />
                  </div>

                  <div className=" w-[300px] 2xl:w-[400px]">
                    <Textfield
                      onChange={(e) => {
                        setSeqQuestionAnswer(e.target.value);
                      }}
                      placeholder={"answer"}
                      touches={"w-96"}
                      labal={"Answer"}
                      labelStyle={"text-[14px] text-[#5C5959] font-[400]"}
                      value={seqQuestionAnswer}
                    />
                  </div>
                </div>

                <div className="w-[422px]">
                  <Textfield
                    labelStyle={"text-[14px] text-[#5C5959] font-[400]"}
                    labal={"Password"}
                    type={"password"}
                    value={pinPassword}
                    onChange={(e) => {
                      setPinpassword(e.target.value);
                    }}
                    placeholder={"•••••••••••"}
                  />
                </div>
              </div>

              <div className="flex justify-end items-center pt-10">
                <div>
                  <div>
                    <div className="flex gap-4 justify-center w-[592px] ">
                      <div className="w-1/2">
                        <Button
                          onClick={() => {
                            setConfirmNewPin("");
                            setPinpassword("");
                            setNewPin("");
                            setSeqQuestionAnswer("");
                          }}
                          size="fullwidth"
                          variant="white"
                          touches={"h-[44px]"}
                          isBtnDisabled={disabledbtn2}
                        >
                          cancel
                        </Button>
                      </div>
                      <div className="w-1/2">
                        <Button
                          onClick={() => {
                            userType === "buyer"
                              ? setTxnPin()
                              : setVendorTxnPin();
                          }}
                          variant="authBlue"
                          size="fullwidth"
                          touches={"h-[44px]"}
                          isBtnDisabled={disabledbtn2}
                        >
                          {loading2 ? (
                            <ClockLoader
                              size={"30px"}
                              speedMultiplier={0.8}
                              color="#fafafa"
                            />
                          ) : (
                            "Save changes"
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-7">
        <p
          onClick={() => {
            localStorage.clear();
            navigate("/auth");
            toast.success("log out success");
          }}
          className="text-[#E51837] text-[16px] font-[500] cursor-pointer"
        >
          Log out
        </p>
      </div>
    </section>
  );
};

export default AcctSecurity;
