import React, { useEffect, useState } from "react";
import lock from "../../../assets/imgs/lock.svg";
import profilePicture from "../../../assets/profilePictures/Avatar.svg";
import copy from "../../../assets/imgs/copy.svg";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { HashLoader } from "react-spinners";
import {
  formatCurrency,
  getFromStore,
  removeFromStore,
  saveInStore,
} from "../../../utils/helpers";
import { toast } from "react-toastify";
import useService, { getTokens } from "../../../service/useService";

export const SellerLinkAuthenticated = () => {
  const [showCopied, setShowCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [orderDetail, setOrderDetail] = useState(null);
  const [merchantDetail, setMerchantDetail] = useState(null);
  const navigate = useNavigate();

  const basUrl = process.env.REACT_APP_ODER_BASE_URL;
  const vendorBaseUrl = process.env.REACT_APP_VENDOR_BASE_URL;
  const Service = useService(basUrl);

  const vendorService = useService(vendorBaseUrl);

  const paymentLink = getFromStore("payment_link", "local") || null;
  const token = getTokens();
  const userId = token.userInfo.id;
  const userType = token.userInfo.user_type;

  useEffect(() => {
    const fetchData = async () => {
      const body = {
        payment_link: paymentLink,
      };

      try {
        const response = await Service.post(
          "/buyer/orders/validate/link",
          body
        );

        if (response.status === 201) {
          const detail = response.data.data.order_details;
          console.log(response.data.data);
          setOrderDetail(detail);
          setIsLoading(false);
        } else return;
      } catch (error) {
        const errmsg = error.response.data.message;
        if (errmsg === "Invalid Payment Link") {
          // navigate("/");
        }

        setIsLoading(false);
      }
    };

    if (userType === "VENDOR_SUPER_ADMIN") {
      const getMerchantDetails = async () => {
        const response = await vendorService.get(`/merchant/users/${userId}`);

        if (response.status === 200) {
          const marchantDetails = response.data.data.merchant_business;
          console.log(response.data.data);
          setMerchantDetail(marchantDetails);
        }
      };

      getMerchantDetails();
    }

    setIsLoading(false);
    fetchData();
  }, []);

  function copyToCipboard(value) {
    const result = navigator.clipboard.writeText(value);

    result.then(() => {
      setShowCopied(true);
      setTimeout(() => {
        setShowCopied(false);
      }, [1500]);
    });
  }
  // jsut a want to make a commit

  const handleUpdateOrder = async (status) => {
    const body = {
      order_id: orderDetail.id,
      order_ref: orderDetail.order_ref,
      merchant_business_name: merchantDetail.business_name,
      merchant_business_id: merchantDetail.merchant_id,
      order_accepted_status: status,
    };
    console.log(body);

    try {
      const response = await Service.post("/buyer/orders/accept/reject", body);

      if (response.status === 201) {
        if (status === "ACCEPTED") {
          toast.success("Order accepted");
        } else {
          toast.success("Order rejected");
        }
        setIsLoading(true);
        saveInStore("ifHasAcceptedOrder", response);
        removeFromStore("payment_link", "local");
        navigate("/");
      }

      console.log(response);
    } catch (error) {
      console.log(error);
      if (
        error.response.data.message === "Order Already Accepted By a Vendor"
      ) {
        toast.error("Order Already Accepted By a Vendor");
        removeFromStore("payment_link", "local");
        navigate("/");
      }
    }
  };

  console.log(orderDetail);
  // console.log(userType);

  if (isLoading) {
    return (
      <div className="h-screen w-full flex items-center justify-center">
        <HashLoader color="#5271FF" speedMultiplier={0.8} />
      </div>
    );
  }

  if (!orderDetail) {
    return <></>;
  }

  return (
    <section>
      <div className="flex justify-center flex-col items-center gap-3">
        <div className="flex items-center gap-3">
          <div>
            <img src={lock} alt="" />
          </div>
          <div>
            <p className="text-[12px] text-[#9B9697] ">
              Secured by{" "}
              <span className="text-[#5C5959] font-[500]">Vendyz</span>
            </p>
          </div>
        </div>

        <div className="flex justify-center flex-col items-center gap-4 2xl:gap-8">
          <div className="2xl:pt-4">
            <img src={profilePicture} alt="" className="w-28" />
          </div>
          <div className="text-center">
            <p className="text-[#232323] font-[700] text-[16px]">
              {/* {orderDetail.order_payment_history[0]}  */}
              Emmanuel Igwenagu wants to pay
            </p>
            <p className="text-[#232323] font-[700] text-[24px]">
              {formatCurrency(
                orderDetail.order_payment_history[0].amount_initiated
              )}
            </p>
          </div>
        </div>

        <div className="h-[156px] p-3 w-[35%] bg-[#FAFAFA] rounded-[25px] flex justify-center">
          <div className="flex items-center gap-3   justify-between  w-full 2xl:w-[70%]">
            <div className="flex items-center w-[40%]  gap-4">
              <div className="">
                <img
                  src={orderDetail.item_image_url}
                  alt=""
                  className="w-16 rounded-lg"
                />
              </div>

              <div>
                <p className="text-[500] text-[12px] text-[#5C5959] font-[500]">
                  {orderDetail.item_name}
                </p>
              </div>
            </div>

            <div className="flex justify-end border-l-2 w-[40%] p-4 ">
              <div className="flex flex-col items-center justify-center w-full gap-3">
                <div>
                  <p className="text-[12px] font-[700] ">
                    {formatCurrency(
                      orderDetail.order_payment_history[0].amount_initiated
                    )}
                  </p>
                </div>
                <div>
                  <p className="text-[12px] font-[500] text-[#5C5959] pb-2">
                    {orderDetail.order_ref}
                  </p>
                  <div
                    onClick={() => copyToCipboard(orderDetail.order_ref)}
                    className="cursor-pointer flex items-center relative"
                  >
                    <div>
                      <img src={copy} alt="" />
                    </div>
                    <div>
                      <p className="text-[#5271FF] text-[12px] pl-1">Copy</p>
                    </div>
                    {showCopied && (
                      <div className="text-sm text-bold absolute -bottom-8 tracking-wider text-white w-[80px]">
                        <p className="text-white  bg-indigo-400 rounded-lg py-1 px-3  w-full">
                          Copied!
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-4 justify-center w-[592px] pt-10">
          <div className="w-1/2">
            <Button
              onClick={() => handleUpdateOrder("DECLINED")}
              size="fullwidth"
              variant="white"
              touches={"h-[44px]"}
            >
              Reject Order
            </Button>
          </div>
          <div className="w-1/2">
            <Button
              onClick={() => {
                if (orderDetail.order_type === "VENDOR_ORDER") {
                  navigate("/generate-link/payment/card-payment");
                }

                if (orderDetail.order_type === "BUYER_ORDER") {
                  handleUpdateOrder("ACCEPTED");
                }
              }}
              variant="authBlue"
              size="fullwidth"
              touches={"h-[44px]"}
            >
              {orderDetail.order_type === "VENDOR_ORDER" &&
                "Proceed to payment"}

              {orderDetail.order_type === "BUYER_ORDER" && "Accept Order"}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
