/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import SelectInput from "../../../components/select-input";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import IdCardInputFile from "../../../components/idCardDragDrop";
import BvnINputField from "../../../components/BvnINputFIeld";
import useService, { getTokens } from "../../../service/useService";
import AWS from "aws-sdk";
import { toast } from "react-toastify";
import { licenseOption } from "../../../constants/localGvt";
import { ClockLoader, HashLoader, PuffLoader } from "react-spinners";
import Textfield from "../../../components/Textfield";
import createAxiosInstance from "../../../service";
import { getFromStore, saveInStore } from "../../../utils/helpers";
import { useAppContext } from "../../../context";

export const AcctDocs = () => {
  const [isUploading, setIsUploading] = useState(false);
  // const [retryProofOfAddressBtn, setRetryProofOfAddressBtn] =
  //   React.useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [sent, setSent] = useState(false);
  const [submitedDos, setSubmitedDocs] = React.useState(true);
  const [idNumber, setIdNumber] = useState("");
  const [proofOfAddress, setProfOfAddress] = useState(null);
  const [idPhoto, setIdphoto] = useState(null);
  const [btnDisabled, setBtnDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [addressDocDescription, setAddressDocDescription] = React.useState("");
  const [idType, setidType] = React.useState("");
  const [pageLoad, setPageLoad] = React.useState(true);
  const [getfiles, setGetFiles] = React.useState(null);
  const [approvedProofOFaddress, setApprovedProofOFaddress] =
    React.useState("");
  const [mycheck, setmycheck] = React.useState("");
  const [useidentificationDocs, setUseIdentificationDocs] = React.useState("");
  const [useIdType, setUseIDtype] = React.useState("");
  const [docsDescrition, setDocsDescription] = React.useState("");
  const [idVerificationStatus, setIdVerificationStatus] = React.useState("");
  const [idUrl, setIdUrl] = React.useState("");
  const [addressUrl, setAddressUrl] = React.useState("");
  const [mycheckID, setMyCheckID] = React.useState("");
  const [addressProfDoc, setaddressProofDoc] = React.useState("");
  const { setActiveAccount, setIfHasDocuments } = useAppContext();

  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const Service = useService(baseUrl);
  const service = createAxiosInstance(baseUrl);
  const merchant = getFromStore("merchant-B");

  const vendorUrl = process.env.REACT_APP_VENDOR_BASE_URL;
  const vendorUseService = useService(vendorUrl);
  const vendorPostService = createAxiosInstance(vendorUrl);
  const walletInfo = getFromStore("walletDetails");
  const bvn = walletInfo.data.data[0].bvn;

  const files = [];

  const token = getTokens();
  const main_id = getFromStore("main_id");
  const merchant_id = getFromStore("merchant_id");

  const MainId = main_id.id;
  const details = token.userInfo;
  const userID = details.id;
  const userType = details.user_type;
  const walletUrl = process.env.REACT_APP_WALLET_BASE_URL;
  const walletService = useService(walletUrl);

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  const s3 = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME },
  });

  const uploadFileToS3 = async (file, description) => {
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: `${file.name}/${description}`,
      Body: file,
    };
    try {
      setLoading(true);
      setBtnDisabled(true);

      const uploadResult = await s3.upload(params).promise();
      files.push(uploadResult.Location);
      if (files) {
        if (userType === "buyer") {
          try {
            const body = {
              user_id: userID,
              identification_doc: idType,
              proof_of_address_doc: addressDocDescription,
              identification_number: idNumber,
              identification_doc_url: files[0],
              proof_of_address_url: files[1],
            };

            setLoading(true);
            setBtnDisabled(true);
            const response = await service.patch(
              `/owner/docs/update/${MainId}`,
              body
            );
            if (response.status === 200) {
              setmycheck("pending");
              setMyCheckID("pending");
              setSubmitedDocs(false);
              setLoading(false);
              setBtnDisabled(true);
              toast.success("Upload successful");
              setIfHasDocuments(true);

              setActiveAccount(true);
            }
          } catch (error) {
            setLoading(false);
            setBtnDisabled(false);
            const errorMsg = error.response.data.message;
            toast.error(errorMsg);
            console.log(error);
          }
        } else {
          try {
            const body = {
              identification_doc: idType,
              proof_of_address_doc: addressDocDescription,
              identification_number: idNumber,
              identification_doc_url: files[0],
              proof_of_address_url: files[1],
              merchant_id: merchant_id,
            };

            const response = await vendorPostService.patch(
              `/merchant/owner/docs/update/${MainId}`,
              body
            );
            if (response.status === 200) {
              setmycheck("pending");
              setMyCheckID("pending");
              setSubmitedDocs(false);
              setLoading(false);
              setBtnDisabled(true);
              toast.success("Upload successful");
              setIfHasDocuments(true);

              setActiveAccount(true);
            }
          } catch (error) {
            setLoading(false);
            setBtnDisabled(false);
            const errorMsg = error.response.data.message;
            toast.error(errorMsg);
            console.log(error);
          }
        }
      }
    } catch (error) {
      console.error("Error uploading file to S3:", error);
      setLoading(false);
      setBtnDisabled(false);
      toast.error("Something went wrong try again");
      return null;
    }
  };

  const uploadPoa = async (file, description) => {
    if (addressDocDescription === "") {
      toast.error("add docment description");
    } else if (!proofOfAddress) {
      toast.error("upload document");
    } else {
      const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
        Key: `/${description}`,
        Body: file,
      };

      try {
        setIsUploading(true);
        // setRetryProofOfAddressBtn(true);

        const uploadResult = await s3.upload(params).promise();
        files.push(uploadResult.Location);
        if (files) {
          if (userType === "buyer") {
            try {
              const body = {
                user_id: userID,
                identification_doc: docsDescrition,
                proof_of_address_doc: addressDocDescription,
                identification_number: useIdType,
                identification_doc_url: useidentificationDocs,
                proof_of_address_url: uploadResult.Location,
              };

              const response = await service.patch(
                `/owner/docs/update/${MainId}`,
                body
              );
              if (response.status === 200) {
                setIsUploading(false);
                setmycheck("pending");

                toast.success(
                  "Successfull ! , we are reviewing your document "
                );
              }
            } catch (error) {
              setIsUploading(false);
              // setBtnDisabled(false);
              const errorMsg = error.response.data.message;
              toast.error(errorMsg);

              setIsUploading(false);
            }
          } else {
            const body = {
              identification_doc: docsDescrition,
              proof_of_address_doc: addressDocDescription,
              identification_number: useIdType,
              identification_doc_url: useidentificationDocs,
              proof_of_address_url: uploadResult.Location,
              merchant_id: merchant_id,
            };

            try {
              const response = await vendorPostService.patch(
                `/merchant/owner/docs/update/${MainId}`,
                body
              );
              if (response.status === 200) {
                console.log(response);
                setmycheck("pending");
                setIsUploading(false);
                toast.success(
                  "Successfull ! , we are reviewing your document "
                );
              }
            } catch (error) {
              setIsUploading(false);

              const errorMsg = error.response.data.message;
              toast.error(errorMsg);
              console.log(error);
              setIsUploading(false);
            }
          }
        }
      } catch (error) {
        console.error("Error uploading file to S3:", error);
        setLoading(false);
        setBtnDisabled(false);
        toast.error("Something went wrong try again");
        return null;
      }
    }
  };
  const uploadId = async (file, description) => {
    if (idType === "") {
      toast.error("Add ID type");
    } else if (!idPhoto) {
      toast.error("upload document");
    } else if (idNumber === "") {
      toast.error("ID number required");
    } else {
      const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
        Key: `/${description}`,
        Body: file,
      };

      try {
        setIsUploading(true);
        // setRetryProofOfAddressBtn(true);

        const uploadResult = await s3.upload(params).promise();
        files.push(uploadResult.Location);
        if (files) {
          // console.log(files);

          if (userType === "buyer") {
            try {
              const body = {
                user_id: userID,
                identification_doc: idType,
                proof_of_address_doc: addressProfDoc,
                identification_number: idNumber,
                identification_doc_url: uploadResult.Location,
                proof_of_address_url: addressUrl,
              };
              console.log(body);
              const response = await service.patch(
                `/owner/docs/update/${MainId}`,
                body
              );
              if (response.status === 200) {
                setMyCheckID("pending");
                setIsUploading(false);
                alert("Successfull ! , we are reviewing your document ");
              }
            } catch (error) {
              setIsUploading(false);
              // setBtnDisabled(false);
              const errorMsg = error.response.data.message;
              toast.error(errorMsg);
              console.log(error);
              setIsUploading(false);
            }
          } else {
            try {
              const body = {
                identification_doc: idType,
                proof_of_address_doc: addressProfDoc,
                identification_number: idNumber,
                identification_doc_url: uploadResult.Location,
                proof_of_address_url: addressUrl,
              };

              const response = await vendorPostService.patch(
                `/merchant/owner/docs/${MainId}`,
                body
              );
              if (response.status === 200) {
                setMyCheckID("pending");
                setIsUploading(false);
                alert("Successfull ! , we are reviewing your document ");
              }
            } catch (error) {
              setIsUploading(false);
              // setBtnDisabled(false);
              const errorMsg = error.response.data.message;
              toast.error(errorMsg);
              console.log(error);
              setIsUploading(false);
            }
          }
        }
      } catch (error) {
        console.error("Error uploading file to S3:", error);
        setLoading(false);
        setBtnDisabled(false);
        toast.error("Something went wrong try again");
        return null;
      }
    }
  };

  const postDocs = async (e) => {
    e.preventDefault();
    uploadFileToS3(proofOfAddress, "proof-of-Adress");
    uploadFileToS3(idPhoto, "id-proof");
  };

  useEffect(() => {
    if (
      proofOfAddress &&
      idPhoto &&
      idNumber !== "" &&
      idType !== "" &&
      addressDocDescription !== ""
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [proofOfAddress, idPhoto, idNumber, idType, addressDocDescription]);

  useEffect(() => {
    const fetch = async () => {
      if (userType === "buyer") {
        try {
          const res = await Service.get(`/owner/docs/user/${userID}`);
          if (res.status === 200) {
            setGetFiles(res.data.data);
            const idDocs = res.data.data.identification_doc_url;
            const idtype = res.data.data.identification_number;
            const DocsDescription = res.data.data.identification_doc;
            const addressUrl = res.data.data.proof_of_address_url;
            const idUrl = res.data.data.identification_doc_url;
            const idVerification = res.data.data.owner_docs_verification_status;
            const addressDosDescription = res.data.data.proof_of_address_doc;
            setIdVerificationStatus(idVerification);
            setIdUrl(idUrl);
            setAddressUrl(addressUrl);
            setaddressProofDoc(addressDosDescription);

            const verifiedProofOfAddress =
              res.data.data.proof_of_address_verification_status;
            if (verifiedProofOfAddress === "VERIFIED" && addressUrl !== null) {
              setmycheck("Verified");
            } else if (
              addressUrl !== null &&
              verifiedProofOfAddress === "NOT_VERIFIED"
            ) {
              setmycheck("pending");
            } else if (
              addressUrl !== null &&
              verifiedProofOfAddress === "DISAPPROVED"
            ) {
              setmycheck("declined");
            }
            if (idVerification === "VERIFIED" && idUrl !== null) {
              setMyCheckID("Verified");
            } else if (idUrl !== null && idVerification === "NOT_VERIFIED") {
              setMyCheckID("pending");
            } else if (idUrl !== null && idVerification === "DISAPPROVED") {
              setMyCheckID("declined");
            }
            setApprovedProofOFaddress(verifiedProofOfAddress);

            setUseIdentificationDocs(idDocs);
            setUseIDtype(idtype);
            setDocsDescription(DocsDescription);
            // console.log(new Date(res.data.data.updated_at) > Date.now());
            setPageLoad(false);
          }
        } catch (error) {
          console.log(error);
          if (error.message === "timeout exceeded") {
            toast.error("Network timeout");
            setPageLoad(true);
          } else if (error.message === "Network Error") {
            toast.error("Network Error");
            setPageLoad(true);
          } else {
            console.log(error);
            setPageLoad(true);
          }
        }
      } else {
        try {
          const res = await vendorUseService.get(
            `/merchant/owner/docs/merchant/${merchant_id}`
          );
          if (res.status === 200) {
            console.log(res);
            setGetFiles(res.data.data);
            const idDocs = res.data.data.identification_doc_url;
            const idtype = res.data.data.identification_number;
            const DocsDescription = res.data.data.identification_doc;
            const addressUrl = res.data.data.proof_of_address_url;
            const idUrl = res.data.data.identification_doc_url;
            const idVerification = res.data.data.owner_docs_verification_status;
            const addressDosDescription = res.data.data.proof_of_address_doc;
            setIdVerificationStatus(idVerification);
            setIdUrl(idUrl);
            setAddressUrl(addressUrl);
            setaddressProofDoc(addressDosDescription);

            const verifiedProofOfAddress =
              res.data.data.proof_of_address_verification_status;
            if (verifiedProofOfAddress === "VERIFIED" && addressUrl !== null) {
              setmycheck("Verified");
            } else if (
              addressUrl !== null &&
              verifiedProofOfAddress === "NOT_VERIFIED"
            ) {
              setmycheck("pending");
            } else if (
              addressUrl !== null &&
              verifiedProofOfAddress === "DISAPPROVED"
            ) {
              setmycheck("declined");
            }
            if (idVerification === "VERIFIED" && idUrl !== null) {
              setMyCheckID("Verified");
            } else if (idUrl !== null && idVerification === "NOT_VERIFIED") {
              setMyCheckID("pending");
            } else if (idUrl !== null && idVerification === "DISAPPROVED") {
              setMyCheckID("declined");
            }
            setApprovedProofOFaddress(verifiedProofOfAddress);

            setUseIdentificationDocs(idDocs);
            setUseIDtype(idtype);
            setDocsDescription(DocsDescription);
            setPageLoad(false);
          }
        } catch (error) {
          console.log(error);
          if (error.message === "timeout exceeded") {
            toast.error("Network timeout");
            setPageLoad(true);
          } else if (error.message === "Network Error") {
            toast.error("Network Error");
            setPageLoad(true);
          } else {
            console.log(error);
            setPageLoad(true);
          }
        }
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    const getWallet = async () => {
      try {
        const getAllUsers = await walletService.get("/wallet/user/types");

        if (getAllUsers.status === 200) {
          if (userType === "buyer") {
            try {
              const balance = await walletService.get(
                `/wallet/s/all?user_id=${userID}&user_type=USER`
              );
              if (balance.status === 200) {
                saveInStore("walletDetails", balance);
                setPageLoading(false);
              }
            } catch (error) {
              console.log(error);
            }
          } else {
            try {
              const balance = await walletService.get(
                `/wallet/s/all?user_id=${userID}&merchant_user_id=${merchant.merchant_id}&merchant_business_id=${merchant.id}&user_type=MERCHANT`
              );
              if (balance.status === 200) {
                saveInStore("walletDetails", balance);
                setPageLoading(false);
              }
            } catch (error) {
              console.log(error);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getWallet();
  }, []);

  if (pageLoading) {
    return (
      <div className="h-72 w-full flex justify-center pt-44">
        <PuffLoader color="#5271FF" speedMultiplier={0.8} />
      </div>
    );
  }

  const truncateBVN = (value) => {
    const data = value.slice(-3);
    return `******** ${data}`;
  };

  return pageLoad ? (
    <div className="h-72 w-full flex justify-center pt-44">
      <PuffLoader color="#5271FF" speedMultiplier={0.8} />
    </div>
  ) : (
    <section className="pt-10">
      <div className="flex flex-col gap-20">
        <div className="flex w-full border-b-[1px] pb-10 gap-3 items-center ">
          <div className="w-[40%]">
            <p className="text-[#232323] text-[500] text-[14px] py-2">
              Bank Verification Number (BVN)
            </p>
            <p className="text-[#9B9697] text-[14px] font-[400] w-96">
              Enter your 11-digit BVN. To get it, dial 5650# from the mobile
              number linked to your bank account.
            </p>
          </div>

          {!sent ? (
            <div>
              {bvn === null ? (
                <div>
                  <BvnINputField setCheck={setSent} />
                </div>
              ) : (
                <div>
                  {" "}
                  <input
                    className={
                      "text-[14px] text-[#5C5959] font-[400] bg-[#F4F4F4] border-[1px] border-[#F0F0F0] rounded-[20px] items-center gap-2  outline-none hover:shadow-[#F0F0F0] h-[50px] px-10 w-[400px] tracking-widest "
                    }
                    type={"text"}
                    value={truncateBVN(bvn)}
                    disabled
                  />
                </div>
              )}
            </div>
          ) : (
            // eslint-disable-next-line react/jsx-no-comment-textnodes
            <div className="flex items-center flex-col-reverse gap-1">
              <div className="text-slate-800 text-[18px] font-[700]">
                Submited{" "}
              </div>

              <iframe
                height={70}
                src="https://lottie.host/embed/ce20ba91-89d0-44b0-aacb-e3d2bfa6dd0d/gMWVrV8td1.json"
                // src="https://lottie.host/embed/1d55f937-7533-46d4-9ceb-260d8595b7a7/0C7xT5eZY3.json"
              ></iframe>
            </div>
          )}
        </div>

        <div className="w-full border-b-[1px] pb-10">
          <div className="flex w-full items-center gap-3">
            <div className="w-[40%]">
              <p className="text-[#232323] text-[500] text-[14px] py-2">
                Proof of address
              </p>
              <p className="text-[#9B9697] text-[14px] font-[400]  w-96">
                Please upload a document that shows your name and current
                address. Accepted documents include utility bills (electricity,
                water, gas), bank statements, or government-issued documents
                (e.g., tax statements, residence permits).
              </p>
            </div>
            {submitedDos ? (
              <div>
                {approvedProofOFaddress === "NOT_VERIFIED" &&
                addressUrl !== null &&
                mycheck === "pending" ? (
                  <div>
                    <p className="bg-[#F4F4F4] text-[#232323] px-5 py-2 rounded-[100px] ">
                      Not verified
                    </p>
                  </div>
                ) : approvedProofOFaddress === "DISAPPROVED" &&
                  addressUrl !== null &&
                  mycheck === "pending" ? (
                  <div>
                    <p className="bg-[#F4F4F4] text-[#232323] px-5 py-2 rounded-[100px] ">
                      Not verified
                    </p>
                  </div>
                ) : approvedProofOFaddress === "DISAPPROVED" &&
                  addressUrl !== null &&
                  mycheck === "declined" ? (
                  <div className="flex items-center gap-6 flex-row-reverse">
                    <div>
                      <button
                        onClick={() => {
                          setmycheck("retry");
                        }}
                        className="text-[#5271FF] font-[400] bg-white px-4 py-2 rounded-[100px] shadow-md hover:bg-gray-100 border-[1px] border-[#fafafa]"
                      >
                        Retry
                      </button>
                    </div>
                    <p className="bg-[#FFE8E8] text-[#EF5959] px-5 py-2 rounded-[100px]  border-[#FFBBBB] border-[1px]">
                      Disapproved
                    </p>
                  </div>
                ) : approvedProofOFaddress === "VERIFIED" &&
                  addressUrl !== null ? (
                  <div>
                    <p className="bg-[#EFFFF6] text-[#449E6A] px-5 py-2 rounded-[100px] border[1px] border-[#83F3B2] border-[1px] ">
                      APPROVED
                    </p>
                  </div>
                ) : approvedProofOFaddress === "DISAPPROVED" &&
                  addressUrl !== null &&
                  mycheck === "retry" ? (
                  <div className="w-[520px] flex flex-col gap-10">
                    <div>
                      <Textfield
                        labelStyle={"text-[14px] text-[#5C5959] font-[400]"}
                        type={"text"}
                        labal={"Document description"}
                        placeholder={"Utility bill"}
                        value={addressDocDescription}
                        onChange={(e) => {
                          setAddressDocDescription(e.target.value);
                        }}
                      />
                    </div>

                    <IdCardInputFile
                      id={"card-2"}
                      selectedFile={proofOfAddress}
                      setSelectedFile={setProfOfAddress}
                      handleSubmit={uploadFileToS3}
                    />

                    <div className="flex justify-end ">
                      <button
                        onClick={() => {
                          uploadPoa(proofOfAddress, "proof-of-Adress");
                        }}
                        className={`text-[#5271FF] font-[400]  px-4 py-2 rounded-[100px] shadow-md hover:bg-gray-100 border-[1px] border-[#fafafa] cursor-pointer bg-white `}
                      >
                        {isUploading ? (
                          <HashLoader size={20} color="#5271FF" />
                        ) : (
                          "Upload"
                        )}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="w-[520px] flex flex-col gap-10">
                    <div>
                      <Textfield
                        labelStyle={"text-[14px] text-[#5C5959] font-[400]"}
                        type={"text"}
                        labal={"Document description"}
                        placeholder={"Utility bill"}
                        value={addressDocDescription}
                        onChange={(e) => {
                          setAddressDocDescription(e.target.value);
                        }}
                      />
                    </div>

                    <IdCardInputFile
                      id={"card-2"}
                      selectedFile={proofOfAddress}
                      setSelectedFile={setProfOfAddress}
                      handleSubmit={uploadFileToS3}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div>
                <p className="bg-[#F4F4F4] text-[#232323] px-5 py-2 rounded-[100px] ">
                  Not verified
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="flex w-full border-b-[1px] pb-10 gap-3 ">
          <div className="w-[40%]">
            <p className="text-[#232323] text-[500] text-[14px] py-2">
              ID Card
            </p>
            <p className="text-[#9B9697] text-[14px] font-[400] w-96">
              Upload a clear photo or scan of your valid ID card (e.g.,
              passport, driver's license, national ID card).
            </p>
          </div>
          {submitedDos ? (
            <div>
              {idUrl !== null &&
              idVerificationStatus === "NOT_VERIFIED" &&
              mycheckID === "pending" ? (
                <div>
                  <p className="bg-[#F4F4F4] text-[#232323] px-5 py-2 rounded-[100px] ">
                    Not verified
                  </p>
                </div>
              ) : idUrl !== null &&
                idVerificationStatus === "DISAPPROVED" &&
                mycheckID === "pending" ? (
                <div>
                  <p className="bg-[#F4F4F4] text-[#232323] px-5 py-2 rounded-[100px] ">
                    Not verified
                  </p>
                </div>
              ) : idUrl === null && idVerificationStatus === "NOT_VERIFIED" ? (
                <div className="flex flex-col gap-10 w-[520px]">
                  <div className="w-full">
                    <SelectInput
                      label={"ID Type"}
                      onChange={(e) => {
                        setidType(e.target.value);
                      }}
                      labelStyle={
                        "text-[14px] text-[#5C5959] font-[400] w-full"
                      }
                      options={licenseOption}
                      value={idType}
                    />
                  </div>

                  <div>
                    <Textfield
                      labelStyle={"text-[14px] text-[#5C5959] font-[400]"}
                      type={"text"}
                      labal={"ID Number"}
                      placeholder={"333-444-555"}
                      onChange={(e) => {
                        setIdNumber(e.target.value);
                      }}
                      value={idNumber}
                    />
                  </div>

                  <div className="w-full">
                    <IdCardInputFile
                      id={"card-1"}
                      selectedFile={idPhoto}
                      setSelectedFile={setIdphoto}
                      handleSubmit={uploadFileToS3}
                    />
                  </div>
                </div>
              ) : idUrl !== null && idVerificationStatus === "VERIFIED" ? (
                <div>
                  <p className="bg-[#EFFFF6] text-[#449E6A] px-5 py-2 rounded-[100px] border[1px] border-[#83F3B2] border-[1px] ">
                    APPROVED
                  </p>
                </div>
              ) : idVerificationStatus === "DISAPPROVED" &&
                idUrl !== null &&
                mycheckID === "declined" ? (
                <div className="flex items-center gap-6 flex-row-reverse">
                  <div>
                    <button
                      onClick={() => {
                        setMyCheckID("retry");
                      }}
                      className="text-[#5271FF] font-[400] bg-white px-4 py-2 rounded-[100px] shadow-md hover:bg-gray-100 border-[1px] border-[#fafafa]"
                    >
                      Retry
                    </button>
                  </div>
                  <p className="bg-[#FFE8E8] text-[#EF5959] px-5 py-2 rounded-[100px]  border-[#FFBBBB] border-[1px]">
                    Disapproved
                  </p>
                </div>
              ) : idVerificationStatus === "DISAPPROVED" &&
                idUrl !== null &&
                mycheckID === "retry" ? (
                <div className="w-[520px] flex flex-col gap-10">
                  <div className="w-full">
                    <SelectInput
                      label={"ID Type"}
                      onChange={(e) => {
                        setidType(e.target.value);
                      }}
                      labelStyle={
                        "text-[14px] text-[#5C5959] font-[400] w-full"
                      }
                      options={licenseOption}
                      value={idType}
                    />
                  </div>

                  <div>
                    <Textfield
                      labelStyle={"text-[14px] text-[#5C5959] font-[400]"}
                      type={"number"}
                      labal={"ID Number"}
                      placeholder={"333-444-555"}
                      onChange={(e) => {
                        setIdNumber(e.target.value);
                      }}
                      value={idNumber}
                    />
                  </div>

                  <div className="w-full">
                    <IdCardInputFile
                      id={"card-1"}
                      selectedFile={idPhoto}
                      setSelectedFile={setIdphoto}
                      handleSubmit={uploadFileToS3}
                    />
                  </div>

                  <div className="flex justify-end ">
                    <button
                      onClick={() => {
                        uploadId(idPhoto, "id-proof");
                      }}
                      className={`text-[#5271FF] font-[400]  px-4 py-2 rounded-[100px] shadow-md hover:bg-gray-100 border-[1px] border-[#fafafa] cursor-pointer bg-white `}
                    >
                      {isUploading ? (
                        <HashLoader size={20} color="#5271FF" />
                      ) : (
                        "Upload"
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="w-[520px] flex flex-col gap-10">
                  <div className="w-full">
                    <SelectInput
                      label={"ID Type"}
                      onChange={(e) => {
                        setidType(e.target.value);
                      }}
                      labelStyle={
                        "text-[14px] text-[#5C5959] font-[400] w-full"
                      }
                      options={licenseOption}
                      value={idType}
                    />
                  </div>

                  <div>
                    <Textfield
                      labelStyle={"text-[14px] text-[#5C5959] font-[400]"}
                      type={"text"}
                      labal={"ID Number"}
                      placeholder={"333-444-555"}
                      onChange={(e) => {
                        setIdNumber(e.target.value);
                      }}
                      value={idNumber}
                    />
                  </div>

                  <div className="w-full">
                    <IdCardInputFile
                      id={"card-1"}
                      selectedFile={idPhoto}
                      setSelectedFile={setIdphoto}
                      handleSubmit={uploadFileToS3}
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div>
              <p className="bg-[#F4F4F4] text-[#232323] px-5 py-2 rounded-[100px] ">
                Not verified
              </p>
            </div>
          )}
        </div>
        <div className="flex justify-between items-center">
          <div>
            <p
              onClick={() => {
                localStorage.clear();
                navigate("/auth");
                toast.success("log out success");
              }}
              className="text-[#E51837] text-[16px] font-[500] cursor-pointer"
            >
              Log out
            </p>
          </div>
          <div>
            <div>
              <div className="flex gap-4 justify-center w-[592px] ">
                <div className="w-1/2">
                  <Button
                    isBtnDisabled={btnDisabled}
                    size="fullwidth"
                    variant="white"
                    touches={"h-[44px]"}
                  >
                    cancel
                  </Button>
                </div>
                <div className="w-1/2">
                  <Button
                    onClick={postDocs}
                    variant="authBlue"
                    size="fullwidth"
                    touches={"h-[44px]"}
                    isBtnDisabled={btnDisabled}
                  >
                    {loading ? (
                      <ClockLoader
                        size={"30px"}
                        speedMultiplier={0.8}
                        color="#fafafa"
                      />
                    ) : (
                      "Save changes"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
