import React from "react";
import Textfield from "../../../components/Textfield";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../../assets/imgs/Logo.svg";
import Button from "../../../components/Button";
import { useEffect } from "react";
import createAxiosInstance from "../../../service";
import { ClockLoader } from "react-spinners";
import { toast } from "react-toastify";

const ForgottenPassword = () => {
  const navigate = useNavigate();
  const params = useParams();
  const token = params.req_token;

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const Service = createAxiosInstance(baseUrl);

  const [email, updateEmail] = React.useState("");
  const [disabledbtn, setDisabledBtn] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  //  monitor input fields
  useEffect(() => {
    function checkField() {
      if (email !== "") {
        setDisabledBtn(false);
      } else {
        setDisabledBtn(true);
      }
    }
    checkField();
  }, [email]);

  const validate = async () => {
    if (email !== "") {
      const body = {
        email: email,
      };

      try {
        setDisabledBtn(true);
        setLoading(true);
        const response = await Service.post("/auth/forgot/password", body);
        if (response.status === 200) {
          navigate(`/auth/${email}/${token}`);
        }
      } catch (error) {
        // const erroMsg = error.response.data.message;
        console.log(error);
        setDisabledBtn(false);
        setLoading(false);
        // toast.error(erroMsg);
      }
    } else {
      return null;
    }
  };

  return (
    <section className="w-full flex justify-center  h-full">
      <div>
        <div className="flex justify-center">
          <img src={logo} alt="" className="" />
        </div>
        <div className="pt-6">
          <div className="flex justify-center">
            <h1 className="text-[27px] font-[700] text-[#232323]">
              Recover password
            </h1>
          </div>

          <div className="flex justify-center pt-2">
            <p className="text-[#9B9697] text-[16px] text-[400]">
              Please provide your email and we’ll send you a code.
            </p>
          </div>
        </div>
        <form className="flex flex-col gap-5 w-[496px] pt-6 ">
          <Textfield
            labal={"Email"}
            type={"text"}
            value={email}
            onChange={(e) => {
              updateEmail(e.target.value);
            }}
            placeholder={"example@gmail.com"}
          />

          <div className="pt-12">
            <Button
              isBtnDisabled={disabledbtn}
              variant="authBlue"
              touches={"h-[44px]"}
              onClick={(e) => {
                validate();
                e.preventDefault();
              }}
            >
              {loading ? (
                <ClockLoader
                  size={"30px"}
                  speedMultiplier={0.8}
                  color="#fafafa"
                />
              ) : (
                "Continue"
              )}
            </Button>
            <div className="pt-7 w-full justify-center">
              <div className="  w-full">
                <p className="text-[#5C5959] text-[400] text-[16px] text-center">
                  New user?
                  <span
                    onClick={() => navigate("/auth/register")}
                    className="text-[#5271FF] pl-5 cursor-pointer"
                  >
                    Create an account
                  </span>
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ForgottenPassword;
