export const lagosLocalGovernmentsLagos = [
  { label: "Agege", value: "Agege" },
  { label: "Ajeromi-Ifelodun", value: "Ajeromi-Ifelodun" },
  { label: "Alimosho", value: "Alimosho" },
  { label: "Amuwo-Odofin", value: "Amuwo-Odofin" },
  { label: "Apapa", value: "Apapap" },
];

export const countriesNigeria = [
  { label: "Nigeria", value: "Nigeria" },
  { label: "Ghana", value: "Ghana" },
];

export const statesNigeria = [
  { label: "Abia", value: "Abia" },
  { label: "Adamawa", value: "Adamawa" },
  { label: "Akwa Ibom", value: "Akwa Ibom" },
  { label: "Anambra", value: "Anambra" },
];

export const licenseOption = [
  {
    label: "Voters Card",
    value: "Voters Card",
  },
  {
    label: "National ID",
    value: "National ID",
  },
  {
    label: "Drivers Licence ",
    value: "Drivers Licence",
  },
];
