import React, { useState } from "react";
import MajorTitle from "../../../components/MajorTitle";
import lock from "../../../assets/imgs/lock.svg";
import productImg from "../../../assets/imgs/Ellipse 2858.svg";
import copy from "../../../assets/imgs/copy.svg";
import { TbPencilMinus } from "react-icons/tb";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import btnLock from "../../../assets/imgs/lock (1).svg";

export const ReviewPaymnet = () => {
  const [showCopied, setShowCopied] = React.useState(false);
  const [itemID, setItemId] = React.useState("VD5477956P");
  const [btnDisabled, setBtnDisabled] = useState(false);

  const navigate = useNavigate();

  function copyToCipboard(value) {
    const result = navigator.clipboard.writeText(value);

    result.then(() => {
      setShowCopied(true);
      setTimeout(() => {
        setShowCopied(false);
      }, [1500]);
    });
  }

  return (
    <section className="h-full p ">
      <div>
        <div>
          <MajorTitle title={"Review your payment "} />
        </div>
        <div className="flex justify-center w-full flex-col items-center">
          <div className="flex justify-center flex-col items-center gap-3 w-[592px]">
            <div className="flex items-center gap-3">
              <div>
                <img src={lock} alt="" />
              </div>
              <div>
                <p className="text-[12px] text-[#9B9697] ">
                  Secured by
                  <span className="text-[#5C5959] font-[500]">Vendyz</span>
                </p>
              </div>
            </div>

            <div className="text-center pt-5 flex flex-col items-center gap-2 pb-4">
              <p className="text-[#9B9697] font-[400] text-[16px]">
                Total amount
              </p>
              <p className="text-[#232323] font-[700] text-[24px]">
                ₦200,000.00
              </p>
            </div>

            <div className="h-[156px] p-3 w-[592px] bg-[#FAFAFA] rounded-[25px] flex justify-center">
              <div className="flex items-center gap-3   justify-between 2xl:px-10 px-5">
                <div className="flex items-center w-[60%] justify-between gap-3">
                  <div className="rounded">
                    <img src={productImg} alt="" className="w-56" />
                  </div>

                  <div>
                    <p className="text-[500] text-[12px] text-[#5C5959] font-[500]">
                      iPhone 15 Pro Max - 6.7Inch - 526 GB ROM, 6GB RAM iOS 18-
                      Midnight Titanium design Ceramic Shield front Textured
                      matte glass back
                    </p>
                  </div>
                </div>

                <div className="flex justify-end border-l-2 w-[30%] ">
                  <div className="flex flex-col items-center justify-center w-full gap-3">
                    <div>
                      <p className="text-[12px] font-[700] ">₦200,000.00</p>
                    </div>
                    <div>
                      <p className="text-[12px] font-[500] text-[#5C5959] pb-2">
                        {itemID}
                      </p>
                      <div
                        onClick={() => copyToCipboard(itemID)}
                        className="cursor-pointer flex items-center relative"
                      >
                        <div>
                          <img src={copy} alt="" />
                        </div>
                        <div>
                          <p className="text-[#5271FF] text-[12px] pl-1">
                            Copy
                          </p>
                        </div>
                        {showCopied && (
                          <div className="text-sm text-bold absolute -bottom-8 tracking-wider text-white w-[80px]">
                            <p className="text-white  bg-indigo-400 rounded-lg py-1 px-3  w-full">
                              Copied!
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full flex flex-col items-center gap-4">
              <div className="border-b-[1px] border-[#F0F0F0] w-full pb-3">
                <div className="flex justify-between ">
                  <div>
                    <p className="text-[#232323] font-[700] text-[16px] ">
                      Payment
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/generate-link/payment/cardPayment");
                    }}
                    className="flex items-center gap-2 cursor-pointer"
                  >
                    <span>
                      <TbPencilMinus color="#5271FF" />
                    </span>{" "}
                    <span className="text-[#5271FF] text-sm">Edit</span>
                  </div>
                </div>
              </div>

              <div className=" border-[#F0F0F0] w-full pb-3 pt-8 px-5">
                <div className="flex justify-between ">
                  <div className="w-1/2  ">
                    <p className="text-[#9B9697] font-[400] text-[14px] ">
                      Name on card
                    </p>
                  </div>

                  <div className="w-1/2 ">
                    <p className="text-[#232323] text-[16px] font-[500]">
                      Emmanuel Israel
                    </p>
                  </div>
                </div>
              </div>

              <div className=" border-[#F0F0F0] w-full pb-3 pt-4 px-5">
                <div className="flex justify-between ">
                  <div className="w-1/2  ">
                    <p className="text-[#9B9697] font-[400] text-[14px] ">
                      Expiry date
                    </p>
                  </div>

                  <div className="w-1/2 ">
                    <p className="text-[#5C5959] text-[16px] font-[500]">
                      12/24
                    </p>
                  </div>
                </div>
              </div>

              <div className=" border-[#F0F0F0] w-full pb-3 pt-4 px-5">
                <div className="flex justify-between ">
                  <div className="w-1/2  ">
                    <p className="text-[#9B9697] font-[400] text-[14px] ">
                      CVV
                    </p>
                  </div>

                  <div className="w-1/2 ">
                    <p className="text-[#5C5959] text-[16px] font-[500]">•••</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full pt-7">
              <div className="border-b-[1px] border-[#F0F0F0] w-full pb-3">
                <div className="flex justify-between ">
                  <div>
                    <p className="text-[#232323] font-[700] text-[16px] ">
                      Seller’s details
                    </p>
                  </div>
                </div>
              </div>

              <div className=" border-[#F0F0F0] w-full pb-3 pt-8 px-5">
                <div className="flex justify-between ">
                  <div className="w-1/2  ">
                    <p className="text-[#9B9697] font-[400] text-[14px] ">
                      Name
                    </p>
                  </div>

                  <div className="w-1/2 ">
                    <p className="text-[#232323] text-[16px] font-[500]">
                      Mooti Gadgets
                    </p>
                  </div>
                </div>
              </div>

              <div className=" border-[#F0F0F0] w-full pb-3 pt-4 px-5">
                <div className="flex justify-between ">
                  <div className="w-1/2  ">
                    <p className="text-[#9B9697] font-[400] text-[14px] ">
                      phone Number
                    </p>
                  </div>

                  <div className="w-1/2 ">
                    <p className="text-[#5C5959] text-[16px] font-[500]">
                      +2347097783230
                    </p>
                  </div>
                </div>
              </div>

              <div className=" border-[#F0F0F0] w-full pb-3 pt-4 px-5">
                <div className="flex justify-between ">
                  <div className="w-1/2  ">
                    <p className="text-[#9B9697] font-[400] text-[14px] ">
                      Email
                    </p>
                  </div>

                  <div className="w-1/2 ">
                    <p className="text-[#5C5959] text-[16px] font-[500]">
                      mootigadgets@gmail.com
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex gap-4 justify-center w-[592px] pb-4 pt-20 ">
              <div className="w-1/2">
                <Button
                  onClick={() => {
                    navigate("/generate-link/payment");
                  }}
                  size="fullwidth"
                  variant="white"
                  touches={"h-[44px]"}
                >
                  Back
                </Button>
              </div>
              <div className="w-1/2">
                <Button
                  onClick={() => {
                    navigate("/generate-link/payment/payment-confirmed");
                  }}
                  variant="authBlue"
                  size="fullwidth"
                  touches={"h-[44px]"}
                  isBtnDisabled={btnDisabled}
                >
                  <div className="flex justify-center items-center">
                    <div className="flex items-center gap-2">
                      <div>
                        <img src={btnLock} alt="" />
                      </div>
                      <div className="text-white">Securely pay</div>
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
