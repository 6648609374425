import React, { useEffect } from "react";
import { BuyerLinkGenration } from "./buyerLink-generation";
import { VendorLinkGeneration } from "./vendorLinkGeneration";
import { useAppContext } from "../../../context";
import { getFromStore } from "../../../utils/helpers";

export const PaymetLInkGeneration = () => {
  const [userView, setUserVIew] = React.useState(false);
  const [vendorView, setVendorVIew] = React.useState(false);

  const details = getFromStore("vendyz_user");
  const userType = details.user_type;

  useEffect(() => {
    if (userType === "buyer") {
      setUserVIew(true);
      setVendorVIew(false);
    } else {
      setUserVIew(false);
      setVendorVIew(true);
    }
  }, [userType]);
  return (
    <div className="h-full ">
      {userView && <BuyerLinkGenration />}
      {vendorView && <VendorLinkGeneration />}
    </div>
  );
};
