import React from "react";
import sort from "../../assets/imgs/sort.svg";
import searchIcon from "../../assets/imgs/search-normal.svg";

const TableFilter = ({ title, onChange }) => {
  return (
    <div className="flex justify-between py-7 px-6  items-center pt-9">
      <div>
        <h1 className="text-[16px] text-[#5C5959] font-semibold">{title}</h1>
      </div>

      <div className="relative flex items-center  gap-9">
        <div className=" flex items-center shadow-lg rounded-[100px]">
          <div className="  h-[39px]   flex items-center  justify-center w-14  rounded-l-[100px] border-[#F0F0F0] bg-[#FFFFFF] shadow-sm cursor-pointer">
            <img src={searchIcon} alt="" />
          </div>

          <input
            placeholder="Search..."
            type="text"
            onChange={onChange}
            className=" rounded-[100px] w-[290px] h-[39px] flex justify-between  items-center rounded-l-none border-[#F0F0F0] bg-[#FFFFFF] shadow-sm outline-none"
          />
        </div>
        <div className=" rounded-[100px] w-[92px] h-[39px] flex  items-center shadow-lg justify-center gap-2">
          <div>
            <img src={sort} alt="" />
          </div>
          <div>
            <h1 className="text-[#5C5959] font-semibold text-sm ">Filter</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableFilter;
