import avatar1 from "../../assets/profilePictures/Avatar.svg";
import avatar2 from "../../assets/imgs/dummiProfileicon.svg";

export const TransactionData = [
  {
    id: 1,
    profilePicture: avatar1,
    AccounTname: "Ebiye Sango",
    amount: "₦200,000.00",
    referenceNo: "REF4715384150",
    date: "Jul 08, 2023 • 9:00 AM",
    status: "debit",
    actionReq: false,
  },
  {
    id: 2,
    profilePicture: avatar2,
    AccounTname: "Inem Atiku",
    amount: "₦200,000.00",
    referenceNo: "REF4715384150",
    date: "Jul 08, 2023 • 9:00 AM",
    status: "credit",
    actionReq: false,
  },
  {
    id: 3,
    profilePicture: avatar1,
    AccounTname: "Ebiye Sango",
    amount: "₦200,000.00",
    referenceNo: "REF4715384150",
    date: "Jul 08, 2023 • 9:00 AM",
    status: "debit",
    actionReq: false,
  },
  {
    id: 4,
    profilePicture: avatar2,
    AccounTname: "Inem Atiku",
    amount: "₦200,000.00",
    referenceNo: "REF4715384150",
    date: "Jul 08, 2023 • 9:00 AM",
    status: "credit",
    actionReq: false,
  },
];
