import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import logo from "../assets/imgs/Logo.svg";
import arrowRight from "../assets/imgs/arrow-right (2).svg";
import loginImg from "../assets/imgs/loginImg.svg";
import useService from "../service/useService";
import { HashLoader } from "react-spinners";

const UnAuthenticated = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-neutral-50 h-screen ">
      <div className=" h-full">
        <div className="h-[10%] xl:h-[100px]  w-full bg-neutral-50 flex justify-start pl-7 items-center cursor-pointer">
          <div>
            <img src={logo} alt="" />
          </div>
        </div>
        <div className="flex bg-neutral-50 h-[90%] xl:h-[calc(100%-100px)]">
          <div className=" h-full w-[10%]"></div>
          <div className=" px-14 pt-6 2xl:pt-12 bg-white w-full rounded-tl-[50px]  ">
            <section className="w-full h-full">
              <div className="flex  h-full pt-0 2xl:pt-16 ">
                <div
                  style={{ backgroundImage: `url(${loginImg})` }}
                  className="w-[40%] rounded-t-[20px] h-full bg-cover bg-no-repeat"
                ></div>
                <div className=" w-[60%] h-full overflow-auto ">
                  <Outlet />
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnAuthenticated;
