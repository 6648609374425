import React from "react";

const MajorTitle = ({ title, subtitle, space }) => {
  return (
    <div className="pb-8">
      <h1 className="text-[24px] text-[#232323] font-extrabold capitalize ">
        {title}
      </h1>
      <p className={`text-[#9B9697] tracking-wider ${space}`}>{subtitle}</p>
    </div>
  );
};

export default MajorTitle;
