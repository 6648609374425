import React from "react";
import { Outlet } from "react-router-dom";

const Page = ({ children }) => {
  return <div>{children ? children : <Outlet />}</div>;
};

export const Withdraw = () => {
  return <Page />;
};
