export const validateFirstname = (value) => {
  if (value === "") {
    return "First name cannot be empty";
  }

  return true;
};

export const validateMiddlename = (value) => {
  if (value === "") {
    return "Middle name cannot be empty";
  }

  return true;
};

export const validateLastname = (value) => {
  if (value === "") {
    return "Last name cannot be empty";
  }

  return true;
};

export const validatePhoneNumber = (value) => {
  if (value === "") {
    return "Phone number cannot be empty";
  }

  return true;
};

export const validateEmail = (value) => {
  const trimmedValue = value;
  if (trimmedValue === "") {
    return "Email address cannot be empty";
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!emailRegex.test(trimmedValue)) {
    return "Please enter a valid email address";
  }

  return true;
};

export const validateDateOfBirth = (value) => {
  const trimmedValue = value;

  if (trimmedValue === "") {
    return "Date of birth cannot be empty";
  }

  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

  if (!dateRegex.test(trimmedValue)) {
    return "Please enter a valid date of birth in YYYY-MM-DD format";
  }

  const inputDate = new Date(trimmedValue);

  const currentDate = new Date();

  if (inputDate > currentDate) {
    return "Date of birth cannot be in the future";
  }

  return true;
};

export const validateStrongPassword = (password) => {
  const minLength = 8;

  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;
  const numberRegex = /[0-9]/;
  const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

  if (password.length < minLength) {
    return `Password should be at least ${minLength} characters long`;
  }

  if (!uppercaseRegex.test(password)) {
    return "Password should contain at least one uppercase letter";
  }

  if (!lowercaseRegex.test(password)) {
    return "Password should contain at least one lowercase letter";
  }

  if (!numberRegex.test(password)) {
    return "Password should contain at least one number";
  }

  if (!specialCharRegex.test(password)) {
    return "Password should contain at least one special character";
  }

  return true;
};

export const validateOTP = (value) => {
  const otpRegex = /^\d+$/;

  if (!otpRegex.test(value)) {
    return "OTP should contain only numbers";
  }

  if (value.length !== 4) {
    return "OTP should be 4 digits long";
  }

  return true;
};
