import React from "react";
import TableFilter from "../../../components/Table-filter";
import { TablE } from "../../../components/ReuableTable";
import { UnpaidlinksData } from "../../../constants/Unpaid-linksData";

export const UnPaidLinksTable = () => {
  const tableHeadData = [
    {
      id: 1,
      name: "Name",
      Link: "Link",
      amount: "Amount",
      "Date Created": "Date",
      status: "Status",
    },
  ];
  return (
    <div className="flex flex-col gap-3">
      <div>
        <TableFilter title={"All orders"} />
      </div>
      <div>
        <TablE
          tHeaders={tableHeadData}
          tRows={UnpaidlinksData}
          variant={"allLinks"}
        />
      </div>
    </div>
  );
};
