import React, { useEffect } from "react";
import logo from "../../../../assets/imgs/Logo.svg";
import Textfield from "../../../../components/Textfield";
import Button from "../../../../components/Button";
import plusIcon from "../../../../assets/imgs/add-circle.svg";
import { useNavigate } from "react-router-dom";
import SelectInput from "../../../../components/select-input";

export const BuyerLinkPrevAcct = () => {
  const navigate = useNavigate();

  const [transactionID, setTransactionId] = React.useState("");
  const [paymentDate, setPaymentDate] = React.useState("");
  const [paymentOption, setPaymentOption] = React.useState("");
  const [paymentAmount, setPaymentAmount] = React.useState("");

  const [btnDisabled, setBtnDisabled] = React.useState(true);
  function getDetails() {
    const userInfo = {
      transctionId: "",
      userAmount: "",
      userPaymntDate: "",
      userPaymntType: "",
    };
    userInfo.transctionId = transactionID;
    userInfo.userAmount = paymentAmount;
    userInfo.userPaymntDate = paymentDate;
    userInfo.userPaymntType = paymentOption;

    console.log(userInfo);
  }

  useEffect(() => {
    if (
      transactionID !== "" &&
      paymentAmount !== "" &&
      paymentDate !== "" &&
      paymentOption !== ""
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [transactionID, paymentAmount, paymentDate, paymentOption]);

  return (
    <section className="">
      <div>
        <div className="flex justify-center">
          <img src={logo} alt="" className="" />
        </div>
        <div className="pt-2">
          <div className="flex justify-center">
            <h1 className="text-[27px] font-[700] text-[#232323]">
              Link previous transactions (If any)
            </h1>
          </div>

          <div className="flex justify-center">
            <p className="text-[#9B9697] text-[16px] text-center text-[400]">
              To view previous transactions that occurred before account
              creation, please provide the necessary details.
            </p>
          </div>
        </div>
        <div className="flex justify-center pt-12">
          <form className="w-[500px] flex flex-col gap-4 ">
            <Textfield
              labal={"Transaction ID"}
              placeholder={"1234566VD"}
              type={"text"}
              onChange={(e) => setTransactionId(e.target.value)}
              value={transactionID}
            />

            <Textfield
              labal={"Payment amount"}
              placeholder={"0.00"}
              type={"number"}
              value={paymentAmount}
              onChange={(e) => {
                setPaymentAmount(e.target.value);
              }}
            />
            <Textfield
              labal={"Payment date"}
              placeholder={"DD/MM/YY"}
              type={"date"}
              value={paymentDate}
              onChange={(e) => {
                setPaymentDate(e.target.value);
              }}
            />

            <SelectInput
              label={"Select option"}
              onChange={(e) => {
                setPaymentOption(e.target.value);
              }}
              value={paymentOption}
            >
              <option value="">Select option</option>
              <option value="Card payment" className="text-[#9B9697]">
                Card payment
              </option>
              <option value="Bank deposite" className="text-[#9B9697]">
                Bank deposite
              </option>
            </SelectInput>
            <div className="flex gap-6 items-center pt-5">
              <div>
                <img src={plusIcon} alt="" />
              </div>
              <div>
                <h1 className="text-[#5271FF] text-sm">Add new transaction</h1>
              </div>
            </div>

            <div className="pt-5 pb-8">
              <Button
                touches={"h-[44px]"}
                onClick={(e) => {
                  navigate("/auth/register/buyer/contact-information");
                  e.preventDefault();
                  getDetails();
                }}
                variant="authBlue"
                isBtnDisabled={btnDisabled}
              >
                Continue
              </Button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
