import React, { useEffect, useState } from "react";
import { allinksData } from "../../../constants/all-linksData";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import MajorTitle from "../../../components/MajorTitle";
import arrowRight from "../../../assets/imgs/arrow-right (2).svg";
import { GetLinkDataTable } from "../../../components/GetLinksDataTable";
import product2 from "../../../assets/imgs/Ellipse 2858 (1).svg";
import bankLogo from "../../../assets/imgs/bankLogo.svg";
import dummiProfileImg from "../../../assets/imgs/dummiProfileicon.svg";
import mooiIcon from "../../../assets/imgs/mooiIcon.svg";
import { GetDataTable } from "../../../components/GetDataTable";
import { PaymentInfoTable } from "../../../components/paymentInfoTable";
import Button from "../../../components/Button";
import { getFromStore } from "../../../utils/helpers";
import useService from "../../../service/useService";
import { toast } from "react-toastify";
import { PuffLoader } from "react-spinners";

export const LinkCOnfirm = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [allLinksData, setallLinksData] = useState([]);
  const [pageLoad, setPageLoad] = useState(true);

  const details = getFromStore("vendyz_user");
  const userID = details.id;
  const userType = details.user_type;
  const basUrl = process.env.REACT_APP_ODER_BASE_URL;
  const Service = useService(basUrl);
  const merchant_id = getFromStore("merchant_id");

  useEffect(() => {
    const getDetails = async () => {
      try {
        const response =
          userType === "buyer"
            ? await Service.get(`/buyer/orders/user/${userID}`)
            : await Service.get(`/vendor/orders/vendor/${merchant_id}`);
        if (response.status === 200) {
          console.log(response);
          const data = response.data.data.data;
          setallLinksData(data);
          setPageLoad(false);
        }
      } catch (error) {
        if (error.message === "timeout exceeded") {
          toast.error("Timeout exceeded");
        } else if (error.message === "Network Error") {
          toast.error("Network error");
        }
      }
    };

    getDetails();
  }, []);

  const linkData = allLinksData.find(
    (link) => link.id === Number(params.linksConfirmation)
  );

  const tableHeadData = [
    {
      id: 1,
      link: "Link",
      Amount: "Amount",
      "Date Created": "Date Created",
      "Expired Date": "Expired Date",
      status: "Status",
    },
  ];

  const data = {
    id: 1,
    productImg: product2,
    productName: "iPhone 15 Pro Max 526 GB",
    companyName: "Mooi Gadgets",
    amount: "₦200,000.00",
    orderId: "VD5477956P",
    date: "Jul 08, 2023 • 9:00 AM",
    status: "completed",
    actionReq: false,
  };

  const transactionTableHead = [
    {
      id: 1,
      "Payment to": "Payment to",
      "Payment from": "Payment from",
      "Payment amount": "Payment amount",
    },
  ];

  const reciepientInfo = {
    phoneNumber: "+2347097783230",
    logo: mooiIcon,
    email: "mootigadgets@gmail.com",
  };

  const senderDetails = {
    referenceNumber: "23548868VD",
    bankName: "ECOBANK PLC",
    banklogo: bankLogo,
    phoneNumber: "+2348048740391",
    profilepicture: dummiProfileImg,
  };

  if (pageLoad) {
    return (
      <div className="h-72 w-full flex justify-center pt-44">
        <PuffLoader color="#5271FF" speedMultiplier={0.8} />
      </div>
    );
  }

  return (
    <section>
      <div className="">
        <div
          onClick={() => navigate("/paymentLinks")}
          className=" rounded-[100px] w-[101px] h-[40px] flex  items-center shadow-sm justify-center gap-2 border-[1px] border-[#F0F0F0] cursor-pointer "
        >
          <div>
            <img src={arrowRight} alt="" />
          </div>
          <div>
            <h1 className="text-[#5C5959] font-semibold text-sm ">Back</h1>
          </div>
        </div>

        <div className="pt-10 pl-2">
          <MajorTitle title={"Details"} />
        </div>

        <div className="border-b-[1px] pb-12">
          <GetLinkDataTable tableHeadData={tableHeadData} data={linkData} />
        </div>

        <div className="border-b-[1px] pb-12">
          <GetDataTable
            tableHeadData={tableHeadData}
            data={linkData}
            variant={false}
          />
        </div>

        <div className="w-full pt-14 pb-14 ">
          <PaymentInfoTable
            data={linkData}
            tHeaders={transactionTableHead}
            recipeientPhoneNum={reciepientInfo.phoneNumber}
            recipientEmail={reciepientInfo.email}
            recipientLogo={reciepientInfo.logo}
            senderBankLogo={senderDetails.banklogo}
            senderPhoneNumber={senderDetails.phoneNumber}
            senderBankName={senderDetails.bankName}
            senderReferenceNum={senderDetails.referenceNumber}
            senderProfilePicture={senderDetails.profilepicture}
          />
        </div>

        <div>
          <Button variant="white" size="small">
            Share reciept
          </Button>
        </div>
      </div>
    </section>
  );
};
