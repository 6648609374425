import React from "react";
import MajorTitle from "../../../components/MajorTitle";
import { HiOutlineXMark } from "react-icons/hi2";
import Button from "../../../components/Button";
import linkLogo from "../../../assets/imgs/paymentSenrIcon.svg";

import { useNavigate } from "react-router-dom";

export const ConfirmedPayment = () => {
  const amountSent = "₦200,000.00";
  const vendorName = "Mooti Gadgets";

  const navigate = useNavigate();
  return (
    <div className="absolute top-0 left-0 h-screen w-full bg-[#F3F5FD] rounded-tl-[50px] ">
      <div className="pt-12  pl-10">
        <div
          onClick={() => {
            navigate("/generate-link/payment");
          }}
          className=" rounded-[100px] w-[101px] h-[40px] flex  items-center shadow-sm justify-center gap-2 border-[1px] border-[#F0F0F0] cursor-pointer  bg-white"
        >
          <div>
            <HiOutlineXMark color="#9B9697" size={25} />
          </div>
          <div>
            <h1 className="text-[#5C5959] font-semibold text-sm ">Close</h1>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center px-4  flex-col pt-2 2xl:pt-12">
        <div>
          <img src={linkLogo} alt="" />
        </div>
        <div className="pt-7">
          <MajorTitle title={"Money sent"} />
        </div>
        <div className="pt-3">
          <p className="text-sm text-[#9B9697] tracking-wide text-center">
            Sit back and relax. Your payment o
            <span className=" px-2 bg text-[#232323] text-[16px] font-[700]">
              {amountSent}
            </span>
            will be released to <br />
            <span className=" px-2 bg text-[#232323] text-[16px] font-[700]">
              {vendorName}
            </span>
            when you receive your order.
          </p>
        </div>

        <div className="w-full 2xl:pt-24  pt-14 pb-5  flex justify-center">
          <div>
            <div className="flex gap-4 justify-center w-[592px] ">
              <div className="w-1/2">
                <Button size="fullwidth" variant="white" touches={"h-[44px]"}>
                  share link
                </Button>
              </div>
              <div className="w-1/2">
                <Button
                  onClick={() => {
                    navigate("/auth");
                  }}
                  variant="authBlue"
                  size="fullwidth"
                  touches={"h-[44px]"}
                >
                  Log in to view details
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
