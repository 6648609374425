import React, { useState } from "react";

const CharacterCounter = ({ text, maxLength }) => {
  const remainingChars = maxLength - text.length;

  return (
    <div className="text-sm text-gray-500">
      {remainingChars} characters left
    </div>
  );
};

const TextArea = ({
  description,
  setDescription,
  counterMaxLength,
  maxiumum,
  placeholder,
  counter,
  label,
}) => {
  return (
    <div>
      <label htmlFor="textArea" className="pt-3">
        <p className="text-[14px] font-[400] text-[#5C5959] pb-1">{label}</p>
      </label>
      <div>
        <textarea
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          value={description}
          minLength="4"
          maxLength={maxiumum}
          placeholder={placeholder}
          name=""
          id="textArea"
          cols="30"
          rows="4"
          className="text-sm w-full border-[2px] border-[#F0F0F0] rounded-[20px] outline-none p-2 resize-none tracking-wide"
        ></textarea>
        {counter && (
          <CharacterCounter text={description} maxLength={counterMaxLength} />
        )}
      </div>
    </div>
  );
};

export default TextArea;
