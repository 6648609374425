import React, { useEffect, useState } from "react";
import Button from "../../../components/Button";
import UploadAcctImage from "../../../components/acctUPloadImage";
import Textfield from "../../../components/Textfield";
import { useNavigate } from "react-router-dom";
import { InternationalPhone } from "../../../components/international-phone-input";
import useService, { getTokens } from "../../../service/useService";
import { toast } from "react-toastify";
import { ClockLoader, PuffLoader } from "react-spinners";
import createAxiosInstance from "../../../service";
import { getFromStore } from "../../../utils/helpers";
import { useAppContext } from "../../../context";
import { Country } from "country-state-city";
import { City, State } from "country-state-city";
import Selector from "../../../components/contry-select-input";

// :U2FsdGVkX1%2Bfu7WlS%2BRDzj6bKOBYnGjBhQ85ULI525ZXj4k55EfGVEljNQ%2FZZ9%2BI

const AcctDetails = () => {
  const basUrl = process.env.REACT_APP_BASE_URL;
  const Service = createAxiosInstance(basUrl);
  const service = useService(basUrl);
  const vendorBaseUrl = process.env.REACT_APP_VENDOR_BASE_URL;
  const vendorPostService = createAxiosInstance(vendorBaseUrl);
  const token = getTokens();

  const vendorUseService = useService(vendorBaseUrl);

  const details = token.userInfo;
  const userID = details.id;
  const userType = details.user_type;

  const countryData = Country.getAllCountries();
  const [pageLoad, setPageLoad] = React.useState(true);
  const [uploadedImage, setUploadedImage] = React.useState(null);
  const [firstName, setFirstName] = React.useState(details.first_name);
  const [lastname, setLastname] = React.useState(details.last_name);
  const [businessName, setBusinessName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [adress, setAddress] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [state, setState] = React.useState("");
  const [email, setEmail] = React.useState(details.email);
  const [btnDisabled, setBtnDisabled] = React.useState(true);
  const [loading, setloading] = React.useState(false);
  const [addressPlaceHolder, setAddressPlaceHolder] = React.useState("");
  const [phonePlaceHolder, setPhonePlaceHolder] = React.useState("");
  const [countryPlaceHolder, setCountryPlaceHolder] = React.useState("");
  const [statePlaceHolder, setStatePlaceHolder] = React.useState("");
  const [localGovPlaceHolder, setLocalGovPlaceHolder] = React.useState("");
  const [city, setCity] = useState();
  const [stateData, setStateData] = useState();
  const [cityData, setCityData] = useState();

  const navigate = useNavigate();

  const UserPostDocs = async () => {
    const body = {
      user_id: userID,
      user_avatar_url: details.user_avatar_url,
      city: city.name,
      contact_address: adress,
      state: state.name,
      country: country.name,
      marital_status: details.marital_status,
      gender: details.gender,
      user_token: details.user_token,
      account_status: details.account_status,
      phone: details.phone,
    };
    const body2 = {
      address: adress,
      user_avertar_url: details.user_avatar_url,
      city: city.name,
      state: state.name,
      country: country.name,
    };

    if (userType === "buyer") {
      try {
        setloading(true);
        setBtnDisabled(true);
        const response = await Service.patch(`/user/${userID}`, body);

        if (response.status === 200) {
          setloading(false);
          setBtnDisabled(false);
          toast.success("Upload successful");
        }
      } catch (error) {
        if (error.message === "timeout exceeded") {
          toast.error("Network timeout");
          // setPageLoad(true);
        } else if (error.message === "Network Error") {
          toast.error("Network Error");
          // setPageLoad(true);
        } else {
          toast.error("something went wrong");
        }
        setloading(false);
        setBtnDisabled(false);
      }
    } else {
      try {
        setloading(true);
        setBtnDisabled(true);
        const response = await vendorPostService.patch(
          `/merchant/users/${userID}`,
          body2
        );

        if (response.status === 200) {
          setloading(false);
          setBtnDisabled(true);
          toast.success("Upload successful");
        }
      } catch (error) {
        console.log(error);
        if (error.message === "timeout exceeded") {
          toast.error("Network timeout");
          // setPageLoad(true);
        } else if (error.message === "Network Error") {
          toast.error("Network Error");
          // setPageLoad(true);
        }
        setloading(false);
        setBtnDisabled(false);
        // setPageLoad(true);
        // toast.error("something went wrong");
      }
    }
  };

  useEffect(() => {
    const getDetails = async () => {
      if (userType === "buyer") {
        try {
          const response = await Service.get(`/user/${userID}`);
          if (response.status === 200) {
            setUploadedImage(response.data.data.user_avatar_url);
            setAddressPlaceHolder(response.data.data.contact_address);
            setPhoneNumber(response.data.data.phone);

            setCountryPlaceHolder(response.data.data.country);
            setStatePlaceHolder(response.data.data.state);
            setLocalGovPlaceHolder(response.data.data.city);
            setPageLoad(false);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const response = await vendorUseService.get(
            `/merchant/users/${userID}`
          );
          if (response.status === 200) {
            setBusinessName(response.data.data.merchant_business.business_name);
            setUploadedImage(response.data.data.user_avertar_url);
            setAddressPlaceHolder(response.data.data.address);
            setPhoneNumber(response.data.data.phone);
            setCountryPlaceHolder(response.data.data.country);
            setStatePlaceHolder(response.data.data.state);
            setLocalGovPlaceHolder(response.data.data.city);
            // const bussness = getFromStore("merchant_bussness");
            // console.log(bussness);

            // setBusinessName(bussnessName)
            setPageLoad(false);
          }
        } catch (error) {
          console.log(error);
          if (error.message === "timeout exceeded") {
            toast.error("Timeout exceeded");
          } else if (error.message === "Network Error") {
            toast.error("Network error");
          }
        }
      }
    };
    getDetails();
  }, []);

  useEffect(() => {
    if (adress !== "" && country !== "" && state !== "" && city !== "") {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [firstName, lastname, phoneNumber, adress, country, state, city]);

  useEffect(() => {
    setStateData(State.getStatesOfCountry(country?.isoCode));
  }, [country]);

  useEffect(() => {
    setCityData(City.getCitiesOfState(country?.isoCode, state?.isoCode));
  }, [state, country]);

  useEffect(() => {
    stateData && setState(stateData[0]);
  }, [stateData]);

  useEffect(() => {
    cityData && setCity(cityData[0]);
  }, [cityData]);

  return pageLoad ? (
    <div className="h-72 w-full flex justify-center pt-44">
      <PuffLoader color="#5271FF" speedMultiplier={0.8} />
    </div>
  ) : (
    <section className="pt-10">
      <div className="flex flex-col gap-20">
        <div className=" flex  w-full border-b-[1px]  pb-10">
          <div className="w-[40%]">
            <p className="text-[#232323] text-[500] text-[14px]">Your photo</p>
            <p className="text-[#9B9697] text-[14px] font-[400]">
              This will show on your profile.
            </p>
          </div>
          <div>
            <div>
              <UploadAcctImage
                setUploadedImage={setUploadedImage}
                uploadedImage={uploadedImage}
              />
            </div>
          </div>
        </div>

        <div className=" flex  w-full border-b-[1px]  pb-10">
          <div className="w-[40%]">
            <p className="text-[#232323] text-[500] text-[14px]">Your Name</p>
            <p className="text-[#9B9697] text-[14px] font-[400]">
              Only your basic information can be updated.
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex items-center justify-center gap-4 w-full">
              <div className="w-[250px]">
                <Textfield
                  labelStyle={"text-[14px] text-[#5C5959] font-[400]"}
                  type={"text"}
                  labal={"First name"}
                  placeholder={"Emmanuel"}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  value={firstName}
                  className={"bg-[#F6F6F6]"}
                  disabled
                />
              </div>

              <div className="w-[250px]">
                <Textfield
                  labelStyle={"text-[14px] text-[#5C5959] font-[400]"}
                  className={"bg-[#F6F6F6]"}
                  type={"text"}
                  labal={"Last name"}
                  placeholder={"Israel"}
                  value={lastname}
                  onChange={(e) => {
                    setLastname(e.target.value);
                  }}
                  disabled
                />
              </div>
            </div>

            {userType !== "buyer" ? (
              <div>
                <Textfield
                  labelStyle={"text-[14px] text-[#5C5959] font-[400]"}
                  type={"text"}
                  labal={"Bussness name"}
                  placeholder={"Vendyz"}
                  value={businessName}
                  onChange={(e) => {
                    setBusinessName(e.target.value);
                  }}
                  disabled
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className=" flex  w-full border-b-[1px]  pb-10">
          <div className="w-[40%]">
            <p className="text-[#232323] text-[500] text-[14px]">
              Contact information
            </p>
            <p className="text-[#9B9697] text-[14px] font-[400]">
              This information verifies you and lets us reach out to you.
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <div>
              <Textfield
                labelStyle={"text-[14px] text-[#5C5959] font-[400]"}
                className={"bg-[#F6F6F6]"}
                type={"mail"}
                labal={"Email"}
                placeholder={"emmanuel@gmail.com"}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                disabled
              />
            </div>

            <div className="text-[14px] text-[#5C5959] font-[400]">
              <div className="">Phone number</div>
              <InternationalPhone
                disabled={true}
                phone={phoneNumber}
                setPhone={setPhoneNumber}
                placeHolder={phonePlaceHolder}
              />
            </div>

            <div>
              <Textfield
                labelStyle={"text-[14px] text-[#5C5959] font-[400]"}
                type={"text"}
                labal={"Address"}
                placeholder={addressPlaceHolder}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                value={adress}
              />
            </div>

            <div>
              <Selector
                placeholder={countryPlaceHolder}
                label={"Country"}
                selected={country}
                setSelected={setCountry}
                data={countryData}
              />
            </div>

            <div className="flex items-center justify-center gap-4 w-full">
              <div className="w-[250px]">
                <Selector
                  placeholder={statePlaceHolder}
                  label={"State"}
                  selected={state}
                  setSelected={setState}
                  data={stateData}
                />
              </div>

              <div className="w-[250px]">
                <Selector
                  placeholder={localGovPlaceHolder}
                  label={"City"}
                  selected={city}
                  setSelected={setCity}
                  data={cityData}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div>
            <p
              onClick={() => {
                localStorage.clear();
                navigate("/auth");
                toast.success("log out success");
              }}
              className="text-[#E51837] text-[16px] font-[500] cursor-pointer"
            >
              Log out
            </p>
          </div>
          <div>
            <div>
              <div className="flex gap-4 justify-center w-[592px] ">
                <div className="w-1/2">
                  <Button
                    // onClick={() => {
                    //   navigate("/");
                    // }}
                    size="fullwidth"
                    variant="white"
                    touches={"h-[44px]"}
                    isBtnDisabled={btnDisabled}
                  >
                    cancel
                  </Button>
                </div>
                <div className="w-1/2">
                  <Button
                    onClick={() => {
                      // getDocs();
                      //   navigate("/");
                      UserPostDocs();
                    }}
                    variant="authBlue"
                    size="fullwidth"
                    touches={"h-[44px]"}
                    isBtnDisabled={btnDisabled}
                  >
                    {" "}
                    {loading ? (
                      <ClockLoader
                        size={"30px"}
                        speedMultiplier={0.8}
                        color="#fafafa"
                      />
                    ) : (
                      "Save changes"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AcctDetails;
