import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const AccountLinkNavBar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className="pt-10 ">
      <div className="bg-[#F6F6F6] rounded-[100px] w-[290px] h-[39px] flex justify-between  items-center p-3 ">
        <div
          onClick={() => navigate("/account")}
          className={` ${
            pathname === "/account"
              ? "bg-[#FFFFFF] font-semibold shadow-sm rounded-[100px] text-[#232323] p-2"
              : ""
          } hover:bg-[#FFFFFF] hover:font-semibold hover:shadow-sm hover:rounded-[100px] h-[35px] w-[50%] text-center pt-1 hover:text-[#232323] text-[#9B9697] duration-200 cursor-pointer p-2`}
        >
          <p className={`${pathname === "/account" ? " text-[#232323]" : ""}`}>
            Details
          </p>
        </div>

        <div
          onClick={() => {
            navigate("/account/security");
          }}
          className={` ${
            pathname === "/account/security"
              ? "bg-[#FFFFFF] shadow-sm rounded-[100px] text-[#232323] font-semibold flex items-center p-2"
              : ""
          }flex items-center hover:bg-[#FFFFFF] hover:shadow-sm hover:rounded-[100px] h-[35px] w-[50%] text-center justify-center gap-1 cursor-pointer hover:text-[#232323] text-[#9B9697] hover:font-semibold p-2`}
        >
          <p
            className={`${
              pathname === "/account/security" ? " text-[#232323]" : ""
            }`}
          >
            Security
          </p>
        </div>
        <div
          onClick={() => {
            navigate("/account/documents");
          }}
          className={` ${
            pathname === "/account/documents"
              ? "bg-[#FFFFFF] shadow-sm rounded-[100px] text-[#232323] font-semibold flex items-center p-2"
              : ""
          }flex items-center hover:bg-[#FFFFFF] hover:shadow-sm hover:rounded-[100px] h-[35px] w-[50%] text-center justify-center gap-1 cursor-pointer hover:text-[#232323] text-[#9B9697] hover:font-semibold p-2`}
        >
          <p
            className={`${
              pathname === "/account/documents" ? " text-[#232323]" : ""
            }`}
          >
            Documents
          </p>
        </div>
      </div>
    </div>
  );
};

export default AccountLinkNavBar;
