import React from "react";
import { DiVim } from "react-icons/di";

const TruncatedText = ({ text, maxLength }) => {
  if (text.length > maxLength) {
    const truncatedText = text.slice(0, maxLength) + "...";
    return <div>{truncatedText}</div>;
  }

  return <DiVim>{text}</DiVim>;
};

export default TruncatedText;
