import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import Textfield from "../../../components/Textfield";
import logo from "../../../assets/imgs/Logo.svg";

const ResetPassword = () => {
  const navigate = useNavigate();
  // button state
  const [disabledbtn, setDisabledBtn] = React.useState(true);

  // show error
  const [passswordError, setPassswordError] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState("");

  // password value
  const [confirmPassword, updateConfirmPassword] = React.useState("");
  const [password, updatePassword] = React.useState("");

  // show error icon
  const [showErrorPassword, setShowErrorPassword] = React.useState(false);
  const [showErrorConfirmPassword, setShowErrorConfirmPassword] =
    React.useState(false);

  const validate = () => {
    const userInfo = {
      NewConfirmPasword: "",
      NewPasEsword: "",
    };

    // Password validation
    let regPassword = /^(?=.*\d).{4,}$/;
    if (!regPassword.test(password)) {
      setPassswordError("Password must a number and atleast 4 characters");
      setShowErrorPassword(true);
    } else {
      setPassswordError("");
      userInfo.NewPasEsword = password;
      setShowErrorPassword(false);
    }

    // confirm validation
    if (confirmPassword !== password) {
      setConfirmPasswordError("Password must match");
      setShowErrorConfirmPassword(true);
    } else {
      userInfo.NewConfirmPasword = confirmPassword;
      setConfirmPasswordError("");
      setShowErrorConfirmPassword(false);
    }

    // Check if all fields are valid before navigating
    if (userInfo.NewPasEsword !== "" && userInfo.NewConfirmPasword !== "") {
      navigate(`/auth`);
      console.log(userInfo);
    } else {
      return null;
    }
  };

  useEffect(() => {
    function checkField() {
      if (password !== "" && confirmPassword !== "") {
        setDisabledBtn(false);
      } else {
        setDisabledBtn(true);
      }
    }
    checkField();
  }, [password, confirmPassword]);

  return (
    <section className="w-full flex justify-center  h-full">
      <div>
        <div className="flex justify-center">
          <img src={logo} alt="" className="" />
        </div>
        <div className="pt-2">
          <div className="flex justify-center">
            <h1 className="text-[27px] font-[700] text-[#232323]">
              Create new password
            </h1>
          </div>

          <div className="flex justify-center pt-4">
            <p className="text-[#9B9697] text-[16px] text-[400] text-center">
              Please create a password that is 8 characters long and includes{" "}
              <br />
              at least one special character and one uppercase letter.
            </p>
          </div>
        </div>
        <form className="flex flex-col gap-5 w-[496px] pt-6 ">
          <Textfield
            labal={"New password"}
            type={"password"}
            onChange={(e) => {
              updatePassword(e.target.value);
            }}
            value={password}
            msg={passswordError}
            error={showErrorPassword}
          />
          <Textfield
            labal={"Confirm new password"}
            type={"password"}
            value={confirmPassword}
            onChange={(e) => {
              updateConfirmPassword(e.target.value);
            }}
            msg={confirmPasswordError}
            error={showErrorConfirmPassword}
          />

          <div className="pt-8">
            <Button
              isBtnDisabled={disabledbtn}
              variant="authBlue"
              touches={"h-[44px]"}
              onClick={(e) => {
                validate();
                e.preventDefault();
              }}
            >
              Continue
            </Button>
            <div className="pt-5 w-full justify-center">
              <div className="  w-full">
                <p className="text-[#5C5959] text-[400] text-[16px] text-center">
                  Already have an account?
                  <span
                    onClick={() => navigate("/auth")}
                    className="text-[#5271FF] pl-5 cursor-pointer"
                  >
                    Login
                  </span>
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ResetPassword;
