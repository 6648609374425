import React, { useEffect } from "react";
import Service from "./";
import { useNavigate } from "react-router-dom";
import { getFromStore, removeFromStore, saveInStore } from "../utils/helpers";
import { DateTime, Duration } from "luxon";
import { toast } from "react-toastify";

// Function to update tokens in local storage
export const updateToken = (dbResponse) => {
  const now = DateTime.now();

  const { expiresIn, access_token, refreshtoken, partial } = dbResponse;

  const expiresInValue = parseInt(expiresIn);

  if (access_token && refreshtoken) {
    const expiresAt = now
      .plus(Duration.fromObject({ hours: expiresInValue * 0.9 }))
      .toISO();

    if (!partial) {
      const refreshExpiresAt = now
        .plus(Duration.fromObject({ hours: 4 }))
        .toISO();
      saveInStore("vendyz_REFTKEXPAT", refreshExpiresAt);
    }

    saveInStore("vendyz_TK", access_token);
    saveInStore("vendyz_REFTK", refreshtoken);
    saveInStore("vendyz_EXPAT", expiresAt);
    saveInStore("vendyz_EXPIN", expiresIn);
    saveInStore("vendyz_TKCRAT", now.toISO());
  }

  return true;
};

// Function to clear tokens from local storage
export const clearToken = () => {
  removeFromStore("vendyz_EXPAT");
  removeFromStore("vendyz_REFTKEXPAT");
  removeFromStore("vendyz_TK");
  removeFromStore("vendyz_REFTK");
  removeFromStore("vendyz_user");
  removeFromStore("vendyz_TKCRAT");
  removeFromStore("vendyz_EXPIN");
};

// Function to check if the response indicates success
export const successValue = (res) => {
  return (
    res.status_code === 201 &&
    res.success &&
    res.response_description === "Success"
  );
};

// Function to get tokens from local storage
export const getTokens = () => {
  const tokens = {
    accessToken: getFromStore("vendyz_TK"),
    refreshToken: getFromStore("vendyz_REFTK"),
    expiresAT: getFromStore("vendyz_EXPAT"),
    refreshExpiresAt: getFromStore("vendyz_REFTKEXPAT"),
    createdTokenAt: getFromStore("vendyz_TKCRAT"),
    expiresIn: getFromStore("vendyz_EXPIN"),
    userInfo: getFromStore("vendyz_user"),
  };

  if (!tokens.refreshToken) {
    return null;
  } else {
    return tokens;
  }
};

// Custom hook to handle API service and token management
export const useService = (baseUrl) => {
  const navigate = useNavigate();

  const axiosInstance = Service(baseUrl);

  useEffect(() => {
    const logout = () => {
      clearToken();
      navigate("/auth");
    };

    window.addEventListener("unAuthorize", logout);

    return () => {
      window.removeEventListener("unAuthorize", logout);
    };
  }, [navigate]);

  // Function to get tokens and navigate to auth if not found
  const getTokensInner = () => {
    const tokens = getTokens();

    if (!tokens) {
      navigate("/auth");
    } else {
      return tokens;
    }
  };

  // Function to check if the given ISO date is still valid
  const checkValidity = (ISODateTime) => {
    if (ISODateTime.trim() !== "") {
      const dateToCheck = DateTime.fromISO(ISODateTime);
      if (dateToCheck.isValid) {
        return dateToCheck.diffNow().milliseconds > 0;
      }
    }
    return false;
  };

  // Function to process authentication by checking token validity
  const processAuthentication = async () => {
    const tokens = getTokensInner();

    if (
      !tokens ||
      !checkValidity(tokens.refreshExpiresAt) ||
      !checkValidity(tokens.expiresAT)
    ) {
      clearToken();
      navigate("/auth");
      return false;
    }

    return true;
  };

  // POST request with token validation
  const IPost = async (url, data, config) => {
    const tokenProccess = await processAuthentication();
    if (tokenProccess) {
      return axiosInstance.post(url, data, config);
    } else {
      return Promise.resolve({
        data: undefined,
        status: 401,
        statusText: "Token Verification failed",
      });
    }
  };

  // GET request with token validation
  const IGet = async (url, config) => {
    const tokenProccess = await processAuthentication();
    if (tokenProccess) {
      return axiosInstance.get(url, config);
    } else {
      return Promise.resolve({
        data: undefined,
        status: 401,
        statusText: "Token Verification failed",
      });
    }
  };

  // PATCH request with token validation
  const IPatch = async (url, data, config) => {
    const tokenProccess = await processAuthentication();
    if (tokenProccess) {
      return axiosInstance.patch(url, data, config);
    } else {
      return Promise.resolve({
        data: undefined,
        status: 401,
        statusText: "Token Verification failed",
      });
    }
  };

  // DELETE request with token validation
  const IDelete = async (url, config) => {
    const tokenProccess = await processAuthentication();
    if (tokenProccess) {
      return axiosInstance.delete(url, config);
    } else {
      return Promise.resolve({
        data: undefined,
        status: 401,
        statusText: "Token Verification failed",
      });
    }
  };

  // Logout function to clear tokens and navigate to auth
  const ILogout = () => {
    clearToken();
    toast.success("Logout Success");
    navigate("/auth");
  };

  return {
    post: IPost,
    get: IGet,
    patch: IPatch,
    delete: IDelete,
    tokenize: processAuthentication,
    logout: ILogout,
  };
};

export default useService;
